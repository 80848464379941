import {LayoutZone, Padding} from '../../style/lib'
import {Button, Fab, Grid, Typography} from '@material-ui/core'
import {withSnackbar} from 'notistack'
import {Link} from '@reach/router'
import Chip from '@material-ui/core/Chip'
import {RestaurantCardCompact} from '../RestaurantCardCompact'
import Map from '../Map'
import {withLocation} from '../../module/withLocation'
import SkeletonGrid from '../SkeletonGrid'
import RestaurantGrid from '../RestaurantGrid'
import geoAreas from './../../definition/geoAreas.json'
import {RecommendSellerBanner} from '../RecommendSellerBanner'
import {ReactTitle} from 'react-meta-tags'
import MapCover from '../MapCover'
import {Flex, FlexItem} from '../Flex'

const StyledBanner = styled.div`
	width: 100%;
	height: ${props => props.screenwidth < 580 ? 240 : 384}px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	
	> div {
		margin: ${props => props.screenwidth < 580 ? 16 : 32}px 0;
	}
`

const StyledRedSpan = styled.span`
	color: #d32f2f;
`

const StyledSingleRestaurantCardCompact = styled(RestaurantCardCompact)`
	max-width: 300px;
	flex-basis: initial;
`

@withLocation
@withAPI @withSnackbar @withScreen
export default class Home extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.state = {
			ctaUserLabel: (Date.now() % 2) ? 'Nakup si' : 'Vyber si',
			allRestaurants: null,
			nearbyRestaurants: null,
			newRestaurants: null,
			tags: null,
			mapHidden: true
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.latitude !== this.props.location.latitude) {
			this.fetchNearbyRestaurants(this.props.location.latitude, this.props.location.longitude)
		}
	}

	render() {
		const markers = this.getAllMarkers()
		const mapCenter = (this.props.location.latitude && this.props.location.longitude)
			? {latitude: this.props.location.latitude, longitude: this.props.location.longitude}
			: undefined

		return (
			<>
				<ReactTitle title={`${window.WebName} | ${window.WebClaim}`}/>
				<LayoutZone>
					{window.isSignUpAvailable &&
						<StyledBanner className="image banner" screenwidth={this.props.screen.width}>
							<Grid
								container
								spacing={this.props.screen.width < 580 ? 1 : 4}
								direction="row"
								alignItems="flex-end"
								justify="center"
							>
								<Grid item>
									<Button
										variant="contained"
										color="primary"
										component={Link}
										to="/nearby"
										size={this.props.screen.width < 580 ? 'small' : 'large'}
									>
										{this.state.ctaUserLabel}
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant="contained"
										color="secondary"
										component={Link}
										to="/auth/signup"
										size={this.props.screen.width < 580 ? 'small' : 'large'}
									>
										Chci nabízet
									</Button>
								</Grid>
							</Grid>
						</StyledBanner>
					}

					<Padding vertical size={32} overflow="visible">
						<Grid container spacing={2} justify="center" direction="column">
							<FlexItem xs={12}>
								<Flex spacing={2} justify="space-between" align="center" safariFix>
									<FlexItem>
										<Typography variant="h5">
											<>
												V okolí{' '}
												{this.props.location && this.props.location.street && this.props.location.city && (
													<StyledRedSpan>
														{this.props.location.street}, {this.props.location.city}
													</StyledRedSpan>
												)}
											</>
										</Typography>
									</FlexItem>
									<FlexItem>
										<Fab
											size="medium"
											color="primary"
											variant="extended"
											component={Link}
											to="/nearby">
											Další nabídky v okolí
										</Fab>
									</FlexItem>
								</Flex>
							</FlexItem>

							<Grid item xs={12} style={{maxWidth: '100%'}}>
								{this.state.nearbyRestaurants !== null
									? <RestaurantGrid restaurants={this.state.nearbyRestaurants}
									                  alive={this.props.alive}/>
									: <SkeletonGrid items={9}/>
								}
							</Grid>
							<Padding size={8}/>
							{window.isSignUpAvailable &&
								<Grid item xs={12}>
									<RecommendSellerBanner/>
								</Grid>
							}
							<Padding size={8}/>
							<Grid item xs={12}>
								<Typography variant="h5">Mapa okolí</Typography>
							</Grid>
							<Grid item xs={12}>
								{this.state.mapHidden ? (
									<MapCover onShow={this.showMap}/>
								) : (
									<Map
										key={JSON.stringify(mapCenter)}
										center={mapCenter}
										zoom={mapCenter ? 12 : undefined}
										markers={markers}
									/>
								)}
							</Grid>

							<Grid item xs={12}>
								<Grid container justify="center">
									<LocationList locations={geoAreas}/>
								</Grid>
							</Grid>

							<Padding/>
							<Grid item xs={12}>
								<Typography variant="h5">Nejnovější</Typography>
							</Grid>
							<Grid item xs={12} style={{maxWidth: '100%'}}>
								{this.state.newRestaurants !== null
									? <RestaurantGrid
										restaurants={this.state.newRestaurants}
										alive={this.props.alive}/>
									: <SkeletonGrid items={9}/>
								}
							</Grid>

							<Padding/>
							<Grid item xs={12}>
								<Grid container justify="center">
									<Grid item sm={12} lg={10} xl={8}>
										{this.state.tags &&
										<Padding horizontal size={16}>
											<TagList tags={this.state.tags}/>
										</Padding>}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Padding>

				</LayoutZone>
			</>
		)
	}

	componentDidMount() {
		this.fetchRestaurants()
		this.fetchLatestRestaurants()
		this.fetchTags()
		if (this.props.location.latitude) {
			this.fetchNearbyRestaurants(this.props.location.latitude, this.props.location.longitude)
		}
	}

	fetchRestaurants() {
		this.props.API.get('/default.json', {S3: true})
		.then(result => {
			const visibleRestaurants = result.restaurants.filter(x => (x.delivery || x.pickup))

			this.setState({
				allRestaurants: visibleRestaurants
			})
		})
		.catch(() => this.props.enqueueSnackbar(
			'Nepodařilo se načíst seznam restaurací. Zkuste to prosím později.',
			{variant: 'error'}
		))
	}

	fetchNearbyRestaurants(lat, lon) {
		this.props.API.get(`/nearby?lat=${lat}&lon=${lon}&items=9`)
		.then(result => {
			this.setState({
				nearbyRestaurants: result.restaurants
			})
		})
		.catch(() => this.props.enqueueSnackbar(
			'Nepodařilo se načíst seznam restaurací. Zkuste to prosím později.',
			{variant: 'error'}
		))
	}

	fetchLatestRestaurants() {
		this.props.API.get('/latest.json', {S3: true})
		.then(result => {
			this.setState({
				newRestaurants: result.restaurants
			})
		})
		.catch(() => this.props.enqueueSnackbar(
			'Nepodařilo se načíst seznam nejnovějších restaurací. Zkuste to prosím později.',
			{variant: 'error'}
		))
	}

	fetchTags() {
		this.props.API.get('/allTags.json', {
			S3: true
		})
		.then(result => {
			this.setState({tags: result.tags.shuffle()})
		})
		.catch(() => this.props.enqueueSnackbar(
			'Nepodařilo se načíst seznam štítků. Zkuste to prosím později.',
			{variant: 'error'}
		))
	}

	getAllMarkers() {
		if (!this.state.allRestaurants) return []

		return this.state.allRestaurants
		.filter(x => parseFloat(x.latitude) && parseFloat(x.longitude))
		.map(restaurant => ({
			latitude: restaurant.latitude,
			longitude: restaurant.longitude,
			card: (
				<Grid container>
					<StyledSingleRestaurantCardCompact restaurant={restaurant}/>
				</Grid>
			)
		}))
	}

	showMap() {
		this.setState({
			mapHidden: false
		})
	}
}

class TagList extends React.PureComponent {
	render() {
		return (
			<Grid container justify="center">
				{this.props.tags.map((tag, index) => (
					<Padding horizontal size={4} key={`tag-${index}`}>
						<Chip
							component={Link}
							clickable={true}
							label={tag.label}
							to={`tag/${tag.slug}`}
						/>
					</Padding>
				))}
			</Grid>
		)
	}
}

class LocationList extends React.PureComponent {
	render() {
		return (
			<Grid container justify="center">
				{this.props.locations.cities.map((tag, index) => (
					<Padding horizontal size={4} key={`location-${index}`}>
						<Chip
							component={Link}
							clickable={true}
							label={tag}
							to={`nearby/${tag}`}
						/>
					</Padding>
				))}
			</Grid>
		)
	}
}
