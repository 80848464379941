import {withSnackbar} from 'notistack'
import {LayoutZone, Padding} from '../../../style/lib'
import {Grid, Typography} from '@material-ui/core'
import {MetaTags} from 'react-meta-tags'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import {Flex} from '../../Flex'
import Box from '@material-ui/core/Box'


@withAPI @withSnackbar
export default class CityRank extends React.PureComponent {

	constructor() {
		super()
		this.state = {
			cities: null
		}
	}

	render() {
		const title = `Žebříček měst | ${window.WebClaim}`
		return <>
			<MetaTags>
				<title>{title}</title>
				<meta name="title" content={title}/>
				<meta name="description"
				      content='Přehled restaurací a obchodů nabízejících rozvoz jídel. Registrace pro restaurace a obchody je zdarma.'/>
				<meta name="keywords"
				      content='rozvoz jídel, rozvoz jídla, rozvoz jídla praha, rozvoz jídla brno, rozvoz jídla plzeň'/>
			</MetaTags>
			<LayoutZone>
				<Padding vertical size={32}>
					<Grid container spacing={2} justify="center" direction="row">
						<Grid item xs={12}>
							<Typography variant="h5" component="h2">Žebříček měst</Typography>
						</Grid>
						{this.state.cities && this.state.cities.slice(0, 9).map((city, index) => (
							<Grid item xs={4} key={index}>
								<Card onClick={() => {this.browse(city.name)}}>
									<CardActionArea>
										<CardMedia
											component="img"
											src={`${S3_URI}/cities/photo/header/${city.id.replace(/[0-9]*$/, '').replace(/-*$/, '')}.jpg`}
											title={city.name}
											height={223}
										/>
										<CardContent>
											<Grid container justify="space-between" alignItems="flex-end">
												<Grid item>
													<Typography variant="h5" component="h3">
														{index + 1}. {city.name}
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant="h5" component="p">
														{this.sellers(city.count)}
													</Typography>
												</Grid>
											</Grid>
										</CardContent>
									</CardActionArea>
								</Card>
							</Grid>
						))}
						<Grid item xs={12} md={8}>
							{this.state.cities && this.state.cities.slice(9).map((city, index) => (
								<Card onClick={() => {this.browse(city.name)}} key={index}>
									<CardActionArea>
										<Flex justify="space-between" p={2}>
											<Box>
												<Typography variant="h5" component="h3" key={index}>
													{index + 10}.&nbsp;
												</Typography>
											</Box>
											<Box flexGrow={1}>
												<Typography variant="h5" component="h3" key={index}>
													{city.name}
												</Typography>
											</Box>
											<Box>
												<Typography variant="h5" component="p">
													{this.sellers(city.count)}
												</Typography>
											</Box>
										</Flex>
									</CardActionArea>
								</Card>
							))}
						</Grid>
					</Grid>
				</Padding>
			</LayoutZone>
		</>
	}

	sellers(number) {
		if (number === 1) {
			return `${number} prodejce`
		}
		if (number >= 2 && number <= 4) {
			return `${number} prodejci`
		}
		return `${number} prodejců`
	}

	browse(city) {
		this.props.navigate(`/nearby/${city ?? ''}`)
	}

	componentDidMount() {
		this.fetchCities()
	}

	fetchCities() {
		this.props.API.get('/cities/list.json', {
			S3: true
		})
		.then(result => {
			console.log(result)
			this.setState({
				cities: result
			})
		})
		.catch(() => this.props.enqueueSnackbar(
			'Nepodařilo se načíst seznam měst. Zkuste to prosím později.',
			{variant: 'error'}
		))
	}
}