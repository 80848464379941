import {Grid} from '@material-ui/core'
import {Skeleton} from '@material-ui/lab'

export default class SkeletonGrid extends React.PureComponent {
	render() {
		return (
			<Grid container spacing={2}>
				{[...new Array(this.props.items)].map((_, index) => (
					<Grid item xs={12} md={4} key={index}>
						<Skeleton height={100}/>
					</Grid>
				))}
			</Grid>
		)
	}
}