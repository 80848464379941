import {
    FormControl, InputLabel, MenuItem, OutlinedInput, RootRef, Select as MaterialSelect,
    InputAdornment, IconButton
} from '@material-ui/core'
import IconClear from 'mdi-material-ui/Close'
import styled, {css} from 'styled-components'

const StyledOutlinedInput = styled(OutlinedInput)`
  legend {
    border-bottom: none;
  }
`

const StyledMenuItem = styled(MenuItem)`
  ${props => props.isgrouplabel === 'true' && css`
    & {
        font-size: 13px;
        font-weight: bold;
        color: black;
    }
  `}
`

const StyledMaterialSelect = styled(MaterialSelect)`
  ${props => props.clearable && css`
    & > div:first-child {
      padding-right: 48px;
    }
  `}
`

const StyledFormControl = styled(FormControl)`
  position: relative;
`

const StyledClearButton = styled.div`
  z-index: 100;
  position: absolute;
  right: ${props => props.size === 'small' ? 6 : 12}px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;  
`

export default class Select extends React.PureComponent {
    constructor(props) {
        super(props)
        autobind(this)

        this.state = {
            labelWidth: 0
        }
    }

    render() {
        const inputId = this.props.id || `SelectID_${Math.random()}`

        const proxiedProps = {...this.props}
        delete proxiedProps.dense
        delete proxiedProps.clearable
        delete proxiedProps.SelectProps
        delete proxiedProps.minWidth

        const showClearButton = Boolean(this.props.clearable && this.props.value)

        return (
            <StyledFormControl
                variant="outlined"
                margin="none"
                {...(this.props.dense ? {size: 'small'} : {})}
                {...proxiedProps}
            >
                {showClearButton && (
                    <StyledClearButton size={this.props.size || 'medium'}>
                        <IconButton
                            size="small"
                            onClick={this.clear}
                            disabled={this.props.disabled}
                        >
                            <IconClear/>
                        </IconButton>
                    </StyledClearButton>
                )}
                <RootRef rootRef={c => this.selectLabel = c}>
                    <InputLabel htmlFor={inputId}>
                        {this.props.label}
                    </InputLabel>
                </RootRef>
                <StyledMaterialSelect
                    value={this.props.value}
                    onChange={this.props.onChange}
                    input={
                        <StyledOutlinedInput
                            clearable={showClearButton.toString()}
                            labelWidth={this.state.labelWidth}
                            name={this.props.name}
                            id={inputId}
                            style={{minWidth: this.props.minWidth || 180}}
                        />
                    }
                    clearable={showClearButton.toString()}
                    IconComponent={showClearButton ? DisplayNone : undefined}
                    {...this.props.SelectProps}
                >
                    {this.props.options.map(option => (
                        <StyledMenuItem
                            key={option.key || option.value}
                            value={option.value}
                            disabled={option.groupLabel}
                            isgrouplabel={Boolean(option.groupLabel).toString()}
                            dense={option.groupLabel}
                        >
                            {option.label}
                        </StyledMenuItem>
                    ))}
                </StyledMaterialSelect>
            </StyledFormControl>
        )
    }

    componentDidMount() {
        this.updateLabelWidth()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.label !== this.props.label) {
            this.updateLabelWidth()
        }
    }

    updateLabelWidth() {
        this.setState({
            labelWidth: this.selectLabel.offsetWidth
        })
    }

    clear() {
        this.props.onChange('')
    }

    static propTypes = {
        value: PropTypes.any.isRequired,
        onChange: PropTypes.func.isRequired,
        options: PropTypes.array.isRequired,
        id: PropTypes.string,
        clearable: PropTypes.bool,
        size: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        minWidth: PropTypes.number,
        name: PropTypes.string
    }
}

const DisplayNone = styled.div`
  display: none !important;
`