import {LayoutZone, Padding} from '../../../style/lib'
import {Grid, Link as MaterialLink, Paper, Typography} from '@material-ui/core'
import {Link} from '@reach/router'

const StyledParagraph = styled(Typography).attrs({variant: 'body1'})`
  margin-top: 0.5rem;
  margin-bottom: 1.1rem;
`
const StyledH1 = styled(Typography).attrs({variant: 'h4', component: 'h1'})`
  margin-top: 1.1rem;
`

const StyledH2 = styled(Typography).attrs({variant: 'h6', component: 'h2'})`
  margin-top: 2.8rem;
`

const StyledDocumentPaper = styled(Paper)`
  ul > li > ul {
    margin-left: 24px;
  }

  li {
    list-style-type: none;
  }
`

export default class TermsOfUse extends React.PureComponent {
	render() {
		return (
			<LayoutZone>
				<Padding size={40}>
					<StyledDocumentPaper>
						<Padding size={32}>
							<Grid container spacing={4} justify="center" direction="column">
								<Grid item xs={12}>

									<StyledH1>Podmínky užití</StyledH1>

									<StyledParagraph>
										Níže uvedené Podmínky užití (dále jen „<b>Podmínky</b>“) upravují práva
										a povinnosti smluvních stran a zájemců při využívání služby poskytované
										prostřednictvím webových stránek <LinkHome/> anebo mobilní aplikace
										vyzvednisi.cz
										(dále jen „<b>Služba</b>“) provozovaných společností Dotykačka ČR s.r.o.,
										IČO: 062 90 914, se sídlem Plzeňská 3217/16, 150 00 Praha 5,
										zapsanou v obchodním rejstříku vedeném Městským soudem v Praze,
										pod sp. zn. C 279594 (dále jen „<b>Provozovatel</b>“).
									</StyledParagraph>

									<StyledParagraph variant="body1">
										Každý uživatel Služby se zavazuje, že se ihned po vstupu na webové
										stránky <LinkHome/>, či před stažením aplikace dostupné na Google Play seznámí s
										platnými Podmínkami, a dalším užíváním webových stránek či
										stažením mobilní aplikace, tj. faktickým užíváním Služeb akceptuje platné znění
										Podmínek a zavazuje se jimi řídit.
									</StyledParagraph>

									<ul>
										<li>
											<StyledH2 variant="h6" component="h2">
												1. ÚVODNÍ USTANOVENÍ
											</StyledH2>
											<ul>
												<li>
													<StyledParagraph>
														1.1. Provozovatel poskytuje Službu realizovanou prostřednictvím
														webových stránek <LinkHome/> a mobilní aplikace
														Vyzvednisi.cz za účelem propojení jiných podnikatelů (zejména
														restaurací a obchodů) (dále jen „<b>Poskytovatel</b>“) s třetími
														osobami – zákazníky a/nebo potenciálními zákazníky (dále jen
														„<b>Zákazník</b>“) a vytvoření příležitosti k uzavření smlouvy
														na
														dodání zboží nebo poskytnutí služeb Poskytovatelem Zákazníkovi
														(dále jen „<b>Smlouva mezi Poskytovatelem a Zákazníkem</b>“).
													</StyledParagraph>
												</li>
											</ul>
										</li>
									</ul>

									<ul>
										<li>
											<StyledH2 variant="h6" component="h2">
												2. ZÁKLADNÍ PODMÍNKY VYUŽITÍ SLUŽBY PROVOZOVATELE
											</StyledH2>
											<ul>
												<li>
													<StyledParagraph>
														2.1. Provozovatel poskytuje Službu bezplatně (náklady spojené s
														připojením na internet apod. si hradí každý uživatel
														samostatně).
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														2.2. Provozovatel není účastníkem Smlouvy mezi Poskytovatelem a
														Zákazníkem. Provozovatel nenese žádnou odpovědnost za splnění
														povinností ze Smlouvy mezi Poskytovatelem a Zákazníkem, ani
														odpovědnost za případnou škodu (újmu) vzniklou Zákazníkovi nebo
														Poskytovateli na základě nebo v souvislosti se Smlouvou mezi
														Poskytovatelem a Zákazníkem.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														2.3. Provozovatel nepřijímá platby za zboží nebo služby, které
														si
														Zákazník objedná prostřednictvím Služby u Poskytovatele, není-li
														písemně sjednáno jinak. Každý
														Poskytovatel je povinen informovat Zákazníky o dostupných
														možnostech platby a vyzvednutí nebo doručení zboží či služeb
														Poskytovatele a poskytnout Zákazníkovi veškerou potřebnou
														součinnost k realizaci Smlouvy mezi Poskytovatelem a Zákazníkem.
														V případě, že bude Zákazníkovi umožněno realizovat platbu
														prostřednictvím platební brány, bere Zákazník na vědomí, že při
														zprostředkování přijímání plateb za úhradu objednávek Zákazníka
														Provozovatel zastupuje výhradně Poskytovatele, jedná tedy jeho
														jménem a na jeho účet. Úhradou platby za zboží nebo službu a
														jejím připsáním přes platební bránu Zákazník splní svou
														povinnost vůči Poskytovateli.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														2.4. Provozovatel informuje uživatele, že kliknutím na některé
														odkazy může dojít k přesměrování na webové stránky třetích
														subjektů, zejména Poskytovatelů.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														2.5. Provozovatel si vyhrazuje právo kdykoliv omezit nebo
														ukončit poskytování Služby.
													</StyledParagraph>
												</li>
											</ul>
										</li>
									</ul>

									<ul>
										<li>
											<StyledH2 variant="h6" component="h2">
												3. PRÁVA A POVINNOSTI ZÁKAZNÍKŮ
											</StyledH2>
											<ul>
												<li>
													<StyledParagraph>
														3.1. Zákazník prohlašuje, že při využití Služeb:
													</StyledParagraph>
													<ul>
														<li>
															<StyledParagraph>
																3.1.1 se seznámil s Podmínkami, jsou pro něj
																pochopitelné,
																souhlasí s nimi a zavazuje se je plně dodržovat;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																3.1.2. veškeré úkony na platformě <LinkHome/> či v
																aplikaci Vyzvednisi.cz činí vlastním jménem a na vlastní
																účet a je způsobilý k využití Služeb;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																3.1.3. se řádně zaregistruje a zadá veškeré požadované
																údaje
																úplně, správně a přesně;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																3.1.4. bude dodržovat veškeré právní předpisy a nařízení
																platné
																na území České republiky;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																3.1.5. nebude Služby využívat v rozporu se smyslem a
																účelem
																Služby, k nelegálním činnostem, ani jakýmkoliv jiným
																zneužívajícím způsobem;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																3.1.6. nebude využívat část Služeb nabízejících
																produkty, které
																jsou označené symbolem „18+“ nebo jiným symbolem
																stejného významu v případě, že mu ke dni užití Služeb
																není nejméně 18 let;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																3.1.7. bude maximálním způsobem chránit práva a
																oprávněné zájmy
																třetích stran;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																3.1.8. si je vědom skutečnosti, že některé zboží či
																služby
																objednané prostřednictvím Služby mohou obsahovat
																alergeny. Pro bližší informace ohledně alergenů
																obsažených v konkrétních jídlech, nápojích či jiném
																relevantním zboží mohou Zákazníci kontaktovat přímo
																Poskytovatele nebo zákaznickou podporu Provozovatele na
																adrese <MaterialLink
																href="mailto:info@vyzvednisi.cz">info@vyzvednisi.cz</MaterialLink> Poskytovatel
																nebo zákaznická
																podpora Provozovatele poskytne bez zbytečného odkladu a
																bezplatně Zákazníkovi informace o alergenech obsažených
																v jídle, nápoji či jiném relevantním zboží. Zákazník
																informace o alergenech získá rovněž při osobním
																převzetí, nebo doručení jídla, nápoje či jiného
																relevantního zboží.
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																3.1.9. nebude užívat název Služby v rozporu s jejím
																účelem a
																dobrým jménem Služby či jejího Provozovatele, ani jinak
																zneužívat, blokovat, modifikovat či jinak měnit
																jakoukoliv součást Služby, nebo narušovat stabilitu a
																chod Služby či neoprávněně získávat data potřebná k
																realizaci Služby;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																3.1.10. informuje Provozovatele o podstatném porušení
																Podmínek
																Poskytovatelem či jiným Zákazníkem.
															</StyledParagraph>
														</li>
													</ul>
												</li>
												<li>
													<StyledParagraph>
														3.2. Zákazník má právo uplatnit práva z vadného plnění
														související se Smlouvou mezi Poskytovatelem a Zákazníkem u
														Poskytovatele, a to na adrese provozovny Poskytovatele nebo jiné
														adrese a/nebo kontaktních údajích, které za tímto účelem
														Poskytovatel sdělil, a to (pokud je to relevantní pro danou
														službu nebo zboží) s přihlédnutím ke skutečnosti, že je
														předmětem dané smlouvy koupě zuživatelného zboží (jídlo a
														nápoje), které podléhá rychlé zkáze.
													</StyledParagraph>
												</li>
											</ul>
										</li>
									</ul>

									<ul>
										<li>
											<StyledH2 variant="h6" component="h2">
												4. PRÁVA A POVINNOSTI POSKYTOVATELE ZBOŽÍ A/NEBO SLUŽEB
											</StyledH2>
											<ul>
												<li>
													<StyledParagraph>
														4.1. Poskytovatel se při využití Služeb zavazuje:
													</StyledParagraph>
													<ul>
														<li>
															<StyledParagraph>
																4.1.1. seznámit s Podmínkami a prohlašuje, že jsou pro
																něj pochopitelné, souhlasí s nimi a zavazuje se je plně
																dodržovat;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																4.1.2. že veškeré úkony činí osoba oprávněná zastupovat
																Poskytovatele při využití Služeb, a to na účet
																Poskytovatele;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																4.1.3. se řádně zaregistrovat a zadat veškeré požadované
																údaje úplně, správně a přesně;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																4.1.4. zveřejňovat prostřednictvím Služby aktuální,
																přesné, pravdivé a úplné informace o nabízených službách
																či zboží, tj. jednotlivých položkách, a to takovým
																způsobem, aby obsah ani vzhled informací nebyl v rozporu
																s právními předpisy či nepoškozoval práva třetích osob;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																4.1.5. poskytovat Zákazníkům, zájemcům o Službu a/nebo
																zákaznické podpoře Provozovatele přesné a úplné
																informace o obsahu alergenů, pokud je to relevantní pro
																danou službu či zboží, a to neprodleně a bezplatně;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																4.1.6. řádným způsobem přijímat a vyřizovat reklamace
																zboží a/nebo služeb poskytnutých prostřednictvím Služby;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																4.1.7. nevyužívat Služby v rozporu se smyslem a účelem
																Služby, k nelegálním činnostem, ani jakýmkoliv jiným
																zneužívajícím způsobem;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																4.1.8. dodržovat veškeré právní předpisy a nařízení
																platné na území České republiky;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																4.1.9. neposkytovat zboží či služby osobám mladším
																osmnácti let v případech stanovených platnými právními
																předpisy a dané zboží a/nebo služby odpovídajícím
																způsobem ve Službě označit;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																4.1.10. maximálním způsobem chránit práva a oprávněné
																zájmy třetích stran;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																4.1.11. že nebude užívat název Služby v rozporu s jejím
																účelem a dobrým jménem Služby či jejího Provozovatele,
																ani jinak zneužívat, blokovat, modifikovat či jinak
																měnit jakoukoliv součást Služby, nebo narušovat
																stabilitu a chod Služby či neoprávněně získávat data
																potřebná k realizaci Služby;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																4.1.12. informovat Provozovatele o podstatném porušení
																Podmínek Zákazníkem či jiným Poskytovatelem.
															</StyledParagraph>
														</li>
													</ul>
												</li>
											</ul>
										</li>
									</ul>

									<ul>
										<li>
											<StyledH2 variant="h6" component="h2">
												5. PRÁVA A POVINNOSTI PROVOZOVATELE SLUŽEB
											</StyledH2>
											<ul>
												<li>
													<StyledParagraph>
														5.1. Provozovatel Služby je oprávněn:
													</StyledParagraph>
													<ul>
														<li>
															<StyledParagraph>
																5.1.1. zablokovat účet a možnost další registrace
																Zákazníkovi,
																který podstatně porušil svoje povinnosti stanovené v čl.
																3 těchto Podmínek;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																5.1.2. zablokovat účet a možnost další registrace
																Poskytovateli, který podstatně porušil svoje povinnosti
																stanovené v čl. 4 těchto Podmínek;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																5.1.3. smazat informace o zboží či službách
																Poskytovatele,
																pokud jakýmkoliv způsobem poškozují práva třetích osob
																nebo jsou v rozporu s platnými právními předpisy.
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																5.1.4. smazat libovolný účet za předpokladu, že má
																důvodné
																podezření, že se jedná o duplikát účtu anebo o účet
																vytvořený robotem nebo jiným zneužívajícím způsobem;
															</StyledParagraph>
														</li>
														<li>
															<StyledParagraph>
																5.1.5. domáhat se plnění povinností Poskytovatelem či
																Zákazníkem a chránit své dobré jméno jakož i práva
																třetích osob.
															</StyledParagraph>
														</li>
													</ul>
												</li>
											</ul>
										</li>
									</ul>

									<ul>
										<li>
											<StyledH2 variant="h6" component="h2">
												6. ODPOVĚDNOST PROVOZOVATELE A NÁHRADA ŠKODY
											</StyledH2>
											<ul>
												<li>
													<StyledParagraph>
														6.1. Provozovatel není odpovědný za dodání zboží či služeb
														poskytovaných Poskytovatelem, ani za jejich kvalitu a/nebo škodu
														způsobenou v důsledku uzavřené Smlouvy mezi Poskytovatelem a
														Zákazníkem.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														6.2. Provozovatel není odpovědný za obsah zveřejněný
														Poskytovatelem, jeho pravdivost, úplnost, či přesnost ani za
														škodu (újmu) vzniklou porušením autorských práv, osobnostních
														práv nebo soutěžního práva, ke kterému došlo v souvislosti s
														obsahem zveřejněným Poskytovatelem nebo na popud Poskytovatele.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														6.3. Provozovatel není odpovědný za rozdíl v ceně zboží a/nebo
														služby zveřejněné Poskytovatelem ve Službě a ceně zboží a/nebo
														služby sdělené jeho/jejím Poskytovatelem při realizaci platby.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														6.4. Provozovatel nezaručuje nepřetržitý přístup ke Službě a
														neodpovídá za škodu (újmu) vzniklou v důsledku jejího výpadku.
														Provozovatel neodpovídá za nezávadnost webových stránek{' '}
														<LinkHome/>, ani za škodu způsobenou počítačovými viry,
														nebo v důsledku ztráty dat nebo neoprávněného přístupu k účtu
														uživatele Služby.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														6.5. Provozovatel nenese odpovědnost za ušlý zisk Poskytovatele
														ani za žádné speciální, nepřímé, ekonomické nebo následné ztráty
														Poskytovatele.
													</StyledParagraph>
												</li>
											</ul>
										</li>
									</ul>

									<ul>
										<li>
											<StyledH2 variant="h6" component="h2">
												7. ZÁVĚREČNÁ USTANOVENÍ
											</StyledH2>
											<ul>
												<li>
													<StyledParagraph>
														7.1. Provozovatel informuje Zákazníky, že k mimosoudnímu řešení
														spotřebitelských sporů je příslušná Česká obchodní inspekce, se
														sídlem Štěpánská 567/15, 120 00 Praha 2,
														IČO: 000 20 869, internetová adresa:{' '}
														<MaterialLink
															href="http://www.coi.cz">http://www.coi.cz</MaterialLink>
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														7.2. Veškeré právní vztahy vznikající na základě nebo v
														souvislosti
														se Službou se řídí právním řádem České republiky, a to bez
														ohledu na to, odkud uživatel Službu využíval a budou rozhodnuty
														místně a věcně příslušným soudem v České republice.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														7.3. Pro případ, že kterékoli ustanovení těchto Podmínek je nebo
														se
														stane neplatným, nevymahatelným či zdánlivým, nebude mít tato
														neplatnost, nevymahatelnost či zdánlivost za následek
														neplatnost, nevymahatelnost či zdánlivost zbývajících ustanovení
														Podmínek.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														7.4. Provozovatel je oprávněn Podmínky kdykoliv jednostranně
														změnit
														či doplnit, a to zejména z důvodu změn právních předpisů
														(respektive aktuálně přijímaných opatření), nebo z důvodu změny
														Služeb poskytovaných Provozovatelem. Provozovatel bude o takové
														změně informovat Poskytovatele a Zákazníky na webové
														stránce{' '}
														<LinkHome/>, případně jiným vhodným způsobem dle volby
														Provozovatele tak, aby se s aktuálně platným zněním Podmínek
														mohli Poskytovatelé a Zákazníci bez zbytečného prodlení
														seznámit. Podmínky nabývají účinnosti okamžikem jejich
														zveřejnění na webové stránce <LinkHome/>
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														7.5. Shora uvedené Podmínky nabývají platnosti a účinnosti dne
														10.8.2020
													</StyledParagraph>
												</li>
											</ul>
										</li>
									</ul>

								</Grid>
							</Grid>
						</Padding>
					</StyledDocumentPaper>
				</Padding>
			</LayoutZone>
		)
	}
}

class LinkHome extends React.PureComponent {
	render() {
		return <MaterialLink component={Link} to="/">www.vyzvednisi.cz</MaterialLink>
	}
}
