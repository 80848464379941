import {CardMedia, Grid, Typography} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import {Link} from '@reach/router'
import thumbnails from '../definition/thumbnails'

const StyledRestaurantHeight = styled.div`
	min-height: 100px;
	max-height: 100px;
`

const StyledRestaurantLink = styled(Link)`
	text-decoration: none;
`

const StyledTagsTypography = styled(Typography)`
	display: block;
	margin-top: 8px;
	color: rgba(0, 0, 0, .55);
`

const StyledRestaurantTitleTypography = styled(Typography)`
	line-height: 1.2;
	font-size: 18px;
`

const StyleRestaurantAddressTypography = styled(Typography)`
	margin-top: 4px;
`

const StyledCardMedia = styled(CardMedia)`
	width: 100px;
	height: 100px;
	float: left;
	background-size: 100%;
`

const StyledInfo = styled.div`
	float: right;
	width: calc(100% - 100px);
	padding: 12px;
`

export class RestaurantCardCompact extends React.PureComponent {
	render() {
		const allowedOrderTypes = []

		if (this.props.restaurant.delivery) allowedOrderTypes.push('rozvoz')
		if (this.props.restaurant.pickup) allowedOrderTypes.push('vyzvednutí')

		const hashCode = this.hashCode(this.props.restaurant.id)
		const imageIndex = Math.abs(hashCode) % 10

		let imageType = 'gastro'
		if ((this.props.restaurant.tags || []).includes('.nongastro')) {
			imageType = 'nongastro'
		}

		const thumbnailSplit = this.props.restaurant.thumbnail.split(':')
		const thumbnailType = thumbnailSplit[0]
		const thumbnailId = thumbnailSplit[1]

		let thumbnailPath
		if (!this.props.restaurant.thumbnail) {
			thumbnailPath = `/asset/${imageType}/${imageType}${imageIndex}.jpg`
		}
		else {
			thumbnailPath = thumbnailType === 'default'
				? '/asset/thumbnails/'
				+ thumbnails.thumbnails100x100.find(x => x.id === thumbnailId).filename
				: `${S3_URI}/restaurant-thumbnail/${thumbnailId}`
		}

		const isOnline = !!this.props.alive && (this.props.alive > (Date.now() - 30 * 60000))
		const cardColor = isOnline ? '#FFF8E1' : '#FFF';
		return (
			<Grid item xs={12} sm={6} md={4} className={this.props.className}>
				<StyledRestaurantLink to={`/${this.props.restaurant.id}`}>
					<StyledRestaurantHeight as={Card} key={this.props.restaurant.id} style={{backgroundColor: cardColor}}>
						<StyledRestaurantHeight as={CardActionArea}>
							<StyledCardMedia image={thumbnailPath}>
								{false && (
									<img src="/asset/badge-online.png" alt="online"/>
								)}
							</StyledCardMedia>
							<StyledInfo>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Grid container spacing={0}>
											<Grid item xs={12}>
												<StyledRestaurantTitleTypography variant="h6" component="h3" noWrap>
													{this.props.restaurant.name}
												</StyledRestaurantTitleTypography>
											</Grid>
											<Grid item xs={12}>
												<StyleRestaurantAddressTypography component="div" variant="caption"
												                                  noWrap>
													{this.props.restaurant.address}, {this.props.restaurant.city}
												</StyleRestaurantAddressTypography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<StyledTagsTypography variant="caption" noWrap>
											{[...allowedOrderTypes, ...this.props.restaurant.tags].join(', ')}
										</StyledTagsTypography>
									</Grid>
								</Grid>
							</StyledInfo>
						</StyledRestaurantHeight>
					</StyledRestaurantHeight>
				</StyledRestaurantLink>
			</Grid>
		)
	}

	hashCode(text) {
		var hash = 0, i, chr
		for (i = 0; i < text.length; i++) {
			chr = text.charCodeAt(i)
			hash = ((hash << 5) - hash) + chr
			hash |= 0 // Convert to 32bit integer
		}
		return hash
	}

	static propTypes = {
		restaurant: PropTypes.object.isRequired
	}
}