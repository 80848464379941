import {LayoutZone, Padding} from '../../../style/lib'
import {Grid, Link as MaterialLink, Paper, Typography} from '@material-ui/core'
import {withSnackbar} from 'notistack'
import {Link} from '@reach/router'
import {MetaTags} from 'react-meta-tags'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import RestaurantGrid from '../../RestaurantGrid'
import Markdown from 'markdown-to-jsx'
import ReactPlayer from 'react-player'
import Container from '@material-ui/core/Container'

const StyledParagraph = styled(Typography)`
	margin-top: 0.5rem;
	margin-bottom: 1.1rem;
`
const StyledH1 = styled(Typography)`
	margin-top: 1.1rem;
	margin-bottom: 0.8rem;
	font-size: 30px;
`

const StyledH2 = styled(Typography)`
	margin-top: 2rem;
	margin-bottom: 1.43rem;
`

const VideoPlayer = styled(ReactPlayer)`
	position: absolute;
	top: 0;
	left: 0;
 `

const VideoWrapper = styled.div`
	position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
`

@withAPI @withSnackbar
export default class BlogPost extends React.PureComponent {

	constructor(props) {
		super(props)
		autobind(this)

		this.state = {
			content: null
		}
	}

	render() {
		const content = this.state.content

		const headline = content ? content.title : 'Blog'
		const title = `${headline} | ${window.WebClaim}`


		const perex = content ? content.perex : ''
		const bodyItems = content ? this.parseBody(content.body) : <></>

		return (
			<>
				<MetaTags>
					<title>{title}</title>
					<meta name="title" content={title}/>
					<meta
						name="description"
						content="Přehled restaurací a obchodů nabízejících rozvoz jídel. Registrace pro restaurace a obchody je zdarma."
					/>
					<meta name="keywords"
					      content="rozvoz jídel, rozvoz jídla, rozvoz jídla praha, rozvoz jídla brno, rozvoz jídla ostrava, rozvoz jídla plzeň"
					/>
				</MetaTags>
				<LayoutZone>
					<Container maxWidth="md">
						{content ? (
							<Paper>
								<Padding size={32}>
									<Grid container spacing={4} justify="center" direction="column">
										<Grid item xs={12}>

											<StyledH1 variant="h4" component="h1">
												{headline}
											</StyledH1>

											<StyledParagraph variant="body2">
												<Markdown>{perex}</Markdown>
											</StyledParagraph>

											{bodyItems}
										</Grid>
									</Grid>
								</Padding>
							</Paper>
						) : (
							<CircularProgress/>
						)}
					</Container>
				</LayoutZone>
			</>
		)
	}

	componentDidMount() {
		this.fetchContent()
	}

	parseBody(elements) {
		return elements.map(it => this.parseItem(it))
	}

	parseItem(item) {
		let textAlign = item.textAlign
		switch (item.type) {
			case 'h2': {
				return <StyledH2 variant="h5" component="h2">{item.data}</StyledH2>
			}
			case 'body': {
				return <StyledParagraph variant="body1">{item.data}</StyledParagraph>
			}
			case 'markdown': {
				return <StyledParagraph variant="body1"><Markdown>{item.data}</Markdown></StyledParagraph>
			}
			case 'button':
				if (!textAlign) {
					textAlign = 'center'
				}
				return <Box textAlign={textAlign} p={1}>{this.createButton(item)}</Box>
			case 'video':
				return this.createVideo(item)
			case 'widget':
				return this.createWidget(item)
			default: {
				return <StyledParagraph variant="body1">
					<pre>{`Unknown type: ${item.type}￿`}</pre>
				</StyledParagraph>
			}
		}
	}

	createButton(data) {
		const variant = data.variant || 'contained'
		const size = data.size || 'medium'
		const color = data.color || 'primary'
		const location = data.location
		const label = data.label || '???'
		if (!location) {
			throw new Error(`Missing location info for item: ${data}`)
		}
		const targetType = data.targetType || (location.startsWith('/') ? 'internal' : 'external')
		if (targetType === 'internal') {
			return (
				<Button
					variant={variant}
					size={size}
					color={color}
					component={Link}
					to={location}
				>
					{label}
				</Button>
			)
		}
		if (targetType === 'external') {
			return (
				<Button
					variant={variant}
					size={size}
					color={color}
					href={location}
					target="_blank"
				>
					{label}
				</Button>
			)
		}
	}

	createWidget(data) {
		switch (data.variant) {
			case 'sellerList':
				return this.createSellerListWidget(data)
			default:
				return <pre>Unknown widget variant: ${data.variant}</pre>
		}
	}

	createVideo(data) {
		const url = data.url
		if (!url) {
			return <MaterialLink to={url} target="_blank">Video</MaterialLink>
		}
		return (
			<VideoWrapper>
				<VideoPlayer
					url={url}
					width='100%'
					height='100%'
					controls={true}
				/>
			</VideoWrapper>
		)
	}

	createSellerListWidget(data) {
		const location = data.location || 'all'
		const items = data.items || 3
		const tags = data.tags || []

		let restaurants = this.props.restaurants
		if (tags.length > 0) {
			restaurants = restaurants.filter((restaurant) => {
				return tags.filter((tag) => (restaurant.tags || []).includes(tag)).length > 0
			})
		}
		restaurants = restaurants.slice(0, items)

		return <RestaurantGrid
			restaurants={restaurants}
			alive={this.props.alive}
		/>
	}

	fetchContent() {
		this.props.API.get(`/blog/${this.props.name}/post.json`, {
			S3: true
		})
		.then(result => {
			console.log(result)
			this.setState({
				content: result
			})
		})
		.catch(() => this.props.enqueueSnackbar(
			'Nepodařilo se načíst příspěvek. Zkuste to prosím později.',
			{variant: 'error'}
		))
	}
}
