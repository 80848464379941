import {ContainerGrid, LayoutZone, Padding} from '../../style/lib'
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Accordion,
	Fab,
	Grid,
	InputAdornment,
	Link as MaterialLink,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	Paper,
	Typography,
	Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions
} from '@material-ui/core'
import {withSnackbar} from 'notistack'
import PageLoader from '../PageLoader'
import IconPhone from 'mdi-material-ui/Phone'
import IconWeb from 'mdi-material-ui/Web'
import IconEmail from 'mdi-material-ui/Email'
import moment from 'moment'
import Chip from '@material-ui/core/Chip'
import IconMinus from 'mdi-material-ui/Minus'
import IconPlus from 'mdi-material-ui/Plus'
import Big from 'big.js'
import {Alert, AlertTitle} from '@material-ui/lab'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import Select from '../Select'
import validator from 'validator'
import {Link} from '@reach/router'
import memo from 'memoize-one'
import {getMenuStructured} from '../../module/menuHelpers'
import Facebook from 'mdi-material-ui/Facebook'
import Instagram from 'mdi-material-ui/Instagram'
import LinkedIn from 'mdi-material-ui/Linkedin'
import Twitter from 'mdi-material-ui/Twitter'
import IconPickup from 'mdi-material-ui/Shopping'
import IconDelivery from 'mdi-material-ui/Truck'
import IconTable from 'mdi-material-ui/TableFurniture'
import {Flex, FlexItem} from '../Flex'
import {Sticky, StickyContainer} from '../Sticky'
import {Anchor, goToAnchor} from '../Anchor'
import {css} from 'styled-components'
import IconClock from 'mdi-material-ui/Clock'
import {MetaTags} from 'react-meta-tags'
import Bubble from '../Bubble'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import geolocator from 'geolocator'
import {processSeznamEvent} from '../../module/processSeznamEvent'
import {FacebookButton, SignInButton} from '../SignInWidget'
import IconAdmin from 'mdi-material-ui/ShieldAccount'
import queryString from 'query-string'

const localStorageOrderDataPrefix = 'LastOrderData'

const paymentTypesMap = {
	CASH: 'hotovost',
	CARD: 'karta',
	MEAL_VOUCHER: 'stravenky'
}

const StyledCartConfirmationFab = styled(Fab)`
	width: 100%;
`

const StyleMenuItemBottom = styled(Grid)`
	flex-grow: 1;
`

const StyledMyOrderPaper = styled(Paper)`
	min-height: ${props => props.summary ? 0 : 200}px;
	display: flex;
	flex-direction: column;
`

const StyledEmptyCartNote = styled.div`
	margin: auto;
	color: rgba(0, 0, 0, .56);
`

const StyledThumbnailImg = styled.img`
	width: 54px;
	height: 54px;
	border-radius: 2px;
`

const StyledStickyCart = styled(Sticky)`
	position: relative;
	width: 100%;
	max-height: 0;
	z-index: 110;
`

const StyledCompactCartBackground = styled.div`
	position: absolute;
	background-color: #fbfbfb;
	top: 0;
	left: -8px;
	right: -8px;
	height: 104px;
	
	&:after {
		position: absolute;
		height: 36px;
		bottom: -36px;
		left: 0;
		right: 0;
		content: '';
		background: -moz-linear-gradient(top, rgba(251,251,251,1) 0%, rgba(251,251,251,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(251,251,251,1) 0%,rgba(251,251,251,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(251,251,251,1) 0%,rgba(251,251,251,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbfbfb', endColorstr='#00fbfbfb',GradientType=0 ); /* IE6-9 */
	}
`

const StyledCompactCart = styled(Paper)`
	position: absolute;
	top: -8px;
	left: 0;
	right: 0;
	height: 112px;
	padding-top: 8px;
	padding-bottom: 4px;
	display: flex;
	align-items: center;
	
	.btn {
		width: 100%;
	}
`

const StyledCompactCartWrapper = styled.div`
	display: none;
`

const StyledReportTextField = styled(TextField)`
	textarea {
		min-height: 100px;
		resize: vertical;
	}
`

@withAPI @withSnackbar @withUser @withScreen @withLocation
export default class Restaurant extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.stickyTop = []

		const orderPreset = this.getOrderPreset()

		const query = new URLSearchParams(window.location.search)

		console.log('AAA', query.get('tableId'))

		this.state = {
			preselectedTableId: query.get('tableId') ?? null,
			restaurant: null,
			menu: null,
			order: orderPreset?.order ?? [],
			ordering: !!orderPreset,
			orderType: orderPreset?.orderType ?? query.get('orderType') ?? '',
			note: orderPreset?.note ?? '',
			phone: orderPreset?.phone ?? '',
			email: orderPreset?.email ?? '',
			name: orderPreset?.name ?? '',
			deliveryStreet: orderPreset?.deliveryStreet ?? (this.props.location.street || ''),
			deliveryCity: orderPreset?.deliveryCity ?? (this.props.location.city || ''),
			deliveryZip: orderPreset?.deliveryZip ?? (this.props.location.zip || ''),
			pickupTime: orderPreset?.pickupTime ?? null,
			lastOnline: null,
			pickupAdvance: 0,
			pickupTimes: {}, // {[dayOffset]: [{from: '00:00', to: '00:00'}, ...], ...}
			pickupDay: orderPreset?.pickupDay ?? '',
			mobileBar: true,
			orderNoteEnabled: false,
			customerInfoTab: +!!this.props.user.authorized,
			phoneVerificationToken: null,
			eatInTable: orderPreset?.eatInTable ?? query.get('tableId') ?? '',
			reportDialog: false,
			reportReason: '',
			reporting: false,
			reportSnitchPhone: '',
			reportSnitchEmail: '',
			payment: orderPreset?.payment ?? false
		}
	}

	render() {
		if (!this.state.restaurant) {
			return <PageLoader/>
		}

		const restaurant = this.state.restaurant
		const contacts = restaurant.contacts || []
		const hours = Object.keys(restaurant.hours || {}) || []
		const deliveryHours = Object.keys(restaurant.deliveryHours || {}) || []
		const tags = restaurant.tags || []
		const menu = this.state.menu
		const order = [...this.state.order]
		const isDeliveryOpen = this.isDeliveryOpen(restaurant.deliveryHours || {}, +moment().startOf('minute'))
		const isPickupOpen = this.isPickupOpen(this.state.pickupTimes)
		const isRestaurantOpen = isDeliveryOpen || isPickupOpen
		const isDeliveryEnabled = isDeliveryOpen && restaurant.delivery
		const isPickupEnabled = isPickupOpen && restaurant.pickup
		const isEatInEnabled = isPickupOpen && restaurant.eatIn && !!(restaurant.eatInTables || []).length
		const ordersEnabled = isDeliveryEnabled || isPickupEnabled || isEatInEnabled
		const isOffline = ((this.props.alive || {})[this.props.restaurantId] || 0) < (Date.now() - 30 * 60000)
		const hasAnyFractionNumber = this.hasAnyFractionNumber(this.state.menu)
		const thumbnailSplit = (restaurant.thumbnail || '').split(':')
		const thumbnailType = thumbnailSplit[0]
		const thumbnailId = thumbnailSplit[1]
		const title = `${restaurant.name} | ${window.WebName}`
		const thumbnail = `${S3_URI}/restaurant-thumbnail/${thumbnailId}`
		const totalPrice = this.totalPrice(order)

		const additionalItems = this.getAdditionalItems()
		const belowMinimum = restaurant.deliveryMinimumTotal
			? Big(totalPrice).lt(restaurant.deliveryMinimumTotal)
			: false
		const isGratis = !restaurant.deliveryPrice
			|| (restaurant.deliveryGratisThreshold && Big(totalPrice).gte(restaurant.deliveryGratisThreshold))
		const totalText = <>{this.totalPrice([...order, ...additionalItems])}&nbsp;Kč</>

		return (
			<>
				<MetaTags>
					<title>{title}</title>
					<meta name="description" content={restaurant.description}/>
					<meta property="og:title" content={title}/>
					<meta property="og:image" content={thumbnail}/>
				</MetaTags>
				{!!(this.props.screen.width < 960 && order.length && this.state.mobileBar && !this.state.ordering) && (
					<MobileBar toggleOrdering={this.toggleOrdering} total={totalText}/>
				)}
				<LayoutZone verticalPadding>
					<Flex spacing={4} align="stretch">
						<FlexItem item xs={12} md={7} lg={8}>
							<Flex spacing={2}>
								<FlexItem xs={12}>
									<Flex spacing={2} wrap={false}>
										{thumbnailType === 'custom' && (
											<FlexItem shrink={false}>
												<StyledThumbnailImg
													src={`${thumbnail}`}
												/>
											</FlexItem>
										)}
										<FlexItem>
											<Typography variant="h5" component="h1">
												{restaurant.name}
											</Typography>
											<Typography variant="subtitle1">
												{restaurant.address}, {restaurant.city}
											</Typography>
										</FlexItem>
									</Flex>
								</FlexItem>
								{(isRestaurantOpen && ordersEnabled && isOffline) && (
									<FlexItem xs={12}>
										<Alert severity="warning">
											<AlertTitle>
												Prodejce nyní nemá zapnuté upozornění na nové objednávky
											</AlertTitle>
											Je možné, že Vaše objednávka nebude odbavena okamžitě.
										</Alert>
									</FlexItem>
								)}
								{(isRestaurantOpen && !ordersEnabled) && (
									<FlexItem xs={12}>
										<Alert severity="warning">
											<AlertTitle>Prodejce nyní nepřijímá objednávky</AlertTitle>
											Je nám líto, ale prodejce nyní neumožňuje vytvořit novou objednávku.
										</Alert>
									</FlexItem>
								)}
								{!isRestaurantOpen && (
									<FlexItem xs={12}>
										<Alert severity="warning">
											<AlertTitle>Prodejce nyní nepřijímá objednávky</AlertTitle>
											Je nám líto, ale provozní doba prodejce dnes již neumožňuje vytvořit
											objednávku.
										</Alert>
									</FlexItem>
								)}
								<FlexItem xs={12}>
									<Paper variant="outlined">
										<Padding>
											{this.state.restaurant.description.split('\n').map((line, index) => (
												<Typography key={index}>
													{line}
												</Typography>
											))}
										</Padding>
									</Paper>
								</FlexItem>
								{this.state.preselectedTableId !== null && !this.state.ordering && (
									<FlexItem xs={12}>
										<Alert
											severity="info"
											action={<Button onClick={this.onCancelPreselectedTable}>Zrušit</Button>}
											icon={<IconTable/>}
										>
											Objednáváte na stůl <b>{this.state.restaurant?.eatInTables?.find(t => t.id === this.state.preselectedTableId)?.name}</b>.
										</Alert>
									</FlexItem>
								)}
								<FlexItem xs={12}>
									<Anchor
										id="orderPageContentTop"
										topElements={this.stickyTop}
									>
										{this.state.ordering ? (
											<OrderForm
												onCancelPreselectedTable={this.onCancelPreselectedTable}
												parentState={this.state}
												setParentState={this.setSelfState}
												send={this.send}
												pickupEnabled={isPickupEnabled}
												deliveryEnabled={isDeliveryEnabled}
												eatInEnabled={isEatInEnabled}
												isGratis={isGratis}
												belowMinimum={belowMinimum}
												order={order}
												totalItemPrice={this.totalItemPrice}
												additionalItems={additionalItems}
												ordersEnabled={ordersEnabled}
												isPickupEnabled={isPickupEnabled}
												toggleOrdering={this.toggleOrdering}
												ordering={this.state.ordering}
												totalText={totalText}
											/>
										) : (
											<Menu
												menu={menu}
												order={order}
												updateOrder={this.updateOrder}
												getItemCount={this.getItemCount}
												ordersEnabled={ordersEnabled}
												hasAnyFractionNumber={hasAnyFractionNumber}
											/>
										)}
									</Anchor>
								</FlexItem>
							</Flex>
						</FlexItem>
						<FlexItem xs={12} md={5} lg={4}>
							<StickyContainer>
								<Flex spacing={2}>
									{this.props.screen.width >= 960 &&
									<Grid item xs={12}>
										<Anchor
											id="orderCart"
											topElements={this.stickyTop}
											onViewIn={this.cartViewIn}
											onViewOut={this.cartViewOut}
										>
											<Cart
												restaurant={restaurant}
												order={order}
												totalItemPrice={this.totalItemPrice}
												additionalItems={additionalItems}
												ordersEnabled={ordersEnabled}
												belowMinimum={belowMinimum}
												isPickupEnabled={isPickupEnabled}
												toggleOrdering={this.toggleOrdering}
												ordering={this.state.ordering}
												totalText={totalText}
											/>
										</Anchor>
										{!!(order.length && !this.state.ordering) && (
											<StyledStickyCart
												topElements={this.stickyTop}
												onStick={this.cartStick}
												onRelease={this.cartRelease}
												bottom={96}
											>
												<StyledCompactCartWrapper ref={ref => this.compactCart = ref}>
													<StyledCompactCartBackground/>
													<StyledCompactCart>
														<CompactCart
															toggleOrdering={this.toggleOrdering}
															total={totalText}
														/>
													</StyledCompactCart>
												</StyledCompactCartWrapper>
											</StyledStickyCart>
										)}
									</Grid>
									}
									{(restaurant.deliveryGratisThreshold && isDeliveryEnabled) && (
										<Grid item xs={12}>
											<Alert severity="success" icon={<IconDelivery/>}>
												Doprava zdarma při nákupu
												od {restaurant.deliveryGratisThreshold} Kč.
											</Alert>
										</Grid>
									)}
									{isDeliveryEnabled && (
										<Grid item xs={12}>
											<Paper>
												<List dense subheader={
													<ListSubheader disableSticky>
														<StyledListSubheaderIcon>
															<IconDelivery fontSize="inherit" color="inherit"/>
														</StyledListSubheaderIcon>
														Informace k rozvozu
													</ListSubheader>
												}>
													{restaurant.deliveryPrice && (
														<ListItem>
															<ListItemText primary="Cena"/>
															<ListItemSecondaryAction>
																<Typography variant="body2" color="textSecondary">
																	{restaurant.deliveryPrice} Kč
																</Typography>
															</ListItemSecondaryAction>
														</ListItem>
													)}
													<ListItem>
														<ListItemText primary="Maximální vzdálenost"/>
														<ListItemSecondaryAction>
															<Typography variant="body2" color="textSecondary">
																{restaurant.deliveryDistanceKm} km
															</Typography>
														</ListItemSecondaryAction>
													</ListItem>
													{restaurant.deliveryMinimumTotal && (
														<ListItem>
															<ListItemText primary="Minimální hodnota objednávky"/>
															<ListItemSecondaryAction>
																<Typography variant="body2" color="textSecondary">
																	{restaurant.deliveryMinimumTotal} Kč
																</Typography>
															</ListItemSecondaryAction>
														</ListItem>
													)}
												</List>
											</Paper>
										</Grid>
									)}
									<Grid item xs={12}>
										<StaticInfo
											contacts={contacts}
											hours={hours}
											allHours={restaurant.hours}
											deliveryHours={deliveryHours}
											allDeliveryHours={restaurant.deliveryHours}
											tags={tags}
											restaurant={restaurant}
											report={this.openReportDialog}
										/>
									</Grid>
								</Flex>
							</StickyContainer>
						</FlexItem>
					</Flex>
				</LayoutZone>
				<Dialog
					open={this.state.reportDialog}
					onClose={this.closeReportDialog}
					maxWidth="sm"
					scroll="body"
					fullWidth
				>
					<form onSubmit={this.report}>
						<DialogTitle>Nahlášení prodejce</DialogTitle>
						<DialogContent>
							<Flex spacing={2}>
								<FlexItem xs={12}>
									<StyledReportTextField
										required
										multiline autoFocus fullWidth
										variant="outlined"
										value={this.state.reportReason}
										onChange={this.changeReportReason}
										inputProps={{maxLength: 1600}}
										placeholder="Důvod nahlášení"
									/>
								</FlexItem>
								<FlexItem xs={12}>
									<Typography variant="subtitle2">
										Napište nám kontakt, ať vám můžeme dát vědět, co jsme zjistili.
									</Typography>
								</FlexItem>
								<FlexItem xs={12} md={6}>
									<TextField
										fullWidth required
										variant="outlined"
										value={this.state.reportSnitchPhone}
										onChange={this.changeReportSnitchPhone}
										placeholder="Váš telefon"
									/>
								</FlexItem>
								<FlexItem xs={12} md={6}>
									<StyledReportTextField
										fullWidth required
										variant="outlined"
										value={this.state.reportSnitchEmail}
										onChange={this.changeReportSnitchEmail}
										placeholder="Váš e-mail"
									/>
								</FlexItem>
							</Flex>
						</DialogContent>
						<DialogActions>
							{this.state.reporting && (
								<CircularProgress size={24}/>
							)}
							<Button
								onClick={this.closeReportDialog}
								disabled={this.state.reporting}
							>
								Zrušit
							</Button>
							<Button
								color="primary"
								disabled={this.state.reportReason === '' || this.state.reporting}
								type="submit"
							>
								Nahlásit
							</Button>
						</DialogActions>
					</form>
				</Dialog>
			</>
		)
	}

	componentDidMount() {
		this.fetchRestaurant()
		this.stickyTop.push(document.getElementById('AppBar'))
	}

	componentWillUnmount() {
		clearTimeout(this.earliestTimeout)
		clearInterval(this.earliestInterval)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.restaurantId !== this.props.restaurantId) {
			this.fetchRestaurant()
		}

		if (prevState.ordering !== this.state.ordering) {
			goToAnchor('orderPageContentTop', {behavior: 'auto'})
		}

		if (prevProps.user.authorized !== this.props.user.authorized) {
			this.setState({
				phoneVerificationToken: null,
				sending: false
			})
		}
	}

	static getDerivedStateFromProps(props, state) {
		return {
			customerInfoTab: props.user.authorized ? 1 : state.customerInfoTab
		}
	}

	hasAnyFractionNumber = memo(menu => menu.filter(({price}) => price % 1 !== 0).length > 0)

	fetchRestaurant() {
		Promise.all([
			this.props.API.get(`/restaurant/${this.props.restaurantId}.json`, {S3: true}),
			this.props.API.get(`/menu/${this.props.restaurantId}.json`, {S3: true}).catch(Function.prototype),
			this.fetchAppInfo().catch(Function.prototype)
		])
		.then(([restaurant, menu, appInfo]) => {
			const lastOnline = (appInfo && appInfo.lastOnline)
				? moment.utc(appInfo.lastOnline, 'x').local()
				: null
			const pickupAdvanceSupported = !!(appInfo && appInfo.nextDayPickupEnabled === true)
			const pickupAdvance = +(
				(pickupAdvanceSupported && typeof restaurant.pickupAdvance !== 'undefined')
					? restaurant.pickupAdvance
					: Math.min(1, restaurant.pickupAdvance || 1)
			)

			const now = +moment().startOf('minute')
			const pickupTimes = getPickupTimes(
				pickupAdvanceSupported,
				pickupAdvance,
				restaurant.hours,
				restaurant.pickupPrepareMinutes,
				now
			)
			const pickupTimesKeys = Object.keys(pickupTimes)
			const isDeliveryEnabled = restaurant.delivery && this.isDeliveryOpen(restaurant.deliveryHours || {}, now)
			const isPickupEnabled = restaurant.pickup && !!pickupTimesKeys.length
			const isEatInEnabled = !!restaurant.eatIn
			const presetOrderType = (this.state.orderType === 'pickup' && isPickupEnabled) ? 'pickup' : (
				(this.state.orderType === 'delivery' && isDeliveryEnabled) ? 'delivery' : (
					this.state.orderType === 'eatIn' && isEatInEnabled ? 'eatIn' : null
				)
			)
			const orderType = presetOrderType ?? (isPickupEnabled ? 'pickup' : (isDeliveryEnabled ? 'delivery' : (isEatInEnabled ? 'eatIn' : '')))

			const presetPickupDay = this.state.pickupDay
				? pickupTimesKeys.find(x => x.toString() === this.state.pickupDay)?.toString()
				: null

			const presetPickupTime = (presetPickupDay && this.state.pickupTime)
				? pickupTimes[presetPickupDay].find(x => x.value === this.state.pickupTime)?.value
				: null

			const presetEatInTable = this.state.eatInTable ?? null

			const pickupDay = presetPickupDay ?? (pickupTimesKeys.length ? pickupTimesKeys[0].toString() : null)

			this.setState({
				restaurant: {
					...restaurant,
					paymentTypes: {
						pickup: restaurant.paymentTypes?.pickup ?? [],
						delivery: restaurant.paymentTypes?.delivery ?? [],
						eatIn: restaurant.paymentTypes?.eatIn ?? []
					}
				},
				lastOnline, pickupAdvance,
				menu: menu ? menuVisibilityFilter(menu.menu) : [],
				orderType,
				payment: !!(restaurant.onlinePayments || {})[orderType],
				pickupTimes: pickupTimes,
				pickupDay,
				pickupTime: presetPickupTime ?? (pickupTimesKeys.length ? pickupTimes[pickupDay][0].value : null),
				orderNoteEnabled: !!(appInfo && appInfo.orderNoteEnabled),
				pickupEnabled: isPickupEnabled,
				deliveryEnabled: isDeliveryEnabled,
				eatInTable: presetEatInTable ?? restaurant.eatInTables?.[0]?.id ?? null
			})

			// Available times minutely updater
			const update = () => {
				const now = +moment().startOf('minute')
				const pickupTimes = getPickupTimes(
					pickupAdvanceSupported,
					pickupAdvance,
					restaurant.hours,
					restaurant.pickupPrepareMinutes,
					now
				)
				const pickupTimesKeys = Object.keys(pickupTimes)
				const isDeliveryEnabled = restaurant.delivery && this.isDeliveryOpen(restaurant.deliveryHours || {}, now)
				const isPickupEnabled = restaurant.pickup && !!pickupTimesKeys.length

				this.setState(prevState => {
					const pickupDay = pickupTimes[prevState.pickupDay]
						? prevState.pickupDay
						: (pickupTimesKeys.length ? pickupTimesKeys[0].toString() : null)

					const pickupTime = pickupDay
						? (pickupTimes[pickupDay].find(x => x.value === prevState.pickupTime)
								? prevState.pickupTime
								: pickupTimes[pickupDay][0].value
						)
						: null

					const ordering = prevState.ordering && (isPickupEnabled || isDeliveryEnabled)
					const orderType = (() => {
						if (isPickupEnabled && prevState.orderType === 'pickup') return 'pickup'
						if (isDeliveryEnabled && prevState.orderType === 'delivery') return 'delivery'
						if (prevState.orderType === 'eatIn') return 'eatIn'
						return isPickupEnabled ? 'pickup' : (isDeliveryEnabled ? 'delivery' : (isEatInEnabled ? 'eatIn' : ''))
					})()

					if ((prevState.pickupEnabled || prevState.deliveryEnabled) && !isPickupEnabled && !isDeliveryEnabled) {
						this.props.enqueueSnackbar(
							'Omlouváme se, ale z restaurace již není možné objednat.',
							{variant: 'warning', autoHideDuration: 15000}
						)
					} else if (prevState.ordering && prevState.orderType === 'delivery' && orderType !== 'delivery') {
						this.props.enqueueSnackbar(
							'Omlouváme se, ale z restaurace již není možné objednat k doručení. Typ převzetí byl změněn na Vyzvednutí.',
							{variant: 'warning', autoHideDuration: 15000}
						)
					} else if (prevState.ordering && prevState.orderType === 'pickup' && orderType !== 'pickup') {
						this.props.enqueueSnackbar(
							'Omlouváme se, ale z restaurace již není možné objednat k vyzvednutí. Typ převzetí byl změněn na Doručení.',
							{variant: 'warning', autoHideDuration: 15000}
						)
					} else if (prevState.ordering && prevState.orderType === 'pickup' && pickupDay !== prevState.pickupDay) {
						this.props.enqueueSnackbar(
							'Omlouváme se, čas pokročil. Den vyzvednutí byl změněn dle provozní doby.',
							{variant: 'warning', autoHideDuration: 15000}
						)
					}

					return {
						ordering, orderType,
						pickupTimes, pickupDay, pickupTime,
						pickupTimeAdjusted: pickupTime !== prevState.pickupTime,
						pickupEnabled: isPickupEnabled,
						deliveryEnabled: isDeliveryEnabled
					}
				})
			}

			this.earliestTimeout = setTimeout(() => {
				update()
				this.earliestInterval = setInterval(update, 60000)
			}, moment().add(1, 'minute').startOf('minute') - moment())
		})
		.catch(error => {
			if (error === 'NOT_FOUND' || error.status === 404) {
				this.props.navigate('/404')
			}

			console.log(error)
		})
	}

	fetchAppInfo() {
		return new Promise((resolve, reject) => {
			this.props.API.get(`/app/ping?id=${this.props.restaurantId}`)
			.then(resolve)
			.catch(reject)
		})
	}

	updateOrder(updater) {
		this.setState(prevState => ({
			order: updater(prevState.order)
		}))
	}

	getItemCount(menuItem) {
		const item = this.state.order.find(x => x.id === menuItem.id)
		return item.quantity || 0
	}

	totalItemPrice(item) {
		return Big(item.price).round(2, 3).times(item.quantity).toFixed(this.hasAnyFractionNumber(this.state.menu) ? 2 : 0)
	}

	totalPrice = memo(items => {
		return items.reduce((sum, item) => sum.add(this.totalItemPrice(item)), Big(0)).toFixed(this.hasAnyFractionNumber(this.state.menu) ? 2 : 0)
	})

	toggleOrdering() {
		this.setState(prevState => ({
			ordering: !prevState.ordering
		}), () => {
			if (this.state.ordering && gtag) gtag('event', 'begin_checkout')
		})
	}

	setSelfState(...args) {
		this.setState(...args)
	}

	send(e, phoneVerificationCode) {
		e && e.preventDefault()

		if (this.state.orderType === 'pickup' && !this.state.pickupTime) {
			return this.props.enqueueSnackbar('Vyberte prosím čas vyzvednutí.', {variant: 'error'})
		}

		if (this.state.orderType === 'delivery' && (
			!this.state.deliveryStreet
			|| !this.state.deliveryCity
			|| !this.state.deliveryZip
		)) {
			return this.props.enqueueSnackbar('Vyplňte prosím celou adresu.', {variant: 'error'})
		}

		if (!this.props.user.authorized && this.state.email && !validator.isEmail(this.state.email)) {
			return this.props.enqueueSnackbar('Neplatná e-mailová adresa.', {variant: 'error'})
		}

		if (!this.props.user.authorized && this.state.name === '') {
			return this.props.enqueueSnackbar('Prosím, zadejte své jméno.', {variant: 'error'})
		}

		if (!this.props.user.authorized && !validator.isMobilePhone(this.state.phone, ['cs-CZ', 'sk-SK'])) {
			return this.props.enqueueSnackbar('Neplatné telefonní číslo.', {variant: 'error'})
		}

		if (!this.props.user.authorized && !this.state.phone.startsWith('+')) {
			this.state.phone = `+420${this.state.phone}`
		}

		gtag && gtag('event', 'checkout_progress')

		this.setState({sending: true}, () => {
			this.props.API.post(`/${this.props.user.authorized ? 'authorized' : 'public'}/order`, {
				body: {
					pay: this.state.payment,
					...this.state.phoneVerificationToken && {
						phoneVerificationToken: this.state.phoneVerificationToken,
						phoneVerificationCode
					},
					type: this.state.orderType,
					...this.state.orderType === 'delivery' && {
						deliveryStreet: this.state.deliveryStreet,
						deliveryCity: this.state.deliveryCity,
						deliveryZip: this.state.deliveryZip
					},
					...this.state.orderType === 'pickup' && {
						pickupTime: this.state.pickupTime
					},
					...this.state.orderType === 'eatIn' && {
						eatInTableId: this.state.eatInTable
					},
					note: this.state.note,
					...this.props.user.authorized || {
						customerName: this.state.name,
						customerEmail: this.state.email,
						customerPhone: this.state.phone
					},
					restaurantId: this.state.restaurant.id,
					items: [
						...this.state.order.map(x => ({
							id: x.id,
							name: x.name,
							description: x.description || '(bez popisu)',
							price: x.price,
							amount: x.quantity,
							externalId: x.externalId || 'null'
						})),
						...this.getAdditionalItems().map(x => ({
							id: x.id,
							name: x.name,
							description: x.description,
							price: x.price,
							amount: x.quantity,
							externalId: x.externalId || 'null'
						}))
					]
				}
			})
			.then(result => {
				if (result.phoneVerificationToken) {
					return this.setState({phoneVerificationToken: result.phoneVerificationToken}, () => {
						this.props.enqueueSnackbar('Zadejte prosím potvrzovací kód, který jsme Vám zaslali zprávou SMS.')
					})
				}

				this.storeOrderData(result.orderId)

				if (result.payRedirectUri) {
					window.location.href = result.payRedirectUri
					return
				}

				gtag && gtag('event', 'purchase')
				processSeznamEvent(100062713)

				this.props.enqueueSnackbar('Vaše objednávka byla odeslána restauraci. Děkujeme.', {variant: 'success'})
				this.props.navigate(`/t/${result.orderId}/confirmation`)
			})
			.catch(error => {
				if (error === 'INVALID_PHONE_VERIFICATION_TOKEN') {
					let token = this.state.phoneVerificationToken
					this.setState({phoneVerificationToken: null}, () => {
						this.setState({phoneVerificationToken: token}, () => {
							this.props.enqueueSnackbar('Zadaný kód z SMS zprávy nesouhlasí.', {variant: 'error'})
						})
					})

					return
				}

				console.log(error)
				this.props.enqueueSnackbar('Nastala chyba při zpracování Vaší objednávky. Zkuste to, prosím, za chvíli.', {variant: 'error'})
				this.setState({phoneVerificationToken: null, sending: false})
			})
		})
	}

	storeOrderData(orderId) {
		localStorage.setItem(`${localStorageOrderDataPrefix}.${this.state.restaurant.id}`, JSON.stringify({
			orderId,
			order: this.state.order,
			orderType: this.state.orderType,
			note: this.state.note,
			phone: this.state.phone,
			email: this.state.email,
			name: this.state.name,
			deliveryStreet: this.state.deliveryStreet,
			deliveryCity: this.state.deliveryCity,
			deliveryZip: this.state.deliveryZip,
			pickupTime: this.state.pickupTime,
			pickupDay: this.state.pickupDay,
			eatInTable: this.state.eatInTable
		}))
	}

	getOrderPreset() {
		const query = queryString.parse(window.location.search)
		if (!query.preset) return null

		try {
			const lastOrder = JSON.parse(
				localStorage.getItem(`${localStorageOrderDataPrefix}.${this.props.restaurantId}`)
			)

			return lastOrder.orderId === query.preset ? lastOrder : null
		} catch (e) {
			return null
		}
	}

	cartStick() {
		this.compactCart.style.display = 'block'
	}

	cartRelease() {
		this.compactCart.style.display = 'none'
	}

	isPickupOpen = memo(pickupTimes => !!Object.keys(pickupTimes).length)

	isDeliveryOpen = memo((hours, currentMinute) => {
		const weekday = moment().weekday()
		const [_, extraToday] = extractNextDay(hours[(weekday + 6) % 7] || [])
		const [deliveryHoursToday] = extractNextDay([...(hours[weekday] || [])])

		deliveryHoursToday.push(...extraToday)

		if (!deliveryHoursToday.length) return false

		for (const range of deliveryHoursToday) {
			const from = getMomentFromRangeString(range.from)
			const until = getMomentFromRangeString(range.until)
			if (from.isAfter(moment())) continue
			if (until.isBefore(moment().add(30, 'minute'))) continue
			return true
		}

		return false
	})

	getAdditionalItems() {
		if (this.state.orderType !== 'delivery') return []
		if (!this.state.ordering) return []

		const restaurant = this.state.restaurant
		const total = Big(this.totalPrice(this.state.order))

		const additional = []

		if (restaurant.deliveryPrice && !(
			restaurant.deliveryGratisThreshold
			&& total.gte(restaurant.deliveryGratisThreshold)
		)) {
			additional.push({
				name: 'Doprava',
				description: 'Dovoz k zákazníkovi',
				id: 'delivery',
				quantity: 1,
				price: this.state.restaurant.deliveryPrice,
				externalId: this.state.restaurant.deliveryExternalId || 'null'
			})
		}

		if (restaurant.deliveryMinimumTotal && restaurant.deliverySupplement === true) {
			const supplement = Big(restaurant.deliveryMinimumTotal).minus(total).round(2)
			if (supplement.gt(0)) {
				additional.push({
					name: 'Doplatek do minimální hodnoty objednávky',
					description: 'Doplatek',
					id: 'delivery-supplement',
					quantity: 1,
					price: supplement.toString(),
					externalId: this.state.restaurant.deliverySupplementExternalId || 'null'
				})
			}
		}

		return additional
	}

	cartViewIn() {
		this.setState({mobileBar: false})
	}

	cartViewOut() {
		this.setState({mobileBar: true})
	}

	openReportDialog() {
		this.setState({reportDialog: true})
	}

	closeReportDialog() {
		this.setState({reportDialog: false, reportReason: ''})
	}

	changeReportReason(e) {
		this.setState({reportReason: e.target.value})
	}

	changeReportSnitchPhone(e) {
		this.setState({reportSnitchPhone: e.target.value})
	}

	changeReportSnitchEmail(e) {
		this.setState({reportSnitchEmail: e.target.value})
	}

	report(e) {
		e.preventDefault()

		this.setState({reporting: true}, () => {
			this.props.API.post('/web/support?type=reportSeller', {
				body: {
					restaurantId: this.props.restaurantId,
					reason: `Zákazník (telefon: ${this.state.reportSnitchPhone}, email: ${this.state.reportSnitchEmail}) nahlásil prodejce:`
						+ '\r\n----------\r\n'
						+ this.state.reportReason
				}
			})
			.then(() => {
				this.setState({
					reportDialog: false,
					reportReason: '',
					reportSnitchPhone: '',
					reportSnitchEmail: '',
					reporting: false
				})
				this.props.enqueueSnackbar('Prodejce byl nahlášen. Děkujeme.', {
					variant: 'success'
				})
			})
			.catch(() => {
				this.setState({reporting: false})
				this.props.enqueueSnackbar('Nastala chyba při nahlášení. Zkuste to, prosím, později.', {
					variant: 'error'
				})
			})
		})
	}

	onCancelPreselectedTable() {
		this.setState({preselectedTableId: null})
	}
}

const StyledContactListItemText = styled(ListItemText)`
	overflow-wrap: break-word;
`

const StyledListSubheaderIcon = styled.span`
	position: relative;
	top: 2px;
	margin-right: 8px;
`

const StyledFlexListItemText = styled(ListItemText)`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	
	> *:last-child {
		margin-left: auto;
		padding-left: 8px;
	}
`

@withAPI @withUser @withSnackbar
class StaticInfo extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.state = {
			enableOnlinePaymentsProcessing: false,
			disableOnlinePaymentsProcessing: false
		}
	}

	render() {
		const pickup = !!(this.props.hours.length && this.props.restaurant.pickup)
		const delivery = !!(this.props.deliveryHours.length && this.props.restaurant.delivery)
		const restaurant = this.props.restaurant
		return (
			<Flex spacing={2}>
				{!!(this.props.contacts.length || pickup || delivery) && (
					<FlexItem xs={12}>
						<Paper>
							{[
								(pickup || delivery) ? (
									<Flex>
										{pickup && (
											<FlexItem xs={delivery ? 6 : 12}>
												<List dense subheader={
													<ListSubheader disableSticky>
														<StyledListSubheaderIcon>
															<IconClock fontSize="inherit" color="inherit"/>
														</StyledListSubheaderIcon>
														Osobní vyzvednutí
													</ListSubheader>
												}>
													{this.props.hours.map(day => (
														<OpeningHoursDay
															key={day.toString()}
															day={day}
															allHours={this.props.allHours}
														/>
													))}
												</List>
											</FlexItem>
										)}
										{delivery && (
											<FlexItem xs={pickup ? 6 : 12}>
												<List dense subheader={
													<ListSubheader disableSticky>
														<StyledListSubheaderIcon>
															<IconClock fontSize="inherit" color="inherit"/>
														</StyledListSubheaderIcon>
														Rozvoz
													</ListSubheader>
												}>
													{this.props.deliveryHours.map(day => (
														<OpeningHoursDay
															key={day.toString()}
															day={day}
															allHours={this.props.allDeliveryHours}
														/>
													))}
												</List>
											</FlexItem>
										)}
									</Flex>
								) : null,
								this.props.contacts.length ? (
									<List>
										{this.props.contacts.map(contact => (
											<ListItem
												key={JSON.stringify(contact)}
												button
												component="a"
												href={getContactClickableUri(contact)}
												target={contact.type === 'web' ? '_blank' : ''}
											>
												<ListItemIcon>
													<ContactIcon contact={contact}/>
												</ListItemIcon>
												<StyledContactListItemText>
													<ContactName contact={contact}/>
												</StyledContactListItemText>
											</ListItem>
										))}
									</List>
								) : null,
								((pickup && restaurant.paymentTypes.pickup.length) || (delivery && restaurant.paymentTypes.delivery.length)) ? (
									<List>
										{!!(pickup && restaurant.paymentTypes.pickup.length) && (
											<ListItem dense>
												{restaurant.onlinePaymentsExclusive?.pickup ?
													<StyledFlexListItemText
														primary="Platba (vyzvednutí)"
														secondary="Karta (online)"
													/>
												:
													<StyledFlexListItemText
														primary="Platba (vyzvednutí)"
														secondary={restaurant.paymentTypes.pickup.map(x => paymentTypesMap[x]).join(', ')}
													/>
												}
											</ListItem>
										)}
										{!!(delivery && restaurant.paymentTypes.delivery.length) && (
											<ListItem dense>
												{restaurant.onlinePaymentsExclusive?.delivery ?
													<StyledFlexListItemText
														primary="Platba (rozvoz)"
														secondary="Karta (online)"
													/>
													:
													<StyledFlexListItemText
														primary="Platba (rozvoz)"
														secondary={restaurant.paymentTypes.delivery.map(x => paymentTypesMap[x]).join(', ')}
													/>
												}
											</ListItem>
										)}
									</List>
								) : null
							].filter(Boolean).map((section, index) => (
								<React.Fragment key={index}>
									{index !== 0 && <Divider/>}
									{section}
								</React.Fragment>
							))}
						</Paper>
					</FlexItem>
				)}
				{!!this.props.tags.length && (
					<FlexItem xs={12}>
						<Grid container spacing={1}>
							{this.props.tags.map(tag => (
								<Grid item key={tag}>
									<Chip size="small" label={tag}/>
								</Grid>
							))}
						</Grid>
					</FlexItem>
				)}
				<FlexItem xs={12}>
					<Divider/>
				</FlexItem>
				<FlexItem xs={12}>
					<Button
						fullWidth
						onClick={this.props.report}
					>
						Nahlásit prodejce
					</Button>
				</FlexItem>
				{(this.props.user && (this.props.user.access_level === 2 || this.props.user.accessRoleSupport)) && (
					<>
						<FlexItem xs={12}>
							<Divider/>
						</FlexItem>
						<FlexItem xs={12}>
							<Flex align="center">
								<FlexItem>
									<IconAdmin style={{marginRight: 16}}/>
								</FlexItem>
								<FlexItem>
									<Typography variant="subtitle2">Možnosti podpory</Typography>
								</FlexItem>
							</Flex>
						</FlexItem>
						{this.props.user.access_level === 2 && (
							<FlexItem xs={12}>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									component={Link}
									to={`../panel/restaurant/${this.props.restaurant.id}`}
								>
									Spravovat provozovnu
								</Button>
							</FlexItem>
						)}
						<FlexItem xs={12}>
							<Button
								fullWidth
								variant="outlined"
								onClick={this.enableOnlinePayments}
								disabled={this.state.enableOnlinePaymentsProcessing || !restaurant.companyId}
							>
								{this.state.enableOnlinePaymentsProcessing ? (
									<CircularProgress size={24}/>
								) : `Aktivovat platební bránu (IČO: ${this.props.restaurant.companyId})`}
							</Button>
						</FlexItem>
						<FlexItem xs={12}>
							<Button
								fullWidth
								variant="outlined"
								onClick={this.disableOnlinePayments}
								disabled={this.state.disableOnlinePaymentsProcessing || !restaurant.companyId}
							>
								{this.state.disableOnlinePaymentsProcessing ? (
									<CircularProgress size={24}/>
								) : `Deaktivovat platební bránu (IČO: ${this.props.restaurant.companyId})`}
							</Button>
						</FlexItem>
					</>
				)}
			</Flex>
		)
	}

	enableOnlinePayments() {
		this.setState({enableOnlinePaymentsProcessing: true}, () => {
			this.props.API.patch(`/authorized/company/CZ-${this.props.restaurant.companyId}/enableOnlinePayments`)
			.then(() => {
				this.setState({enableP1Processing: false})
				this.props.enqueueSnackbar(`Platební brána pro IČO ${this.props.restaurant.companyId} byla aktivována.`, {
					variant: 'success'
				})
			})
			.catch(err => {
				console.error(err)
				this.setState({enableOnlinePaymentsProcessing: false})
				this.props.enqueueSnackbar('Nastala chyba při aktivaci platební brány.', {
					variant: 'error'
				})
			})
		})
	}

	disableOnlinePayments() {
		this.setState({disableOnlinePaymentsProcessing: true}, () => {
			this.props.API.patch(`/authorized/company/CZ-${this.props.restaurant.companyId}/disableOnlinePayments`)
			.then(() => {
				this.setState({enableP2Processing: false})
				this.props.enqueueSnackbar(`Platební brána pro IČO ${this.props.restaurant.companyId} byla deaktivována.`, {
					variant: 'success'
				})
			})
			.catch(err => {
				console.error(err)
				this.setState({disableOnlinePaymentsProcessing: false})
				this.props.enqueueSnackbar('Nastala chyba při deaktivaci platební brány.', {
					variant: 'error'
				})
			})
		})
	}
}

function getContactClickableUri(contact) {
	const type = contact.type
	if (type === 'email') return `mailto:${contact.value}`
	if (type === 'phone') return `tel:${contact.value}`
	if (type === 'web') {
		if (contact.value.startsWith('http')) {
			return contact.value
		} else {
			return `http://${contact.value}`
		}
	}
}

function getSocialType(contact) {
	if (contact.value.startsWith('https://www.facebook.com')) {
		return 'facebook'
	}
	if (contact.value.startsWith('https://www.instagram.com')) {
		return 'instagram'
	}
	if (contact.value.startsWith('https://www.twitter.com')) {
		return 'twitter'
	}
	if (contact.value.startsWith('https://twitter.com')) {
		return 'twitter'
	}
	if (contact.value.startsWith('https://www.linkedin.com')) {
		return 'linkedin'
	}
	return null
}

const ContactName = React.memo(({contact}) => {
	const socialType = getSocialType(contact)
	if (socialType === 'facebook') {
		return <>Facebook</>
	}
	if (socialType === 'instagram') {
		return <>Instagram</>
	}
	if (socialType === 'twitter') {
		return <>Twitter</>
	}
	if (socialType === 'linkedin') {
		return <>LinkedIn</>
	}
	if (contact.value.startsWith('https://')) {
		return <>{contact.value.substring('https://'.length).replace(/\/$/, '')}</>
	}
	return <>{contact.value}</>
})

const ContactIcon = React.memo(({contact}) => {
	const socialType = getSocialType(contact)
	if (socialType === 'facebook') {
		return <Facebook/>
	}
	if (socialType === 'instagram') {
		return <Instagram/>
	}
	if (socialType === 'twitter') {
		return <Twitter/>
	}
	if (socialType === 'linkedin') {
		return <LinkedIn/>
	}
	if (contact.type === 'phone') return <IconPhone/>
	if (contact.type === 'web') return <IconWeb/>
	if (contact.type === 'email') return <IconEmail/>
})

const StyledDayListItemIcon = styled(ListItemIcon)`
	align-self: flex-start;
	padding: 4px 0;
`

const StyledDayTypography = styled(Typography)`
	text-transform: capitalize;
`

const OpeningHoursDay = React.memo(({day, allHours}) => (
	<ListItem>
		<StyledDayListItemIcon>
			<StyledDayTypography variant="subtitle2">
				{moment(day, 'e').format('dd')}
			</StyledDayTypography>
		</StyledDayListItemIcon>
		<ListItemText component="div">
			{allHours[day].map((day, index) => (
				<div key={index}>{day.from} - {day.until}</div>
			))}
		</ListItemText>
	</ListItem>
))

const StyledItemImage = styled.img`
	width: 100px;
	height: 100px;
`

const StyledMenuCategoryLink = styled(MaterialLink)`
	display: block;
	padding: 4px 0 4px 8px;
	color: ${props => props.active ? undefined : 'rgba(0, 0, 0, .44)'};
	border-left: 3px solid ${props => props.active ? '#d32f2f' : 'transparent'};
	cursor: pointer;
	text-decoration: none !important;	
	
	${props => !props.active && css`
		&:hover {
			border-left-color: rgba(0, 0, 0, .66);
			color: rgba(0, 0, 0, .66);
		}
	`};
`

const StyledStickyContainer = styled(StickyContainer)`
	padding-top: ${props => props.topgap ? 16 : 0}px;
`

const StyledSticky = styled(Sticky)`
	max-width: 160px;
	min-width: 80px;
`

@withScreen
class Menu extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.stickyTop = [document.getElementById('AppBar')]

		this.state = {
			anchor: null
		}
	}

	render() {
		const menu = this.getMenuStructured(this.props.menu)
		const hasUncategorizedItems = menu.length && menu[0].category.id === 'none'

		return (
			<Flex spacing={2} wrap={false}>
				<FlexItem
					shrink={false}
					hidden={
						this.props.screen.width < 1024
						|| !menu.length
						|| (hasUncategorizedItems && menu.length === 1)
					}
					align="stretch"
				>
					<StyledStickyContainer topgap={+!hasUncategorizedItems}>
						<StyledSticky
							topElements={this.stickyTop}
							top={16}
							bottom={16}
						>
							<Typography variant="body2" component="div">
								{menu.map(category => !category.items.length ? null : (
									<StyledMenuCategoryLink
										active={+(this.state.anchor === category.category.id)}
										key={category.category.id}
										onClick={() => goToAnchor(category.category.id, {
											addPixels: 3
										})}
									>
										{category.category.name || 'Hlavní'}
									</StyledMenuCategoryLink>
								))}
							</Typography>
						</StyledSticky>
					</StyledStickyContainer>
				</FlexItem>
				<FlexItem grow={1}>
					{menu.map(category => !category.items.length ? null : (
						<Box key={category.category.id}>
							<Anchor
								id={category.category.id}
								topElements={this.stickyTop}
								onScrollIn={() => this.changeAnchor(category.category.id)}
								onScrollOut={() => this.changeAnchor(null)}
							>
								{category.category.id !== 'none' && (
									<Box p={2}>
										<CategoryTitle category={category.category}/>
									</Box>
								)}
								<div>
									{category.items.map(menuItem => {
										const price = Number(menuItem.price).toFixed(this.props.hasAnyFractionNumber ? 2 : 0).replace('.', ',')
										return (
											<Accordion
												key={menuItem.id}
												expanded={this.isOnMenu(menuItem, this.props.order)}
											>
												<Padding>
													<ContainerGrid container spacing={2} wrap="nowrap">
														{menuItem.image && (
															<Grid item>
																<StyledItemImage
																	src={`${S3_URI}/menu-item/${menuItem.id}`}
																	alt="Obrázek položky"/>
															</Grid>
														)}
														<FlexItem grow={1} style={{display: 'flex'}}>
															<Grid
																container
																spacing={1}
																direction="column"
																style={{height: 'calc(100% + 8px)'}}
																wrap="nowrap"
																justify="space-between"
															>
																<Grid item>
																	<Grid container direction="row"
																	      justify="space-between">
																		<Grid item>
																			<Typography variant="subtitle1"
																			            component="h3">
																				<strong>{menuItem.name}</strong>
																			</Typography>
																		</Grid>
																		<Grid item style={{
																			display: 'flex',
																			justifyContent: 'flex-end',
																			flexGrow: 1
																		}}>
																			<Typography variant="subtitle1">
																				<strong>{price}&nbsp;Kč</strong>
																			</Typography>
																		</Grid>
																	</Grid>
																</Grid>
																<Grid item style={{flexGrow: 1}}>
																	<ContainerGrid spacing={2} alignItems="flex-end"
																	               style={{height: 'calc(100% + 16px)'}}>
																		<StyleMenuItemBottom item style={{
																			alignSelf: 'flex-start',
																			glexGrow: 99999999999
																		}}>
																			{menuItem.description.split('\n').map((line, index) => (
																				<Typography variant="body2" key={index}>
																					{line}
																				</Typography>
																			))}
																		</StyleMenuItemBottom>
																		<Grid item style={{flexGrow: 1}}>
																			<ContainerGrid spacing={1}
																			               alignItems="center"
																			               justify="flex-end">
																				{!this.isOnMenu(menuItem, this.props.order) && (
																					<Grid item>
																						<Button
																							variant="contained"
																							color="primary"
																							size="medium"
																							onClick={() => this.plus(menuItem)}
																							disabled={!this.props.ordersEnabled}
																						>
																							Objednat
																						</Button>
																					</Grid>
																				)}
																				{this.isOnMenu(menuItem, this.props.order) && (
																					<>
																						<Grid item>
																							<Button
																								size="medium"
																								variant="contained"
																								color="primary"
																								onClick={() => this.minus(menuItem)}
																							>
																								<IconMinus/>
																							</Button>
																						</Grid>
																						<Grid item>
																							<Typography>
																								{this.props.getItemCount(menuItem)}&nbsp;ks
																							</Typography>
																						</Grid>
																						<Grid item>
																							<Button
																								size="medium"
																								variant="contained"
																								color="primary"
																								onClick={() => this.plus(menuItem)}
																							>
																								<IconPlus/>
																							</Button>
																						</Grid>
																					</>
																				)}
																			</ContainerGrid>
																		</Grid>
																	</ContainerGrid>
																</Grid>
															</Grid>
														</FlexItem>
													</ContainerGrid>
												</Padding>
												<Divider/>
											</Accordion>
										)
									})}
								</div>
							</Anchor>
						</Box>
					))}
					{!this.props.menu.length && (
						<Alert severity="warning">
							Prodejce zatím nezveřejnil žádnou nabídku.
						</Alert>
					)}
				</FlexItem>
			</Flex>
		)
	}

	isOnMenu = memo((menuItem, order) => {
		return order.findIndex(x => x.id === menuItem.id) !== -1
	})

	getMenuStructured = memo(menu => getMenuStructured(menu).filter(x => !!x.items.length))

	plus(menuItem) {
		gtag && gtag('event', 'add_to_cart')
		this.props.updateOrder(order => {
			const newOrder = [...order]
			const foundItem = newOrder.find(x => x.id === menuItem.id)

			foundItem
				? foundItem.quantity++
				: newOrder.push({
					...menuItem,
					quantity: 1
				})

			return newOrder
		})
	}

	minus(menuItem) {
		gtag && gtag('event', 'remove_from_cart')
		this.props.updateOrder(order => {
			return order.map(
				x => x.id === menuItem.id ? ({...x, quantity: x.quantity - 1}) : x
			)
			.filter(x => x.quantity > 0)
		})
	}

	changeAnchor(id) {
		this.setState({anchor: id})
	}
}

const CategoryTitle = React.memo(props => (
	<>
		<Typography variant="h6" component="h2">
			{props.category.name}
		</Typography>
		{props.category.description && (
			<Typography variant="body2">
				{props.category.description}
			</Typography>
		)}
	</>
))

const StyledDeliveryForm = styled.form`
	display: flex;
	padding: 16px 0;
	> * {
		margin: 0 auto;
		max-width: 420px;
	}
`

const StyledNoteTypography = styled(Typography)`
	margin-left: 8px;
	position: relative;
	bottom: 1px;
`

const StyledGreenSpan = styled.span`
	color: darkgreen;
`

const StyledNoteTextField = styled(TextField)`
	textarea {
		min-height: 80px;
		resize: vertical;
	}
`

const StyledCustomerInfoTabs = styled(Tabs)`
	button {
		flex-grow: 1;
	}
`

@withSnackbar @withScreen @withUser
class OrderForm extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.state = {
			smsCode: '',
			tooFar: false,
			sendAgainEnabled: false
		}
	}

	render() {
		const parent = this.props.parentState
		const pickupTimesKeys = Object.keys(parent.pickupTimes)
		const pickupDayOptions = pickupTimesKeys.map(x => ({
			value: x.toString(),
			label: dayOffsetLabelMap[x]
		}))

		const disabled = parent.sending
			|| (parent.orderType === 'delivery' && this.state.tooFar)
			|| (parent.customerInfoTab === 1 && !this.props.user.authorized)
			|| (parent.customerInfoTab === 0 && (!parent.name || !parent.phone || !parent.email))

		const onlinePaymentsEnabled = (parent.restaurant.onlinePayments || {})[parent.orderType]
		const onlinePaymentsExclusive = (parent.restaurant.onlinePaymentsExclusive || {})[parent.orderType]

		return (
			<StyledDeliveryForm onSubmit={this.submit}>
				<Grid container spacing={2}>
					{this.props.screen.width < 960 && (
						<Grid item xs={12}>
							<Cart
								summary
								restaurant={this.props.parentState.restaurant}
								order={this.props.order}
								totalItemPrice={this.props.totalItemPrice}
								additionalItems={this.props.additionalItems}
								ordersEnabled={this.props.ordersEnabled}
								belowMinimum={this.props.belowMinimum}
								isPickupEnabled={this.props.isPickupEnabled}
								toggleOrdering={this.props.toggleOrdering}
								ordering={this.props.ordering}
								totalText={this.props.totalText}
							/>
						</Grid>
					)}
					{this.props.parentState.preselectedTableId !== null && (
						<Grid item xs={12}>
							<Alert
								severity="info"
								action={<Button onClick={this.props.onCancelPreselectedTable}>Zrušit</Button>}
								icon={<IconTable/>}
							>
								Objednáváte na stůl <b>{this.props.parentState.restaurant?.eatInTables?.find(t => t.id === this.props.parentState.preselectedTableId)?.name}</b>.
							</Alert>
						</Grid>
					)}
					{this.props.parentState.preselectedTableId === null && (
						<Grid item xs={12}>
							<Typography variant="h6">Převzetí</Typography>
							<RadioGroup
								row
								value={parent.orderType}
								onChange={this.changeOrderType}
							>
								<FormControlLabel
									value="pickup"
									control={<Radio color="secondary"/>}
									label="Vyzvednu si"
									labelPlacement="end"
									disabled={!this.props.pickupEnabled}
								/>
								<Bubble
									text={`Minimální hodnota objednávky je ${parent.restaurant.deliveryMinimumTotal} Kč`}
									disabled={!this.props.deliveryEnabled
										|| !this.props.belowMinimum || !!parent.restaurant.deliverySupplement
									}
								>
									<FormControlLabel
										value="delivery"
										control={<Radio color="secondary"/>}
										label={
											<span>
											Chci dovézt
												{parent.restaurant.deliveryPrice && (
													<StyledNoteTypography variant="caption">
														{this.props.isGratis
															? <><StyledGreenSpan>(zdarma)</StyledGreenSpan></>
															: <>(+{parent.restaurant.deliveryPrice} Kč)</>
														}
													</StyledNoteTypography>
												)}
									</span>
										}
										labelPlacement="end"
										disabled={!this.props.deliveryEnabled || (this.props.belowMinimum && !parent.restaurant.deliverySupplement)}
									/>
								</Bubble>
								{this.props.eatInEnabled && (
									<FormControlLabel
										value="eatIn"
										control={<Radio color="secondary"/>}
										label="Objednat na stůl"
										labelPlacement="end"
										disabled={!this.props.eatInEnabled}
									/>
								)}
							</RadioGroup>
						</Grid>
					)}
					{this.props.parentState.preselectedTableId === null && (
						<Grid item xs={12}>
							{parent.orderType === 'delivery' && (
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Typography variant="h6">Adresa doručení</Typography>
									</Grid>
									<Grid item xs={12}>
										<Paper>
											<Padding>
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<TextField
															name="shipping-address"
															fullWidth
															variant="outlined"
															label="Ulice a číslo popisné"
															autoComplete="street-address"
															placeholder="např. Bratislavská 10"
															value={parent.deliveryStreet}
															onChange={this.changeDeliveryStreet}
															onBlur={this.addressBlur}
															onFocus={this.addressFocus}
														/>
													</Grid>
													<Grid item xs={12} md={4}>
														<TextField
															name="shipping-zip"
															fullWidth
															variant="outlined"
															label="PSČ"
															autoComplete="postal-code"
															placeholder="např. 60200"
															value={parent.deliveryZip}
															onChange={this.changeDeliveryZip}
															onBlur={this.addressBlur}
															onFocus={this.addressFocus}
														/>
													</Grid>
													<Grid item xs={12} md={8}>
														<TextField
															name="shipping-city"
															fullWidth
															variant="outlined"
															label="Město"
															autoComplete="address-level2"
															placeholder="např. Brno"
															value={parent.deliveryCity}
															onChange={this.changeDeliveryCity}
															onBlur={this.addressBlur}
															onFocus={this.addressFocus}
														/>
													</Grid>
												</Grid>
											</Padding>
										</Paper>
									</Grid>
								</Grid>
							)}
							{parent.orderType === 'pickup' && (
								<Flex spacing={2}>
									<FlexItem xs={12}>
										<Typography variant="h6">Čas vyzvednutí</Typography>
									</FlexItem>
									<FlexItem xs={12}>
										<Paper>
											<Flex p={2} spacing={2}>
												<FlexItem grow={1}>
													<Select
														fullWidth
														onChange={this.changePickupDay}
														value={parent.pickupDay}
														options={pickupDayOptions}
														minWidth={0}
														disabled={pickupDayOptions.length === 1}
													/>
												</FlexItem>
												<FlexItem grow={1}>
													<Select
														fullWidth
														onChange={this.changePickupTime}
														value={parent.pickupTime}
														options={parent.pickupTimes[parent.pickupDay]}
														minWidth={0}
													/>
												</FlexItem>
											</Flex>
										</Paper>
									</FlexItem>
								</Flex>
							)}
							{parent.orderType === 'eatIn' && (
								<Flex spacing={2}>
									<FlexItem xs={12}>
										<Typography variant="h6">Výběr stolu</Typography>
									</FlexItem>
									<FlexItem xs={12}>
										<Paper>
											<Flex p={2} spacing={2}>
												<FlexItem xs={12}>
													<Select
														fullWidth
														onChange={this.changeEatInTable}
														value={parent.eatInTable}
														options={parent.restaurant.eatInTables.filter(x => !x.disabled).map(x => ({
															label: x.name,
															value: x.id
														}))}
													/>
												</FlexItem>
											</Flex>
										</Paper>
									</FlexItem>
								</Flex>
							)}
						</Grid>
					)}
					{parent.orderNoteEnabled && (
						<>
							<Grid item xs={12}>
								<Typography variant="h6">
									Poznámka k objednávce
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Paper>
									<Padding>
										<StyledNoteTextField
											multiline fullWidth
											variant="outlined"
											label="Poznámka"
											placeholder="Zadejte volitelnou poznámku..."
											value={parent.note}
											onChange={this.changeNote}
										/>
									</Padding>
								</Paper>
							</Grid>
						</>
					)}
					<Grid item xs={12}>
						<Typography variant="h6">
							Informace o zákazníkovi
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Paper>
							<StyledCustomerInfoTabs value={parent.customerInfoTab} onChange={this.changeCustomerInfoTab}>
								<Tab label="Nový zákazník" disabled={this.props.user.authorized}/>
								<Tab label="Registrovaný uživatel"/>
							</StyledCustomerInfoTabs>
							<Divider/>
							{parent.customerInfoTab === 0 ? (
								<Padding>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<TextField
												name="name"
												fullWidth required
												variant="outlined"
												label="Jméno a příjmení"
												autoComplete="name"
												placeholder="např. Jan Novák"
												value={parent.name}
												onChange={this.changeName}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												name="mobile"
												fullWidth required
												type="tel"
												variant="outlined"
												label="Telefonní číslo"
												placeholder="např. +420777888999"
												autoComplete="tel"
												value={parent.phone}
												onChange={this.changePhone}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth required
												type="email"
												variant="outlined"
												label="E-mailová adresa"
												placeholder="např. jan.novak@email.cz"
												name="email"
												autoComplete="email"
												value={parent.email}
												onChange={this.changeEmail}
											/>
										</Grid>
									</Grid>
								</Padding>
							) : <RegisteredUserTab/>}
						</Paper>
					</Grid>
					{parent.phoneVerificationToken && (
						<Grid item xs={12}>
							<Paper>
								<Padding>
									<TextField
										fullWidth
										variant="outlined"
										label="Potvrzovací kód z SMS"
										placeholder="např. ABC12"
										value={this.state.smsCode}
										onChange={this.changeSmsCode}
										disabled={this.state.smsCode.length >= 5}
										inputRef={ref => this.smsCodeFieldRef = ref}
										InputProps={{
											endAdornment: this.state.smsCode.length >= 5 ? (
												<InputAdornment position="end">
													<CircularProgress color="inherit" size={24}/>
												</InputAdornment>
											) : undefined
										}}
									/>
								</Padding>
								<Divider/>
								<Padding>
									<Grid container spacing={2} justify="flex-end" alignItems="center">
										<Grid item>
											<Typography variant="caption">
												{this.state.sendAgainEnabled
													? 'Kód nepřišel?'
													: 'Nový kód bude možné poslat za 30s.'
												}
											</Typography>
										</Grid>
										<Grid item>
											<Button
												color="secondary"
												onClick={this.sendAgain}
												disabled={!this.state.sendAgainEnabled}
											>
												Poslat znovu
											</Button>
										</Grid>
									</Grid>
								</Padding>
							</Paper>
						</Grid>
					)}
					{onlinePaymentsEnabled && (
						<Grid item xs={12}>
							<Typography variant="h6">Platba</Typography>
							{onlinePaymentsExclusive ? (
								<Typography variant="body1">Ihned (online)</Typography>
							) : (
								<RadioGroup
									row
									value={parent.payment.toString()}
									onChange={this.changePayment}
								>
									<FormControlLabel
										value="true"
										control={<Radio color="secondary"/>}
										label="Ihned (online)"
										labelPlacement="end"
									/>
									<FormControlLabel
										value="false"
										control={<Radio color="secondary"/>}
										label={
											<span>
											Při převzetí
												{!!(parent.restaurant.paymentTypes[parent.orderType] || []).length && (
													<StyledNoteTypography variant="caption">
														({parent.restaurant.paymentTypes[parent.orderType].map(x => paymentTypesMap[x]).join(', ')})
													</StyledNoteTypography>
												)}
										</span>
										}
										labelPlacement="end"
									/>
								</RadioGroup>
							)}
						</Grid>
					)}
					<Grid item xs={12}>
						<Button
							color="primary"
							variant="contained"
							fullWidth
							disabled={disabled}
							type="submit"
						>
							{(parent.sending && !parent.phoneVerificationToken)
								? <CircularProgress size={24}/>
								: 'Odeslat objednávku'
							}
						</Button>
					</Grid>
				</Grid>
			</StyledDeliveryForm>
		)
	}

	componentDidMount() {
		if (this.props.parentState.orderType === 'delivery') {
			this.checkDistance()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.parentState.phoneVerificationToken && this.props.parentState.phoneVerificationToken) {
			this.smsCodeFieldRef.focus()
		}

		if (prevProps.parentState.phoneVerificationToken && !this.props.parentState.phoneVerificationToken) {
			this.setState({smsCode: ''})
		}

		if (prevState.smsCode.length < 5 && this.state.smsCode.length >= 5) {
			this.props.send(null, this.state.smsCode)
		}

		if (this.props.parentState.orderType === 'delivery' && prevProps.parentState.orderType !== 'delivery') {
			this.checkDistance()
		}
	}

	componentWillUnmount() {
		if (this.sendAgainTimer) clearTimeout(this.sendAgainTimer)
	}

	changeOrderType(e) {
		this.props.setParentState({
			orderType: e.target.value,
			payment: !!(this.props.parentState.restaurant.onlinePayments || {})[e.target.value]
		})
	}

	changeDeliveryStreet(e) {
		this.props.setParentState({
			deliveryStreet: e.target.value
		})
		this.setState({tooFar: false})
	}

	changeDeliveryCity(e) {
		this.props.setParentState({
			deliveryCity: e.target.value
		})
		this.setState({tooFar: false})
	}

	changeDeliveryZip(e) {
		this.props.setParentState({
			deliveryZip: e.target.value
		})
		this.setState({tooFar: false})
	}

	addressFocus() {
		this.addressFocused = true
	}

	addressBlur() {
		this.addressFocused = false
		setTimeout(() => {
			if (!this.addressFocused) {
				this.checkDistance()
			}
		}, 0)
	}

	checkDistance() {
		const parent = this.props.parentState
		if (!parent.deliveryStreet || !parent.deliveryCity || !parent.deliveryZip) {
			return
		}

		if (
			this.cachedDistance
			&& this.cachedDistance.street === parent.deliveryStreet
			&& this.cachedDistance.city === parent.deliveryCity
			&& this.cachedDistance.zip === parent.deliveryZip
		) {
			this.processDistanceResult(...this.cachedDistance.result)
			return
		}

		const restaurantLat = parseFloat(parent.restaurant.latitude)
		const restaurantLng = parseFloat(parent.restaurant.longitude)

		if (!restaurantLat || !restaurantLng) {
			return
		}

		geolocator.geocode({
			address: `${parent.deliveryStreet}, ${parent.deliveryCity}`,
			postalCode: parent.deliveryZip,
			region: 'cz'
		}, (err, location) => {
			if (err) {
				console.log(err)
				return
			}
			if (!location.coords) return

			geolocator.getDistanceMatrix({
				unitSystem: geolocator.UnitSystem.METRIC,
				origins: {
					latitude: restaurantLat,
					longitude: restaurantLng
				},
				destinations: {
					latitude: parseFloat(location.coords.latitude),
					longitude: parseFloat(location.coords.longitude)
				},
				region: 'cz'
			}, (err, result) => {
				this.cachedDistance = {
					street: parent.deliveryStreet,
					city: parent.deliveryCity,
					zip: parent.deliveryZip,
					result: [err, result]
				}

				this.processDistanceResult(err, result)
			})
		})
	}

	processDistanceResult(err, result) {
		if (err) {
			console.log(err)
			return
		}
		if (!result.length || !result[0].distance) return

		const parent = this.props.parentState
		const maxDistance = parseFloat(parent.restaurant.deliveryDistanceKm)
		if (!maxDistance) return

		if ((result[0].distance.value / 1000) > maxDistance) {
			this.setState({tooFar: true})
			this.props.enqueueSnackbar(
				'Je nám líto, ale provozovna tuto objednávku nemůže dopravit. Jste moc daleko 😞.',
				{variant: 'error', preventDuplicate: true}
			)
		}
	}

	changePickupDay(e) {
		this.props.setParentState({
			pickupTime: this.props.parentState.pickupTimes[e.target.value][0].value,
			pickupDay: e.target.value
		})
	}

	changePickupTime(e) {
		this.props.setParentState({
			pickupTime: e.target.value,
			pickupTimeAdjusted: false
		})
	}

	changeEatInTable(e) {
		this.props.setParentState({
			eatInTable: e.target.value
		})
	}

	changeNote(e) {
		this.props.setParentState({
			note: e.target.value
		})
	}

	changePayment(e) {
		this.props.setParentState({
			payment: e.target.value === 'true'
		})
	}

	changeName(e) {
		this.props.setParentState({
			name: e.target.value
		})
	}

	changeCustomerInfoTab(e, val) {
		this.props.setParentState({
			customerInfoTab: val
		})
	}

	changePhone(e) {
		this.props.setParentState({
			phone: e.target.value
		})
	}

	changeEmail(e) {
		this.props.setParentState({
			email: e.target.value
		})
	}

	changeSmsCode(e) {
		this.setState({
			smsCode: e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '')
		})
	}

	submit(e) {
		this.props.send(e)
		this.setState({sendAgainEnabled: false}, () => {
			this.sendAgainTimer = setTimeout(() => this.setState({sendAgainEnabled: true}), 30000)
		})
	}

	sendAgainTimer = null

	sendAgain(e) {
		this.props.send(e)
		this.setState({sendAgainEnabled: false}, () => {
			this.sendAgainTimer = setTimeout(() => this.setState({sendAgainEnabled: true}), 30000)
		})
	}
}

const StyledCenteredGrid = styled(Grid)`
	align-self: center;
`

@withUser
class RegisteredUserTab extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)
	}

	render() {
		if (!this.props.user.authorized) return (
			<Padding>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<SignInButton/>
					</Grid>
					<Grid item xs={12}>
						<FacebookButton/>
					</Grid>
				</Grid>
			</Padding>
		)

		return (
			<>
				<List>
					<ListItem>
						<ListItemText
							primary={this.props.user.name || '-'}
							secondary="Jméno a příjmení"
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={this.props.user.phone || '-'}
							secondary="Telefonní číslo"
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={this.props.user.email || '-'}
							secondary="E-mailová adresa"
						/>
					</ListItem>
				</List>
				<Divider/>
				<Padding>
					<Grid container spacing={2} justify="flex-end" alignContent="center">
						<StyledCenteredGrid item>
							<Typography variant="caption">
								Nejste to vy?
							</Typography>
						</StyledCenteredGrid>
						<Grid item>
							<Button onClick={this.props.user.logout}>
								Odhlásit se
							</Button>
						</Grid>
					</Grid>
				</Padding>
			</>
		)
	}
}

const dayOffsetLabelMap = {
	0: 'Dnes',
	1: 'Zítra',
	2: getDayLabel(2),
	3: getDayLabel(3),
	4: getDayLabel(4),
	5: getDayLabel(5),
	6: getDayLabel(6)
}

function getDayLabel(dayOffset) {
	return moment().startOf('day').add(dayOffset, 'days').format('ddd D. M.')
}

const getPickupTimes = memo((pickupAdvanceSupported, pickupAdvance, hours, minimumMinutesToPrepare = 30, currentMinute) => {
	const pickupTimes = {}
	const weekday = moment().weekday()

	let [_, extraNextDay] = extractNextDay(hours[(weekday + 6) % 7] || [])
	for (let dayOffset = 0; dayOffset < pickupAdvance + 1; dayOffset++) {
		const todayHours = dayOffset < 6 ? extraNextDay : []
		let today
		[today, extraNextDay] = extractNextDay(hours[(weekday + dayOffset) % 7] || [])
		todayHours.push(...today)

		for (
			let time = moment().add(dayOffset, 'day').startOf('day')
			; time.isBefore(moment().add(dayOffset, 'day').endOf('day').add(30, 'minute'))
			; time.add(30, 'minute')
		) {
			let lastIteration = false
			if (time.isSameOrAfter(moment().add(dayOffset, 'day').endOf('day'))) {
				if (dayOffset !== 0) break

				lastIteration = true
				time = moment().add(minimumMinutesToPrepare, 'minute')
			}

			if (dayOffset === 0 && moment().add(minimumMinutesToPrepare, 'minute').isAfter(time)) continue
			if (matchTime(time, todayHours, dayOffset, minimumMinutesToPrepare)) {
				pickupTimes[dayOffset] || (pickupTimes[dayOffset] = [])
				pickupTimes[dayOffset][lastIteration ? 'unshift' : 'push']({
					label: time.format('HH:mm'),
					value: time.format('x'),
					moment: time.clone(),
					...lastIteration && {
						earliest: true,
						label: `Co nejdříve (${minimumMinutesToPrepare}min)` //`Co nejdřív (${time.format('HH:mm')})`
					}
				})
			}

			if (lastIteration) break
		}
	}

	// Remove when the majority of users have newer app version
	if (!pickupAdvanceSupported) {
		for (const key in pickupTimes) {
			if (!['0', '1'].includes(key)) {
				delete pickupTimes[key]
			}
		}

		(function() {
			if (!pickupTimes[1]) return
			pickupTimes[1] = pickupTimes[1].map(time => {
				const max = moment().add(24, 'hour')

				if (time.moment.isAfter(max)) {
					return null
				}

				return time
			}).filter(Boolean)
		})()
	}

	return pickupTimes
})

function matchTime(time, hours, dayOffset) {
	for (const range of hours) {
		const from = getMomentFromRangeString(range.from, dayOffset)
		const until = getMomentFromRangeString(range.until, dayOffset)
		if (time.isAfter(until)) continue
		if (time.isBefore(from)) continue
		return true
	}

	return false
}

function getMomentFromRangeString(time, dayOffset = 0) {
	const parts = time.split(':')
	return moment().startOf('day').add({days: dayOffset, hours: parts[0], minutes: parts[1]})
}

function extractNextDay(hours) {
	let thisDay = []
	let nextDay = []
	for (const range of hours) {
		const from = getMomentFromRangeString(range.from)
		const until = getMomentFromRangeString(range.until)

		if (until.isBefore(from)) {
			thisDay.push({from: from.format('HH:mm'), until: '23:59'})
			nextDay.push({from: '00:00', until: until.format('HH:mm')})
			continue
		}

		thisDay.push({...range})
	}

	return [thisDay, nextDay]
}

const StyledMobileBar = styled.div`
	padding-top: 4px;
	padding-bottom: 4px;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 104px;
	background-color: #fff;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, .32);
	z-index: 110;
	display: flex;
	align-items: center;
	
	.btn {
		width: 100%;
	}
`

const MobileBar = React.memo(props => (
	<StyledMobileBar>
		<CompactCart
			total={props.total}
			toggleOrdering={props.toggleOrdering}
		/>
	</StyledMobileBar>
))

class CompactCart extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)
	}

	render() {
		return (
			<Flex spacing={1} p={2} edge="y" align="center">
				<FlexItem xs={12}>
					<Flex justify="space-between" wrap={false} align="center">
						<FlexItem>
							<Typography variant="h6">Celkem</Typography>
						</FlexItem>
						<FlexItem>
							<Typography variant="h6">{this.props.total}</Typography>
						</FlexItem>
					</Flex>
				</FlexItem>
				<FlexItem xs={12}>
					<Fab
						color="primary"
						variant="extended"
						onClick={this.props.toggleOrdering}
						size="medium"
						className="btn"
					>
						Vytvořit objednávku
					</Fab>
				</FlexItem>
			</Flex>
		)
	}
}

@withScreen
class Cart extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)
	}

	render() {
		return (
			<Paper>
				<Padding>
					<StyledMyOrderPaper elevation={0} summary={+(this.props.summary || 0)}>
						<Padding edge="top" vertical>
							<Grid container spacing={1} alignItems="center">
								<Grid item style={{flexGrow: 1}}>
									<Typography variant="h6">
										Moje objednávka
									</Typography>
								</Grid>
								{this.props.restaurant.pickup && (
									<Grid item>
										<Chip
											size="small"
											label={this.props.screen.width < 1024
												? undefined
												: 'Vyzvednutí'
											}
											icon={<IconPickup/>}
											style={{backgroundColor: 'transparent'}}
										/>
									</Grid>
								)}
								{this.props.restaurant.delivery && (
									<Grid item>
										<Chip
											size="small"
											label={this.props.screen.width < 1024
												? undefined
												: 'Dovoz'
											}
											icon={<IconDelivery/>}
											style={{backgroundColor: 'transparent'}}
										/>
									</Grid>
								)}
							</Grid>
						</Padding>
						{!this.props.order.length && (
							<StyledEmptyCartNote>
								<Typography variant="button">
									Objednávka je prázdná
								</Typography>
							</StyledEmptyCartNote>
						)}
						<Grid container spacing={1}>
							{this.props.order.map(item => (
								<Grid item key={item.id} xs={12}>
									<Grid container direction="row"
									      justify="space-between">
										<Grid item xs={9}>
											<Grid container direction="row">
												<Grid item xs={2}>
													<Typography
														variant="body1">{item.quantity}&nbsp;x</Typography>
												</Grid>
												<Grid item xs={8}>
													<Typography
														variant="body1">{item.name}</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item>
											<Typography variant="body1">
												{this.props.totalItemPrice(item).replace('.', ',')}&nbsp;Kč
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							))}
						</Grid>
					</StyledMyOrderPaper>
				</Padding>
				<Padding edge="top">
					{!!this.props.order.length && (
						<>
							{!!this.props.additionalItems.length && (
								<><Divider/>
									<Padding vertical>
										{this.props.additionalItems.map(item => (
											<Grid item key={item.name}>
												<Grid container
												      justify="space-between">
													<Typography variant="caption">
														{item.name}
													</Typography>
													<Typography variant="caption">
														{item.price.replace('.', ',')}&nbsp;Kč
													</Typography>
												</Grid>
											</Grid>
										))}
									</Padding>
								</>
							)}
							<Divider/>
							<Padding vertical edge={this.props.summary ? 'bottom' : undefined}>
								<Grid item>
									<Grid container justify="space-between">
										<Typography variant="h6">Celkem</Typography>
										<Typography
											variant="h6">{this.props.totalText}</Typography>
									</Grid>
								</Grid>
							</Padding>
						</>
					)}
					{!this.props.summary && (
						<>
							<Bubble
								text={`Minimální hodnota objednávky je ${this.props.restaurant.deliveryMinimumTotal} Kč`}
								disabled={
									!this.props.order.length || !this.props.ordersEnabled
									|| !this.props.belowMinimum || this.props.restaurant.deliverySupplement || this.props.isPickupEnabled
								}
							>
								<StyledCartConfirmationFab
									variant="extended"
									color="primary"
									size="large"
									disabled={
										!this.props.order.length || !this.props.ordersEnabled
										|| !!(this.props.belowMinimum && !this.props.restaurant.deliverySupplement && !this.props.isPickupEnabled)
									}
									onClick={this.props.toggleOrdering}
								>
									{this.props.ordering
										? 'Změnit objednávku'
										: 'Vytvořit objednávku'
									}
								</StyledCartConfirmationFab>
							</Bubble>
						</>
					)}
				</Padding>
			</Paper>
		)
	}
}

function menuVisibilityFilter(menu) {
	const result = []

	for (let i = 0; i < menu.length; i++) {
		const item = menu[i]

		if (item.special !== 'category' && item.visible === false) {
			continue
		}

		result.push(item)
	}

	return result
}