import Typography from '@material-ui/core/Typography'

const StyledNotFoundContainer = styled.div`
	display: flex;
	flex-grow: 1;
	
	> * {
		margin: auto;
	}
`

export default React.memo(() => (
	<StyledNotFoundContainer>
		<Typography variant="h6">Nenalezeno</Typography>
	</StyledNotFoundContainer>
))