import memo from 'memoize-one'

export const getMenuStructured = memo(menuFlat => {
	let currentItemsArray = []
	const struct = [{category: {id: 'none'}, items: currentItemsArray}]

	for (const item of menuFlat) {
		if (item.special === 'category') {
			currentItemsArray = []
			struct.push({
				category: item,
				items: currentItemsArray
			})
			continue
		}

		currentItemsArray.push(item)
	}

	return struct
})

export const getMenuFlat = memo(menuStructured => {
	const result = []

	for (const category of menuStructured) {
		if (category.category.id !== 'none') {
			result.push(category.category)
		}

		result.push(...category.items)
	}

	return result
})