import {LayoutZone, Padding} from '../../../style/lib'
import {Divider, Fab, Grid, Link as MaterialLink, Paper, Typography} from '@material-ui/core'
import {withSnackbar} from 'notistack'
import {Link} from '@reach/router'
import {MetaTags} from 'react-meta-tags'

const StyledBanner = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  max-width: ${props => props.maxWidth};
  max-height: ${props => props.maxHeight};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  background-image: url('${props => props.url}');
`

const StyledImgWrapper = styled.div`
  text-align: center;
  
  > img {
    max-width: 100%;
  }
`

const StyledParagraph = styled(Typography)`
  margin-top: 0.5rem;
  margin-bottom: 1.1rem;
`
const StyledH1 = styled(Typography)`
  margin-top: 1.1rem;
`

const StyledH2 = styled(Typography)`
  margin-top: 2.8rem;
`

const GuideImage = styled.img`
  display: block;
  margin: 16px auto auto;
`


@withAPI @withSnackbar @withScreen
export default class About extends React.PureComponent {

	render() {
		const title = 'O nás'
		return (
			<>
				<MetaTags>
					<title>{title}</title>
					<meta name="description" content={`O vyzvednisi.cz`}/>
				</MetaTags>
				<LayoutZone>
					<StyledBanner
						className="image"
						url="/asset/static/about/about-header.jpg"
						width="100%"
						height={`${this.props.screen.width < 700 ? 320 : 557}px`}
					/>
				</LayoutZone>
				<LayoutZone verticalPadding>
					<Paper>
						<Padding size={16}>
							<StyledParagraph variant="body1">
								<b>
									<MaterialLink component={Link} to="/">Vyzvednisi.cz</MaterialLink> je
									bezplatný objednávkový systém pro gastropodniky a maloobchod.
								</b>{' '}
								Když kvůli karanténě zavřela většina restaurací a obchodů, chtěli jsme vytvořit
								nástroj, který by i těm nejmenším umožnil zachovat aspoň omezený provoz. Naši
								vývojáři tehdy zasedli k počítačům a začali programovat. Výsledek se dostavil po
								14 dnech (a nocích) jejich intenzivní práce: Představili jsme web, kde podniky i
								jednotlivci mohou nabídnout cokoliv „s sebou“ (jídlo i jiné zboží) a lidé z
								jejich okolí si to můžou jednoduše objednat a vyzvednout. Registrace i
								objednávky jsou otázkou několika kliknutí, systém funguje po celé republice a je
								to úplně zdarma.
							</StyledParagraph>
							<StyledImgWrapper>
								<img
									src="/asset/static/about/about-mid.jpg"
									alt="mid-banner"
								/>
							</StyledImgWrapper>
							<StyledParagraph variant="body1">
								Nakonec se z Vyzvednisi.cz stal plnohodnotný objednávkový systém, který jsme
								integrovali i do pokladního systému <MaterialLink
									href="https://www.dotykacka.cz/" target="_blank">Dotykačka</MaterialLink>.
								K webové verzi přibyla i <MaterialLink
									href="https://play.google.com/store/apps/details?id=com.dotypos.orders.app"
									target="_blank">mobilní aplikace</MaterialLink>.
								Posledním krokem, kterým jsme usnadnili práci podnikům a objednání
								zákazníkům je <b>online platební brána</b>.
							</StyledParagraph>
						</Padding>
						{window.isSignUpAvailable &&
							<>
								<Divider/>
								<Padding size={16}>
									<Grid container justify="center" direction="column">
										<Grid item xs={12}>
											<Grid container direction="row" justify="space-between" it>
												<Grid item md={3} sm={6} xs={12}>
													<GuideImage src="/asset/static/about/about-register.png"
													            alt="Zaregistrujte svůj podnik"/>
												</Grid>
												<Grid item md={3} sm={6} xs={12}>
													<GuideImage src="/asset/static/about/about-add-items.png"
													            alt="Zadejte všechny prodejní položky"/>
												</Grid>
												<Grid item md={3} sm={6} xs={12}>
													<GuideImage src="/asset/static/about/about-open.png"
													            alt="Nastavte otevírací dobu a adresu"/>
												</Grid>
												<Grid item md={3} sm={6} xs={12}>
													<GuideImage src="/asset/static/about/about-orders.png"
													            alt="Příjmejte objednávky"/>
												</Grid>
											</Grid>
										</Grid>
										<Grid item>
											<StyledH2 variant="h6" component="h2">
												Jak se přidat?
											</StyledH2>
											<StyledParagraph variant="body1">
												Jednoduše se zaregistrujte na webu, vyplňte údaje, <MaterialLink
												href={PLAY_STORE_URI}>stáhněte si aplikaci do
												telefonu nebo tabletu</MaterialLink> (s operačním systémem Android) a
												začněte
												přijímat
												objednávky.
												Kdyby cokoliv, systém je napojený na naší zákaznickou podporu a ty vám
												rádi
												poradí. Tak jdeme na to!
											</StyledParagraph>
										</Grid>
										<Grid item xs={12}>
											<Grid container justify="center">
												<Grid item>
													<Fab
														size="large"
														color="primary"
														variant="extended"
														component={Link}
														to="/auth/signup">
														Přidejte se
													</Fab>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Padding>
							</>
						}
					</Paper>
				</LayoutZone>
			</>
		)
	}
}

class LinkHome extends React.PureComponent {

	render() {
		return <MaterialLink component={Link} to="/">www.vyzvednisi.cz</MaterialLink>
	}
}
