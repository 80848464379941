import Grid from '@material-ui/core/Grid'
import styled, {css} from 'styled-components'

const StyledContainerGrid = styled(Grid)`
	width: calc(100% + ${props => props.spacing * 8 + props.safarifix}px);
	height: calc(100% + ${props => props.spacing * 8}px);
`

const StyledWrapper = styled.div`
	width: 100%;
	overflow: {props => props.overflow ? props.overflow : (props.p ? 'hidden' : 'visible')};
	margin${props => props.indent ? `-${props.indent}` : ''}: ${props => props.m * 8}px;
	padding${props => props.indent ? `-${props.indent}` : ''}: ${props => props.p * 8}px;
	
	${props => props.edge && css`
		margin-${props.edge}: 0;
		padding-${props.edge}: 0;
	`};
	${props => props.edge === 'y' && css`
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
	`};
	${props => props.edge === 'x' && css`
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	`};
`

export const Flex = React.memo(props => {
	const proxiedProps = {...props}
	delete proxiedProps.p
	delete proxiedProps.m
	delete proxiedProps.edge
	delete proxiedProps.indent
	delete proxiedProps.className
	delete proxiedProps.wrap
	delete proxiedProps.safariFix
	delete proxiedProps.overflow
	delete proxiedProps.justify
	delete proxiedProps.align

	return (
		<StyledWrapper
			p={props.p}
			m={props.m}
			edge={props.edge}
			indent={props.indent}
			className={props.className}
		>
			<StyledContainerGrid
				{...proxiedProps}
				container
				spacing={props.spacing}
				justify={alignmentMap[props.justify]}
				alignItems={alignmentMap[props.align]}
				direction={props.direction}
				safarifix={+props.safariFix}
				wrap={wrapMap[props.wrap]}
				component={props.component}
			>
				{props.children}
			</StyledContainerGrid>
		</StyledWrapper>
	)
})

Flex.propTypes = {
	spacing: PropTypes.number,
	justify: PropTypes.oneOf(['start', 'end', 'center', 'space-between', 'space-around']),
	align: PropTypes.oneOf(['start', 'end', 'center', 'stretch']),
	p: PropTypes.number,
	m: PropTypes.number,
	edge: PropTypes.oneOf(['top', 'left', 'right', 'bottom', 'x', 'y']),
	indent: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),
	direction: PropTypes.oneOf(['row', 'column']),
	wrap: PropTypes.oneOf([true, false, 'reverse']),
	safariFix: PropTypes.bool,
	overflow: PropTypes.oneOf(['hidden', 'visible', 'auto']),
	component: PropTypes.elementType
}

Flex.defaultProps = {
	spacing: 0,
	justify: 'start',
	align: 'start',
	p: 0,
	m: 0,
	direction: 'row',
	wrap: true,
	safariFix: false
}

const alignmentMap = {
	start: 'flex-start',
	end: 'flex-end',
	center: 'center',
	'space-between': 'space-between',
	'space-around': 'space-around',
	stretch: 'stretch'
}

const wrapMap = {
	[true]: 'wrap',
	[false]: 'nowrap',
	'reverse': 'reverse'
}

const StyledItemGrid = styled(Grid)`
	flex-grow: ${props => props.grow};
	flex-shrink: ${props => +props.shrink};
	${props => props.align && css`align-self: ${alignmentMap[props.align]}`};
	${props => props.width && css`width: ${props.width}px`};
	${props => props.height && css`height: ${props.height}px`};
	${props => props.maxwidth && css`max-width: ${props.maxwidth}px`};
	${props => props.maxheight && css`max-height: ${props.maxheight}px`};
`

export const FlexItem = React.forwardRef((props, ref) => {
	if (props.hidden) return null

	return (
		<StyledItemGrid
			item
			grow={props.grow}
			shrink={+props.shrink}
			align={props.align}
			width={props.width}
			maxwidth={props.maxWidth}
			height={props.height}
			maxheight={props.maxHeight}
			xs={props.xs}
			sm={props.sm}
			md={props.md}
			lg={props.lg}
			xl={props.xl}
			className={props.className}
			ref={ref}
		>
			{props.children}
		</StyledItemGrid>
	)
})

FlexItem.propTypes = {
	grow: PropTypes.number,
	shrink: PropTypes.bool,
	align: PropTypes.oneOf(['start', 'end', 'center', 'stretch']),
	hidden: PropTypes.bool,
	width: PropTypes.number,
	maxWidth: PropTypes.number,
	height: PropTypes.number,
	maxHeight: PropTypes.number,
	xs: PropTypes.number,
	sm: PropTypes.number,
	md: PropTypes.number,
	lg: PropTypes.number,
	xl: PropTypes.number
}

FlexItem.defaultProps = {
	grow: 0,
	shrink: true,
	hidden: false
}