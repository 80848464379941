import {Fab, Grid, Typography} from '@material-ui/core'
import {LayoutZone, Padding} from '../../style/lib'
import RestaurantGrid from '../RestaurantGrid'
import SkeletonGrid from '../SkeletonGrid'
import geolocator from 'geolocator'
import Map from '../Map'
import {Link} from '@reach/router'
import {RestaurantCardCompact} from '../RestaurantCardCompact'
import {withLocation} from '../../module/withLocation'
import {withSnackbar} from 'notistack'
import Alert from '@material-ui/lab/Alert'
import {MetaTags} from 'react-meta-tags'
import {RecommendSellerBanner} from '../RecommendSellerBanner'
import MapCover from '../MapCover'

const StyledRedSpan = styled.span`
	color: #d32f2f;
`

const StyledSingleRestaurantCardCompact = styled(RestaurantCardCompact)`
	max-width: 300px;
	flex-basis: initial;
`

@withLocation @withAPI @withSnackbar
export default class Nearby extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.state = {
			allRestaurants: null,
			nearbyRestaurants: null,
			latitude: null,
			longitude: null,
			mapHidden: true
		}
	}

	render() {
		let address = decodeURIComponent(this.props.address)
		if (!address || address === 'undefined') {
			if (this.props.location.street) {
				address = `${this.props.location.street}, ${this.props.location.city}`
			} else {
				address = this.props.location.city
			}
		}
		const userMapCenter = (this.props.location.latitude && this.props.location.longitude)
			? {latitude: this.props.location.latitude, longitude: this.props.location.longitude}
			: undefined

		const mapCenter = (this.state.latitude && this.state.longitude)
			? {latitude: this.state.latitude, longitude: this.state.longitude}
			: userMapCenter

		const markers = (this.state.allRestaurants || [])
		.map(restaurant => ({
			latitude: restaurant.latitude,
			longitude: restaurant.longitude,
			card: (
				<Grid container>
					<StyledSingleRestaurantCardCompact restaurant={restaurant}/>
				</Grid>
			)
		}))

		const title = `Nabídka v okolí ${address} | ${window.WebName}`
		return (
			<>
				<MetaTags>
					<title>{title}</title>
					<meta name="description" content={`Nabídky restaurací a prodjeců v okolí ${address}`}/>
				</MetaTags>
				<LayoutZone verticalPadding>
					<Padding vertical size={32}>
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<Typography variant="h5">
									Nabídky v okolí
									{' '}
									{address && (
										<StyledRedSpan>
											{address}
										</StyledRedSpan>
									)}
								</Typography>
							</Grid>
							<Grid item xs={12} style={{maxWidth: '100%'}}>
								{this.state.nearbyRestaurants
									? <RestaurantGrid
										restaurants={this.state.nearbyRestaurants.slice(0, 9)}
										alive={this.props.alive}
									/>
									: this.state.nearbyRestaurants === null ? <SkeletonGrid items={24}/> : (
										<Alert severity="warning">
											Z vyhledávaného dotazu nebylo možné rozpoznat polohu.
										</Alert>
									)
								}
								{this.state.nearbyRestaurants && !this.state.nearbyRestaurants.length && (
									<Alert severity="warning">
										V okolí zadané lokality jsme nenašli žádnou nabídku.
									</Alert>
								)}
							</Grid>
							<Grid item xs={12}>
								{this.state.mapHidden ? (
									<MapCover onShow={this.showMap}/>
								) : (
									<Map
										key={JSON.stringify(mapCenter)}
										center={mapCenter}
										zoom={mapCenter ? 12 : undefined}
										markers={markers}
									/>
								)}
							</Grid>
							<Grid item xs={12} style={{maxWidth: '100%'}}>
								{this.state.nearbyRestaurants && (
									<RestaurantGrid
										restaurants={this.state.nearbyRestaurants.slice(9, 18)}
										alive={this.props.alive}
									/>
								)}
							</Grid>
							<Grid item xs={12}>
								<RecommendSellerBanner/>
							</Grid>
							<Grid item xs={12} style={{maxWidth: '100%'}}>
								{this.state.nearbyRestaurants && (
									<RestaurantGrid
										restaurants={this.state.nearbyRestaurants.slice(18)}
										alive={this.props.alive}
									/>
								)}
							</Grid>
							<Grid container justify="center">
								<Grid item>
									<Padding>
										<Fab
											size="large"
											color="primary"
											variant="extended"
											component={Link}
											to="/browse">
											Všichni prodejci
										</Fab>
									</Padding>
								</Grid>
							</Grid>
						</Grid>
					</Padding>
				</LayoutZone>
			</>
		)
	}

	componentDidMount() {
		this.decodeAddress();
		this.fetchRestaurants(this.props.location.latitude, this.props.location.longitude)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.address !== this.props.address) {
			this.decodeAddress()
		}
		if (!this.props.address && prevProps.location.latitude !== this.props.location.latitude) {
			this.fetchRestaurants(this.props.location.latitude, this.props.location.longitude)
		}
	}

	decodeAddress() {
		if (!this.props.address) {
			return;
		}

		geolocator.geocode({
			address: decodeURIComponent(this.props.address)
		}, (err, location) => {
			const latitude = ((location || {}).coords || {}).latitude
			const longitude = ((location || {}).coords || {}).longitude
			this.fetchRestaurants(latitude, longitude)
		})
	}

	fetchRestaurants(latitude, longitude) {
		Promise.all([
			(latitude && longitude)
				? this.props.API.get(`/nearby?lat=${latitude}&lon=${longitude}`)
				: Promise.resolve(false),
			this.props.API.get('/default.json', {S3: true})
		])
		.then(([result, allRestaurants]) => {
			this.setState({
				allRestaurants: allRestaurants.restaurants.filter(x => x.disabled !== true),
				nearbyRestaurants: result
					? (result.restaurants || []).filter(x => parseFloat(x.latitude) && parseFloat(x.longitude))
					: false,
				latitude: latitude,
				longitude: longitude
			})
		})
		.catch(error => {
			console.log(error)
			this.props.enqueueSnackbar(
				'Nepodařilo se načíst seznam restaurací. Zkuste to prosím později.',
				{variant: 'error'}
			)
		})
	}

	showMap() {
		this.setState({
			mapHidden: false
		})
	}
}