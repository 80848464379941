import {photoUploaderContext} from '../../context/photoUploader'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import {withSnackbar} from 'notistack'
import {
	Slide, Dialog, AppBar, Grid, Typography, Button, MuiThemeProvider,
	IconButton
} from '@material-ui/core'
import {ContainerGrid, Padding} from '../../style/lib'
import memo from 'memoize-one'
import {createMuiTheme} from '@material-ui/core/styles'
import PageLoader from '../PageLoader'
import IconCancel from 'mdi-material-ui/Close'
import IconSave from 'mdi-material-ui/Check'

const allowedExtensions = ['jpg', 'jpeg', 'png']

const StyledForm = styled.form`
	display: none;
`

const StyledCropContainer = styled.div`
	flex-grow: 1;
	display: flex;
	padding: 16px;
	background-color: #f0f0f0;
	> * {margin: auto}
`

const StyledAppBar = styled(AppBar)`
	max-height: 68px;
`

@withSnackbar @withScreen
export default class PhotoUploaderProvider extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.state = this.getStateTemplate()
	}

	render() {
		return (
			<>
				<photoUploaderContext.Provider value={this.upload}>
					{this.props.children}
				</photoUploaderContext.Provider>
				<StyledForm ref={ref => this.formRef = ref}>
					<input
						type="file"
						ref={ref => this.fileInputRef = ref}
					/>
				</StyledForm>
				<Dialog
					fullScreen
					open={this.state.dialogOpen}
					onClose={this.closeDialog}
					TransitionComponent={Transition}
				>
					<StyledAppBar position="relative">
						<MuiThemeProvider theme={this.getHeadTheme}>
							<Padding>
								<ContainerGrid spacing={2} justify="space-between" wrap="nowrap" alignItems="center">
									<Grid item>
										<Typography variant="h6" noWrap>Upravit obrázek</Typography>
									</Grid>
									<Grid item>
										<ContainerGrid spacing={1} wrap="nowrap" alignItems="center">
											<Grid item>
												{this.props.screen.width < 375 ? (
													<IconButton size="small" onClick={this.closeDialog}>
														<IconCancel/>
													</IconButton>
												) : (
													<Button onClick={this.closeDialog}>
														Zrušit
													</Button>
												)}
											</Grid>
											<Grid item>
												{this.props.screen.width < 375 ? (
													<IconButton size="small" onClick={this.save}>
														<IconSave/>
													</IconButton>
												) : (
													<Button onClick={this.save}>
														Uložit
													</Button>
												)}
											</Grid>
										</ContainerGrid>
									</Grid>
								</ContainerGrid>
							</Padding>
						</MuiThemeProvider>
					</StyledAppBar>
					<StyledCropContainer>
						{this.state.file ? (
							<ReactCrop
								src={this.state.file}
								crop={this.state.crop}
								onChange={this.changeCrop}
								minWidth={200}
								minHeight={200}
								keepSelection ruleOfThirds
								onImageLoaded={this.imageLoaded}
								imageStyle={{maxHeight: Math.max(this.props.screen.height - 100, 200)}}
							/>
						) : <PageLoader/>}
					</StyledCropContainer>
				</Dialog>
			</>
		)
	}

	getStateTemplate() {
		return {
			dialogOpen: false,
			file: null,
			crop: null,
			resolve: null,
			reject: null,
			processing: false
		}
	}

	upload() {
		if (this.state.resolve) return Promise.reject('BUSY')
		return new Promise((resolve, reject) => {
			this.fileInputRef.click()

			const self = this
			function onChange(e) {
				self.fileInputRef.removeEventListener('change', onChange)

				const parts = e.target.value.split('.')
				if (!allowedExtensions.includes(parts[parts.length - 1].toLowerCase())) {
					return reject('INVALID_EXTENSION')
				}

				self.setState({resolve, reject}, () => {
					self.fileSelected(e)
				})
			}

			this.fileInputRef.addEventListener('change', onChange)
		})
	}

	closeDialog() {
		this.closeWithError('CANCELED')
	}

	closeWithError(error) {
		this.imageRef = null
		this.formRef.reset()
		const reject = this.state.reject
		this.setState(this.getStateTemplate(), () => {
			reject(error)
		})
	}

	fileSelected(e) {
		const file = e.target.files[0]

		// Limit size to 35MB
		if (file.size > 36700160) {
			return this.closeWithError('SIZE')
		}

		this.setState({dialogOpen: true}, () => {
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				this.setState({
					file: reader.result,
					crop: {aspect: 1, width: 200, height: 200}})
			})
			reader.readAsDataURL(file)
		})
	}

	imageLoaded(image) {
		if (image.width < 200 || image.height < 200) {
			this.closeDialog()
			return this.props.enqueueSnackbar(
				'Vvyberte prosím obrázek o minimální velikosti 200x200 pixelů.',
				{variant: 'error'}
			)
		}

		this.imageRef = image
	}

	changeCrop(crop) {
		this.setState({crop})
	}

	save() {
		this.setState({processing: true}, () => {
			const result = getBase64(this.imageRef, this.state.crop)

			this.imageRef = null
			this.formRef.reset()
			const resolve = this.state.resolve
			this.setState(this.getStateTemplate(), () => {
				resolve(result)
			})

		})
	}

	getHeadTheme = memo(theme => {
		return createMuiTheme({
			...theme,
			palette: {
				primary: {
					main: '#fff'
				},
				secondary: {
					...theme.palette.secondary
				},
				type: 'dark'
			}
		})
	})
}

const Transition = React.forwardRef((props, ref) => {
	return <Slide direction="up" {...props} ref={ref}/>
})

function getBase64(image, crop) {
	const canvas = document.createElement('canvas')
	const scaleX = image.naturalWidth / image.width
	const scaleY = image.naturalHeight / image.height
	canvas.width = crop.width
	canvas.height = crop.height
	const ctx = canvas.getContext('2d')

	ctx.drawImage(
		image,
		crop.x * scaleX,
		crop.y * scaleY,
		crop.width * scaleX,
		crop.height * scaleY,
		0,
		0,
		crop.width,
		crop.height
	)

	return canvas.toDataURL('image/jpeg')
}