import {apiContext} from '../context/api'

class APIHOC extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.symbols = []

		this.API = {
			get: (uri, options) => this.request('GET', uri, options),
			post: (uri, options) => this.request('POST', uri, options),
			patch: (uri, options) => this.request('PATCH', uri, options),
			put: (uri, options) => this.request('PUT', uri, options),
			delete: (uri, options) => this.request('DELETE', uri, options)

		}
	}

	render() {
		return (
			<this.props.componentType
				{...this.props.forwardProps}
				ref={this.props.forwardRef}
				API={this.API}
			/>
		)
	}

	componentWillUnmount() {
		for (let i = 0; i < this.symbols.length; i++) {
			this.props.API.cancel(this.symbols[i])
		}
	}

	request(method, uri, options = {}) {
		options.symbol = options.symbol || Symbol()
		this.symbols.push(options.symbol)
		return this.props.API.request(uri, {method, ...options})
	}
}

export function withAPI(componentType) {
	return React.forwardRef((props, ref) => (
		<apiContext.Consumer>
			{context => (
				<APIHOC
					forwardProps={props}
					forwardRef={ref}
					componentType={componentType}
					API={context}
				/>
			)}
		</apiContext.Consumer>
	))
}