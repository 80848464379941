import ReactDOM from 'react-dom'
import React from 'react'
import styled, {css} from 'styled-components'
import {Typography} from '@material-ui/core'

const StyledBubble = styled.div`
    position: fixed;
    height: auto;
    justify-content: center;
    display: flex;
    overflow: hidden;
    z-index: 1000;
    
    > div {
        padding: 10px;
        background-color: black;
        color: #fafafa;
        font-size: 12px;
        border-radius: 3px;
        //white-space: nowrap;
        max-width: 200px;
        position: relative;
        
        > div {
            position: absolute;
            width: 0;
            height: 0;
            border: 6px solid transparent;
        }
        &[data-placement="bottom"] {
            > div {
                border-bottom-color: black;
            }
            margin-top: 6px;
        }
        &[data-placement="top"] {
            > div {
                border-top-color: black;
            }
            margin-bottom: 6px;
        }
        &[data-placement="left"] {
            > div {
                border-left-color: black;
            }
            margin-right: 6px;
        }
        &[data-placement="right"] {
            > div {
                border-right-color: black;
            }
            margin-left: 6px;
        }
    }
`

const StyledWrapper = styled.div`
  ${props => props.circular === 'true' && css`
    border-radius: 50%;
  `}
`

export default class Bubble extends React.PureComponent {
    constructor(props) {
        super(props)
        autobind(this)
        
        /* Validate props */
        
        this.placement = ['bottom', 'left', 'right'].includes(this.props.placement) ? this.props.placement : 'top'
        
        if (['top', 'bottom'].includes(this.placement))
            this.alignment = ['left', 'right'].includes(this.props.alignment) ? this.props.alignment : 'center'
        else
            this.alignment = ['top', 'bottom'].includes(this.props.alignment) ? this.props.alignment : 'center'

            
        /* Init viewport overflow checking rules */
        this.correctionCurrentIndex = null
        this.correctionCirculation = this.props.overflowCorrectionOrder || [
            ['top', 'center'], ['bottom', 'center'], ['top', 'right'],
            ['left', 'center'], ['right', 'center'], ['top', 'left'],
            ['bottom', 'right'], ['bottom', 'left'], ['right', 'top'],
            ['right', 'bottom'], ['left', 'bottom'], ['left', 'top']
        ]
        this.correctionStartingIndex
            = this.correctionCirculation.findIndex(x => x[0] === this.placement && x[1] === this.alignment)

            
        /* Init state */
            
        this.state = {
            bubbleLeft: 0,
            bubbleTop: 0,
            show: false,
            arrowStyle: {
                left: 'initial',
                right: 'initial',
                top: 'initial',
                bottom: 'initial'
            },
            placement: this.placement
        }
    }
    
    render() {
        return (
            <>
                <StyledWrapper
                    ref={c => this.button = c}
                    className={this.props.className}
                    circular={Boolean(this.props.circular).toString()}
                >
                    {this.props.children}
                </StyledWrapper>
                {ReactDOM.createPortal(
                    <BubbleBase
                        bubbleRef={c => this.bubble = c}
                        top={this.state.bubbleTop}
                        left={this.state.bubbleLeft}
                        show={this.state.show && !this.props.disabled}
                        placement={this.state.placement}
                        arrowStyle={this.state.arrowStyle}
                        arrowRef={this.arrowRef.bind(this)}
                        zIndex={this.props.zIndex}
                    >
                        <Typography variant="caption">
                            {this.props.text}
                        </Typography>
                    </BubbleBase>,
                    document.body
                )}
            </>
        )
    }

    componentDidMount() {
        this.calcPosition()
        this.button.addEventListener('mouseover', this.hoverIn)
        this.button.addEventListener('mouseout', this.hoverOut)
    }
    
    componentWillUnmount() {
        this.button.removeEventListener('mouseover', this.hoverIn)
        this.button.removeEventListener('mouseout', this.hoverOut)
        window.removeEventListener('scroll', this.calcPosition)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.disabled !== this.props.disabled && this.state.show) {
            this.calcPosition()
        }

        if (!prevState.show && this.state.show) {
            (this.props.onShow || Function.prototype)()
        }

        if (prevState.show && !this.state.show) {
            (this.props.onHide || Function.prototype)()
        }
    }
    
    calcPosition() {
        /* Calculate the position & handle viewport overflow */
        
        if (!this.state.show) return null
        
        const button = this.button
        const bubble = this.bubble
        
        let left, top
        let arrowStyle = {
            top: 'initial',
            bottom: 'initial',
            left: 'initial',
            right: 'initial'
        }
        
        let buttonOffset = button.getBoundingClientRect()

        
        /* Manage placement */
        if (this.placement === 'top') {
            top = buttonOffset.top - bubble.offsetHeight - (this.props.gap || 0)
            arrowStyle.top = '100%'
            horizontalAlign.apply(this)
        }
        else if (this.placement === 'left') {
            left = buttonOffset.left - bubble.offsetWidth - (this.props.gap || 0)
            arrowStyle.left = '100%'
            verticalAlign.apply(this)
        }
        else if (this.placement === 'right') {
            left = buttonOffset.left + button.offsetWidth + (this.props.gap || 0)
            arrowStyle.right = '100%'
            verticalAlign.apply(this)
        }
        else {
            top = buttonOffset.top + button.offsetHeight + (this.props.gap || 0)
            arrowStyle.bottom = '100%'
            horizontalAlign.apply(this)
        }

        
        /* Manage alignment */
        // Vertical
        function verticalAlign() {
            if (this.alignment === 'top') {
                top = buttonOffset.top
                arrowStyle.top = Math.floor(button.offsetHeight / 2 - this.arrowEl.offsetHeight / 2)
            }
            else if (this.alignment === 'bottom') {
                top = buttonOffset.top + button.offsetHeight - bubble.offsetHeight
                arrowStyle.bottom = Math.floor(button.offsetHeight / 2 - this.arrowEl.offsetHeight / 2)
            }
            else {
                top = buttonOffset.top + Math.floor(button.offsetHeight / 2 - bubble.offsetHeight / 2)
                arrowStyle.top = 'calc(50% - 6px)'                
            }

            // Apply alignment shift
            if (this.props.alignmentShift) {
                top -= this.props.alignmentShift

                if (this.alignment === 'top') {
                    arrowStyle.top -= this.props.alignmentShift
                }
                else if (this.alignment === 'bottom') {
                    arrowStyle.bottom -= this.props.alignmentShift
                }
            }
        }
        // Horizontal
        function horizontalAlign() {
            if (this.alignment === 'right') {
                left = buttonOffset.left + button.offsetWidth - bubble.offsetWidth
                arrowStyle.right = Math.floor(button.offsetWidth / 2 - this.arrowEl.offsetWidth / 2)
            }
            else if (this.alignment === 'left') {
                left = buttonOffset.left
                arrowStyle.left = Math.floor(button.offsetWidth / 2 - this.arrowEl.offsetWidth / 2)
            }
            else {
                left = buttonOffset.left + Math.floor(button.offsetWidth / 2 - bubble.offsetWidth / 2)
                arrowStyle.left = 'calc(50% - 6px)'
            }

            // Apply alignment shift
            if (this.props.alignmentShift) {
                left -= this.props.alignmentShift

                if (this.alignment === 'right') {
                    arrowStyle.right -= this.props.alignmentShift
                }
                else if (this.alignment === 'left') {
                    arrowStyle.left -= this.props.alignmentShift
                }
            }
        }
        
        
        /* Prevent rendering outside of viewport! */
        
        let viewportWidth = document.documentElement.clientWidth
        let viewportHeight = document.documentElement.clientHeight
        
        let isOverflown = (top + bubble.offsetHeight > viewportHeight)
            || (left + bubble.offsetWidth > viewportWidth)
            || top < 0 || left < 0
        
        if (isOverflown) {
            if (this.correctionCurrentIndex !== this.correctionStartingIndex) {
                let nextIndex = this.correctionCurrentIndex === null
                                ? this.correctionStartingIndex + 1
                                : this.correctionCurrentIndex + 1
                
                if (nextIndex === this.correctionCirculation.length)
                    nextIndex = 0
                
                this.correctionCurrentIndex = nextIndex
                this.placement = this.correctionCirculation[nextIndex][0]
                this.alignment = this.correctionCirculation[nextIndex][1]
                
                this.setState({
                    placement: this.placement
                }, this.calcPosition)
                
                return
            }
        }
        
        /* Update state & re-render */
        this.setState({
            bubbleTop: top,
            bubbleLeft: left,
            arrowStyle
        })
    }
            

    arrowRef(c) {
        this.arrowEl = c
    }

    hoverIn() {
        this.setState({
            show: true   
        }, () => {
            window.addEventListener('scroll', this.calcPosition)

            this.placement = this.correctionCirculation[this.correctionStartingIndex][0]
            this.alignment = this.correctionCirculation[this.correctionStartingIndex][1]
            this.correctionCurrentIndex = null
            
            this.setState({
                placement: this.placement
            }, () => {

                this.calcPosition()
            })
        })
    }
    
    hoverOut() {
        this.setState({
            show: false,
            bubbleTop: 0,
            bubbleLeft: 0
        }, () => {
            window.removeEventListener('scroll', this.calcPosition)
        })
    }
    
    recalc() {
        this.calcPosition()
    }

    static propTypes = {
        children: PropTypes.element.isRequired,
        text: PropTypes.node.isRequired,
        alignment: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'center']),
        placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
        overflowCorrectionOrder: PropTypes.array,
        disabled: PropTypes.bool,
        alignmentShift: PropTypes.number,
        onShow: PropTypes.func,
        onHide: PropTypes.func,
        gap: PropTypes.number,
        circular: PropTypes.bool,
        zIndex: PropTypes.number
    }
}

class BubbleBase extends React.PureComponent {
    constructor(props) {
        super(props)
    }
    
    render() {
        return (
            <StyledBubble
                ref={c => this.bubbleInstance = c}
                style={{
                    left: this.props.left,
                    top: this.props.top,
                    display: this.props.show ? 'flex' : 'none',
                    zIndex: this.props.zIndex || undefined
                }}
            >
                <div data-placement={this.props.placement}>    
                    {this.props.children}
                    <div ref={this.props.arrowRef} style={this.props.arrowStyle}/>
                </div>
            </StyledBubble>
        )
    }

    componentDidMount() {
        this.props.bubbleRef(this.bubbleInstance)
    }
}