import {Redirect, redirectTo, Router} from '@reach/router'
import Layout from '../view/Layout'
import APIProvider from './APIProvider'
import UserProvider from './UserProvider'
import ScreenProvider from './ScreenProvider'
import LocationProvider from './LocationProvider'
import moment from 'moment'
import PhotoUploaderProvider from './PhotoUploaderProvider'
import DialogProvider from './DialogProvider'
import {Link, Typography} from '@material-ui/core'
import {withSnackbar} from 'notistack'
import ExternalMenu from '../view/ExternalMenu'
import End from '../view/End'

// noinspection JSUnresolvedVariable,ES6ModulesDependencies
const browserSupported = BROWSER_LIST_REGEX.test(navigator.userAgent)
	|| /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i.test(navigator.userAgent)

const StyledContainer = styled.div`
	background-color: #fbfbfb;

	&, & > div {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
`

@withRouterLocation @withSnackbar
export default class App extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.expires = moment().add(30, 'minute')
	}

	render() {
		if (!browserSupported) return <UnsupportedBrowser/>

		return (
			<LocationProvider>
				<ScreenProvider>
					<UserProvider>
						<APIProvider>
							<PhotoUploaderProvider>
								<DialogProvider>
									<StyledContainer>
										<Router primary={false}>
											<ExternalMenu path="/menu/:cloudHash"/>
											{window.isServiceAvailable ?
												<Layout path="/*"/>
												:
												<>
													<End path="/"/>
													<Redirect from="*" to="/"/>
												</>
											}
										</Router>
									</StyledContainer>
								</DialogProvider>
							</PhotoUploaderProvider>
						</APIProvider>
					</UserProvider>
				</ScreenProvider>
			</LocationProvider>
		)
	}

	componentDidMount() {
		// Handle redirect
		const query = new URLSearchParams(window.location.search)
		const redirectPath = query.get('r')
		redirectPath && redirectTo(redirectPath)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.routerLocation.pathname !== this.props.routerLocation.pathname) {
			if (moment().isAfter(this.expires)) {
				return window.location.reload()
			}
			else if (GA_MEASUREMENT_ID) {
				gtag('config', GA_MEASUREMENT_ID, {page_path: this.props.routerLocation.pathname})
			}

			window.scrollTo(0, 0)
		}
	}
}

const StyledUnsupportedWrapper = styled.div`
	background-color: rgba(0, 0, 0, .08);
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	
	> div {
		margin: 64px auto;
	}
`

const UnsupportedBrowser = React.memo(() => (
	<StyledUnsupportedWrapper>
		<div>
			<Typography variant="h6">
				Váš prohlížeč není podporován.
			</Typography>
			<Typography variant="caption">
				Prosíme, <Link href="https://bestvpn.org/outdatedbrowser/cs" target="_blank">aktualizujte si prohlížeč</Link>.
			</Typography>
		</div>
	</StyledUnsupportedWrapper>
))