import {Location} from '@reach/router'

export function withRouterLocation(ComponentType) {
	return React.forwardRef((props, ref) => (
		<Location>
			{({location}) => (
				<ComponentType
					{...props}
					ref={ref}
					routerLocation={location}
				/>
			)}
		</Location>
	))
}