import {createMuiTheme} from '@material-ui/core/styles'

export default createMuiTheme({
	palette: {
		primary: {
			main: '#d32f2f'
		},
		secondary: {
			main: '#FFB300'
		}
	},
	shape: {
		borderRadius: 2
	},
	typography: {
		useNextVariants: true
	},
	overrides: {
		MuiTableCell: {
			root: {
				fontSize: 13
			}
		}
	}
})
