import {Button} from '@material-ui/core'

const StyledMapCover = styled.div`
	width: 100%;
	height: 400px;
	background-position: center;
	background-size: cover;
	display: flex;
	> button {margin: auto}
`

export default function MapCover(props) {
	return (
		<StyledMapCover className="image map-cover">
			<Button onClick={props.onShow} color="secondary" variant="contained" size="large">
				Zobrazit mapu
			</Button>
		</StyledMapCover>
	)
}