import {LayoutZone, Padding} from '../../../style/lib'
import {Grid, Link as MaterialLink, Paper, Typography} from '@material-ui/core'
import {withSnackbar} from 'notistack'
import {Link} from '@reach/router'
import {MetaTags} from 'react-meta-tags'

const StyledParagraph = styled(Typography)`
	margin-top: 0.5rem;
	margin-bottom: 1.1rem;
`
const StyledH1 = styled(Typography)`
	margin-top: 1.1rem;
	margin-bottom: 0.8rem;
`

const StyledH2 = styled(Typography)`
	margin-top: 2rem;
	margin-bottom: 1.43rem;
`
@withAPI @withSnackbar
export default class SeoDelivery extends React.PureComponent {

	render() {
		const title = `Rozvoz jídel drží restaurace i strávníky nad vodou. | ${window.WebClaim}`
		return (
			<>
				<MetaTags>
					<title>{title}</title>
					<meta name="title" content={title}/>
					<meta name="description"
					      content='Přehled restaurací a obchodů nabízejících rozvoz jídel. Registrace pro restaurace a obchody je zdarma.'/>
					<meta name="keywords"
					      content='rozvoz jídel, rozvoz jídla, rozvoz jídla praha, rozvoz jídla brno, rozvoz jídla plzeň'/>
				</MetaTags>
				<LayoutZone>
					<Padding size={40}>
						<Paper>
							<Padding size={32}>
								<Grid container spacing={4} justify="center" direction="column">
									<Grid item xs={12}>

										<StyledH1 variant="h4" component="h1">
											Rozvoz jídel a jídlo s sebou pomáhá restauracím
											přežít
										</StyledH1>

										<StyledParagraph variant="body1">
											Rozvoz jídel nebo jídlo s sebou je v současnosti jediný způsob, jak si
											obstarat
											čerstvě
											uvařený oběd či večeři a na druhé straně způsob prodeje, díky kterému mohou
											restaurace a
											další gastro zařízení zůstat v provozu. Pokud si chcete nechat dovézt jídlo
											domů, <MaterialLink to={'/'} component={Link}>vyberte si z
											nabídky</MaterialLink>.
										</StyledParagraph>
										<StyledParagraph variant="body1">
											V souvislosti s pandemií koronaviru nařídila vláda České republiky mnohá
											mimořádná
											opatření, mezi kterými je kromě výrazného omezení volného pohybu osob také
											např.
											uzavření všech provozoven nabízejících stravovací služby. Pro mnohé
											restaurace a
											další gastro provozy je tato komplikace téměř likvidační, pokud se
											nepřizpůsobí.
										</StyledParagraph>
										<StyledParagraph variant="body1">
											S přibývajícími teplými jarními dny by se jim za normálních okolností začaly
											plnit
											letní zahrádky návštěvníky, zatímco nyní kvůli nařízené karanténě zejí města
											a
											vesnice prázdnotou. Ani to však není důvod vzdát se a ukončit podnikání.
											Řada
											lidí
											ocení rozvoz jídla do zaměstnání či domů, a i z negativní situace se dá
											vytěžit
											maximum pro zachování podnikatelské činnosti.
										</StyledParagraph>
										<StyledH2 variant="h5" component="h2">
											Rozvážíte jídlo nebo potraviny? Získejte více
											zákazníků
										</StyledH2>
										{window.isSignUpAvailable &&
											<StyledParagraph variant="subtitle2">
												Pokud jste majitelem restaurace, cukrárny, pizzerie nebo jakékoliv jiné
												gastro
												provozovny a chcete svým zákazníkům nabídnout rozvoz
												jídel, <MaterialLink
												to={'/panel/signup'} component={Link}>zaregistrujte
												se</MaterialLink> a dejte
												návštěvníkům našeho webu vědět o svojí nabídce.
											</StyledParagraph>
										}
										<StyledParagraph variant="body1">
											A nejedná se přitom pouze o restaurace nebo kavárny. Rozvoz domů lze
											aplikovat i
											v
											maloobchodních prodejnách potravin či drogerie nebo v lékárnách. Tyto
											obchody
											sice
											zůstaly veřejnosti otevřené, avšak při dodržování nařízeného bezpečného
											odstupu
											mezi
											jednotlivými osobami min. 2 metry, většinou pouští dovnitř pouze omezené
											množství
											zákazníků v jednom čase.
										</StyledParagraph>
										<StyledParagraph variant="body1">
											Nově navíc vláda stanovila vyhrazenou dobu, určenou pouze pro nákupy seniorů
											a
											osob
											starších 65 let, a to denně od 7 do 9 hodin. Před obchody se tak začínají
											tvořit
											dlouhé fronty a lidé netrpělivě vyčkávají s obavami, zda ještě seženou vše,
											pro
											co
											se vypravili.
										</StyledParagraph>
										<StyledH2 variant="h6" component="h3">
											Největší zájem o rozvoz jídla je v Praze, Brně a Plzni
										</StyledH2>
										{window.isSignUpAvailable &&
											<StyledParagraph variant="subtitle2">
												Chcete svým zákazníkům nabídnout rozvoz potravin, drogerie, hygienických
												potřeb
												nebo zdravotnického materiálu až do domu? <MaterialLink
												to="/auth/signup"
												component={Link}>Zaregistrujte
												se</MaterialLink> a
												nabídněte lidem
												ve vašem okolí, co vše si mohou objednat a nechat dovézt.
											</StyledParagraph>
										}
										<StyledParagraph variant="body1">
											Dny v karanténě ubíhají pomaleji, než bychom si všichni přáli. Kromě
											standardních
											povinností přibývá denní vaření pro celou rodinu, a především pro děti,
											které
											zavřením škol přišly také o možnost školního stravování. Naštěstí však stále
											funguje
											rozvoz jídel, a lidé si tak mohou objednat domů to, na co mají zrovna chuť.
											Ušetří
											tím drahocenný čas, a navíc pomohou živnostníkům přežít toto velmi náročné
											období.
										</StyledParagraph>
										<StyledParagraph variant="subtitle2">
											Chcete si objednat rozvozem jídlo, potraviny, drogistické nebo hygienické
											potřeby
											pro vaši domácnost? <MaterialLink to="/" component={Link}>Vyberte si z
											nabídky</MaterialLink> rozvozových firem a zajistěte si dostatek zásob pro
											případný
											pobyt v
											karanténě.
										</StyledParagraph>
									</Grid>
								</Grid>
							</Padding>
						</Paper>
					</Padding>
				</LayoutZone>
			</>
		)
	}
}
