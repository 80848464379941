import {
	Button,
	CircularProgress,
	createMuiTheme,
	Dialog,
	Divider,
	Grid,
	Link as MaterialLink,
	MuiThemeProvider,
	TextField,
	Typography
} from '@material-ui/core'
import IconFacebook from 'mdi-material-ui/Facebook'
import {withSnackbar} from 'notistack'
import validator from 'validator'
import {Padding} from '../style/lib'
import {Alert} from '@material-ui/lab'
import {ProfileField} from './ProfileField'

export const SignInButton = React.memo(props => (
	<SignIn {...props} type="native"/>
))

export const FacebookButton = React.memo(props => (
	<SignIn {...props} type="facebook"/>
))

const StyledFlexPadding = styled(Padding)`
	display: flex;
`

const StyledCenteredContent = styled.div`
	margin: auto;
`

@withSnackbar @withAPI @withUser
export class SignIn extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.state = this.getInitialState()
	}

	render() {
		const actionContext = this.getActionContext()
		const dialogOpen = !!(
			typeof this.state.email === 'string' ||
			this.state.signupToken || this.state.profileToken
		)

		return (
			<>
				{this.props.type === 'native' ? (
					<Button
						fullWidth
						variant="contained"
						disableElevation
						color="primary"
						onClick={this.initiate}
					>
						Přihlásit se
					</Button>
				) : (
					<MuiThemeProvider theme={this.getFbButtonTheme}>
						<Button
							startIcon={<IconFacebook/>}
							fullWidth
							variant="contained"
							disableElevation
							color="primary"
							onClick={this.initiate}
						>
							Pokračovat s Facebookem
						</Button>
					</MuiThemeProvider>
				)}
				<Dialog open={dialogOpen} onClose={this.resetState} fullWidth maxWidth="xs">
					<form onSubmit={actionContext.submitHandler}>
						<Padding>
							<Typography variant="h6">Přihlášení</Typography>
						</Padding>
						<Divider/>
						{actionContext.alertText && (
							<Alert severity={actionContext.alertSeverity}>
								<Typography>{actionContext.alertText}</Typography>
							</Alert>
						)}
						{this.state.missingFields ? (
							<Padding>
								<Grid container spacing={2}>
									{this.state.missingFields.map((field, index) => (
										<ProfileField
											key={field}
											name={field}
											value={this.state.completedFields[index] || ''}
											index={index}
											changeMissingField={this.changeMissingField}
											disabled={this.state.processing}
										/>
									))}
									<Grid item xs={12}>
										<Button
											fullWidth
											variant="contained"
											disabled={this.state.completedFields.includes('') || this.state.processing}
											type="submit"
											color="primary"
										>
											{this.state.processing ? <CircularProgress size={24}/> : 'Pokračovat'}
										</Button>
									</Grid>
								</Grid>
							</Padding>
						) : (
							<Padding>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<TextField
											fullWidth autoFocus
											type="email"
											variant="outlined"
											label="E-mail"
											placeholder="Zadejte přihlašovací e-mail"
											value={this.state.email || ''}
											onChange={this.changeEmail}
											disabled={this.state.processing}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											fullWidth
											type="password"
											variant="outlined"
											label="Heslo"
											placeholder="Zadejte heslo"
											value={this.state.password || ''}
											onChange={this.changePassword}
											disabled={this.state.processing}
										/>
									</Grid>
									<Grid item xs={12}>
										<Button
											fullWidth
											variant="contained"
											disabled={this.state.email === '' || this.state.password === '' || this.state.processing}
											type="submit"
											color="primary"
										>
											{this.state.processing ? <CircularProgress size={24}/> : 'Přihlásit'}
										</Button>
									</Grid>
								</Grid>
							</Padding>
						)}
						<Divider/>
						{window.isSignUpAvailable && <>
							<StyledFlexPadding>
								<StyledCenteredContent>
									<Typography variant="caption">
										Nemáte účet?&nbsp;&nbsp;
										<MaterialLink href="/auth/signup" target="_blank">
											Zaregistrovat se
										</MaterialLink>.
									</Typography>
								</StyledCenteredContent>
							</StyledFlexPadding>
							<Divider/>
						</>}
						<StyledFlexPadding>
							<StyledCenteredContent>
								<Typography variant="caption">
									Zapomenuté heslo?&nbsp;&nbsp;
									<MaterialLink href="/auth/recover" target="_blank">
										Obnovit
									</MaterialLink>.
								</Typography>
							</StyledCenteredContent>
						</StyledFlexPadding>
					</form>
				</Dialog>
			</>
		)
	}

	getInitialState() {
		return {
			email: null,
			password: null,
			facebookToken: null,
			missingFields: null,
			completedFields: null,
			profileToken: null,
			signupToken: null
		}
	}

	initiate() {
		if (this.props.type === 'facebook') {
			FB.login(response => {
				if (!response.authResponse || !response.authResponse.accessToken) {
					return this.props.enqueueSnackbar('Váš Facebook účet nebylo možné propojit.', {
						variant: 'error'
					})
				}

				this.setState({
					facebookToken: response.authResponse.accessToken
				}, this.facebookAuth)
			}, {scope: 'email,public_profile'})
			return
		}

		this.setState({email: '', password: ''})
	}

	changeEmail(e) {
		this.setState({email: e.target.value})
	}

	changePassword(e) {
		this.setState({password: e.target.value})
	}

	facebookAuth() {
		this.props.API.post('/public/facebook_auth', {
			body: {facebookToken: this.state.facebookToken}
		})
		.then(result => {
			if (result.fields) {
				const fields = Object.keys(result.fields)
				return this.setState({
					missingFields: fields,
					completedFields: fields.map(f => result.fields[f]),
					signupToken: result.fields.securityToken
				})
			}

			if (result.missingFields) {
				return this.setState({
					missingFields: result.missingFields.fields,
					completedFields: result.missingFields.fields.map(() => ''),
					profileToken: result.missingFields.tmpAccessToken,
					processing: false
				})
			}

			if (gtag) gtag('event', 'login')
			this.props.user.login(result.refreshToken.string)
		})
		.catch(err => {
			this.setState({facebookToken: null})
			console.log(err)
			return this.props.enqueueSnackbar('Váš Facebook účet nebylo možné propojit.', {
				variant: 'error'
			})
		})
	}

	submitLogin(e) {
		if (e) {
			e.preventDefault()
			e.stopPropagation()
		}

		if (!validator.isEmail(this.state.email)) {
			return this.props.enqueueSnackbar('Neplatný e-mail.', {variant: 'error'})
		}

		this.setState({processing: true}, () => {
			this.props.API.post('/public/user_refresh_token', {
				body: {
					email: this.state.email,
					password: this.state.password
				}
			})
			.then(result => {
				if (result.missingFields) {
					return this.setState({
						missingFields: result.missingFields.fields,
						completedFields: result.missingFields.fields.map(() => ''),
						profileToken: result.missingFields.tmpAccessToken,
						processing: false
					})
				}

				if (gtag) gtag('event', 'login')
				this.props.user.login(result.token.string)
			})
			.catch(error => {
				this.setState({processing: false})

				if (error === 'INVALID_EMAIL' || error === 'UNEXISTENT_ACCOUNT') {
					return this.props.enqueueSnackbar('Účet s tímto e-mailem neexistuje.', {variant: 'error'})
				}

				if (error === 'BAD_PASSWORD') {
					return this.props.enqueueSnackbar('Bylo zadáno chybné heslo.', {variant: 'error'})
				}

				console.log(error)
				this.props.enqueueSnackbar('Přihlášení se nezdařilo.', {variant: 'error'})
			})
		})
	}

	submitSignup(e) {
		if (e) {
			e.preventDefault()
			e.stopPropagation()
		}

		const body = {securityToken: this.state.signupToken}

		for (let i = 0; i < this.state.missingFields.length; i++) {
			const field = this.state.missingFields[i]
			body[field] = this.state.completedFields[i]
		}

		this.setState({processing: true}, () => {
			this.props.API.post('/public/facebook_signup', {body})
			.then(() => this.facebookAuth())
			.catch(err => {
				this.setState({processing: false})

				if (err === 'INVALID_NAME') {
					return this.props.enqueueSnackbar('Neplatné jméno.', {variant: 'error'})
				}

				if (err === 'INVALID_PHONE') {
					return this.props.enqueueSnackbar('Telefonní číslo musí být ve formátu +420xxxxxxxxx nebo +421xxxxxxxxx.', {
						variant: 'error'
					})
				}

				if (err === 'PHONE_ALREADY_EXISTS') {
					return this.props.enqueueSnackbar('Toto telefonní číslo má již registrováno jiný uživatel.', {
						variant: 'error'
					})
				}

				this.props.enqueueSnackbar('Při zpracování požadavku došlo k chybě. Zkuste to, prosím, za chvíli.', {variant: 'error'})
			})
		})
	}

	submitProfile(e) {
		if (e) {
			e.preventDefault()
			e.stopPropagation()
		}

		const body = {tmpAccessToken: this.state.profileToken}

		for (let i = 0; i < this.state.missingFields.length; i++) {
			body[this.state.missingFields[i]] = this.state.completedFields[i]
		}

		this.setState({processing: true}, () => {
			this.props.API.post('/public/user/complete_profile', {body})
			.then(() => {
				this.state.facebookToken
					? this.facebookAuth()
					: this.submitLogin()
			})
			.catch(err => {
				this.setState({processing: false})

				if (err === 'INVALID_NAME') {
					return this.props.enqueueSnackbar('Neplatné jméno.', {variant: 'error'})
				}

				if (err === 'INVALID_PHONE') {
					return this.props.enqueueSnackbar('Telefonní číslo musí být ve formátu +420xxxxxxxxx nebo +421xxxxxxxxx.', {
						variant: 'error'
					})
				}

				if (err === 'PHONE_ALREADY_EXISTS') {
					return this.props.enqueueSnackbar('Toto telefonní číslo má již registrováno jiný uživatel.', {
						variant: 'error'
					})
				}

				console.log(err)
				this.props.enqueueSnackbar('Při zpracování požadavku došlo k chybě. Zkuste to, prosím, za chvíli.', {variant: 'error'})
			})
		})
	}

	getActionContext() {
		const action = this.state.missingFields
			? (this.state.profileToken ? 'profile' : 'signup')
			: 'login'

		let submitHandler, alertText, alertSeverity

		switch (action) {
			case 'login':
				submitHandler = this.submitLogin
				break
			case 'profile':
				submitHandler = this.submitProfile
				alertText = 'Před přihlášením prosíme o doplnění několika chybějících údajů.'
				alertSeverity = 'info'
				break
			case 'signup':
				submitHandler = this.submitSignup
				alertText = 'Prosíme ještě o pár informací...'
				alertSeverity = 'success'
				break
		}

		return {submitHandler, alertSeverity, alertText, action}
	}

	changeMissingField(fieldIndex, value) {
		this.setState(prevState => {
			const completedFields = [...prevState.completedFields]
			completedFields[fieldIndex] = value
			return {completedFields}
		})
	}

	getFbButtonTheme(theme) {
		return createMuiTheme({
			...theme,
			palette: {
				...theme.palette,
				primary: {
					main: 'rgb(24, 119, 242)'
				}
			}
		})
	}

	resetState() {
		this.setState(this.getInitialState())
	}
}