import {photoUploaderContext} from '../context/photoUploader'

export function withPhotoUploader(ComponentType) {
	return React.forwardRef((props, ref) => (
		<photoUploaderContext.Consumer>
			{context => (
				<ComponentType
					{...props}
					ref={ref}
					uploadPhoto={context}
				/>
			)}
		</photoUploaderContext.Consumer>
	))
}