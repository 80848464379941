import styled, {css} from 'styled-components'
import {Grid, IconButton as MaterialIconButton, Table as MaterialTable, TableCell} from '@material-ui/core'

export const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
  
  tr td {
    max-width: initial;
  }
`

export const Table = React.forwardRef((props, ref) => (
    <TableWrapper>
      <MaterialTable {...props} ref={ref}/>
    </TableWrapper>
))

export const TableCellFlat = styled(TableCell)`
  padding: 4px !important;
  width: 48px !important;
`

export const Padding = styled.div.attrs(props => ({vertical: (props.vertical || false).toString()}))`
  overflow: ${props => props.overflow || 'hidden'};
  padding: ${props => props.size || 16}px;
  ${props => props.edge && css`
    padding-${props.edge}: 0;
  `}
  
  ${props => props.vertical === 'true' && css`
    padding-left: 0;
    padding-right: 0;
  `}
`
Padding.propTypes = {
    edge: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    size: PropTypes.number,
    vertical: PropTypes.bool,
    overflow: PropTypes.oneOf(['hidden', 'visible'])
}

export const IconButton = styled(MaterialIconButton).attrs(props => props.size === 'fit' ? {
    size: undefined,
    fit: 'true'
} : {})`
  ${props => props.fit === 'true' && css`
    padding: 6px;
  `}
`

export const LayoutZoneWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`

const LayoutZoneCentered = styled.div`
    width: 100%;
    max-width: ${props => props.maxWidth}px;
    padding: ${props => props.pad === 'true' ? '16px' : 0} 16px;
    margin: ${props => props.align === 'true' ? 'auto' : 0} auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
`

export const LayoutZone = React.forwardRef((props, ref) => {
    const proxiedProps = {...props}
    delete proxiedProps.centerVertical
    delete proxiedProps.verticalPadding

    return (
        <LayoutZoneWrapper>
            <LayoutZoneCentered
                {...props}
                align={(props.centerVertical || false).toString()}
                pad={(props.verticalPadding || false).toString()}
                maxWidth={props.maxWidth}
                ref={ref}
            />
        </LayoutZoneWrapper>
    )
})

LayoutZone.propTypes = {
    centerVertical: PropTypes.bool,
    verticalPadding: PropTypes.bool,
    maxWidth: PropTypes.number
}

LayoutZone.defaultProps = {
    maxWidth: 1248
}

export const ContainerGrid = styled(Grid).attrs({container: true})`
    width: ${props => `calc(100% + ${props.spacing * 8 + 1}px)`};
`