import {CircularProgress} from '@material-ui/core'

const StyledProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  align-self: stretch;
  margin: auto;
  min-height: 240px;
`

export default function PageLoader() {
    return (
        <StyledProgressWrapper>
            <CircularProgress
                variant="indeterminate"
                color="primary"
                disableShrink
            />
        </StyledProgressWrapper>
    )
}