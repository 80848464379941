import {Grid} from '@material-ui/core'
import {RestaurantCardCompact} from './RestaurantCardCompact'

export default class RestaurantList extends React.PureComponent {
	render() {
		return (
			<Grid container spacing={2}>
				{(this.props.restaurants || []).map((restaurant, index) => (
					<RestaurantCardCompact
						key={index}
						restaurant={restaurant}
						alive={(this.props.alive || {})[restaurant.id]}
						index={index}/>
				))}
			</Grid>
		)
	}
}