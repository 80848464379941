import {locationContext} from '../context/location'

export function withLocation(ComponentType) {
	return React.forwardRef((props, ref) => (
		<locationContext.Consumer>
			{context => (
				<ComponentType
					{...props}
					ref={ref}
					location={context}
				/>
			)}
		</locationContext.Consumer>
	))
}