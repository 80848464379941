import './module/polyfills'
import moment from 'moment'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {withAPI} from './module/withAPI'
import {withUser} from './module/withUser'
import {withLocation} from './module/withLocation'
import {withRouterLocation} from './module/withRouterLocation'
import {withScreen} from './module/withScreen'
import {withMatch} from './module/withMatch'
import {withPhotoUploader} from './module/withPhotoUploader'
import {withDialog} from './module/withDialog'

Array.prototype.shuffle = function() {
	for (var i = this.length - 1; i > 0; i--) {
		var j = Math.floor(Math.random() * (i + 1));
		var temp = this[i];
		this[i] = this[j];
		this[j] = temp;
	}
	return this;
}

Array.prototype.groupBy = function(key) {
	return this.reduce(function(rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
}

moment.locale('cs')
moment.updateLocale('cs', {
	week: {
		dow: 1,
		doy: 4
	}
})

window.React = React
window.autobind = autobind
window.styled = styled
window.PropTypes = PropTypes
window.WebName = 'vyzvednisi.cz'
window.WebClaim = 'Pojďme si pomoci nezavřít'

window.GOOGLE_MAP_API_KEY = GOOGLE_MAP_API_KEY
window.BUGSNAG_KEY = BUGSNAG_KEY
window.RESTAURANT_ONLINE_TIMEOUT = RESTAURANT_ONLINE_TIMEOUT
window.S3_URI = S3_URI
window.API_URI = API_URI
window.PLAY_STORE_URI = 'https://play.google.com/store/apps/details?id=com.dotypos.orders.terminal&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
window.APP_ENVIRONMENT = APP_ENVIRONMENT
window.FB_APP_ID = FB_APP_ID
window.isSignUpAvailable = Date.now() < 1682028000000 // 2023-04-21 00:00:00 Europe/Prague
window.isServiceAvailable = Date.now() < 1685570400000 // 2023-06-01 00:00:00 Europe/Prague

window.withAPI = withAPI
window.withUser = withUser
window.withRouterLocation = withRouterLocation
window.withScreen = withScreen
window.withMatch = withMatch
window.withPhotoUploader = withPhotoUploader
window.withDialog = withDialog
window.withLocation = withLocation