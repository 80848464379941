import {LayoutZone, Padding} from '../../style/lib'
import {Card, Grid, Link as MaterialLink, TextField, Typography} from '@material-ui/core'
import {withSnackbar} from 'notistack'
import {Link} from '@reach/router'
import Button from '@material-ui/core/Button'
import validator from 'validator'
import {Alert, AlertTitle} from '@material-ui/lab'

@withAPI @withSnackbar
export default class RecommendSeller extends React.PureComponent {

	constructor(props) {
		super(props)
		autobind(this)

		this.state = {
			name: '',
			address: '',
			webpage: '',
			phone: '',
			sending: false,
			sent: false
		}
	}

	render() {
		return (
			<LayoutZone>
				<Padding vertical size={32}>
					<Grid container spacing={2} justify="center" direction="column">
						<Grid item xs={12}>
							<Typography variant="h5" component="h1">Doporučit prodejce</Typography>
							<Typography variant="subtitle1">Nenašli jste tu váš oblíbený obchod, nebo restauraci? Dejte nám o nich vědět.</Typography>
						</Grid>
						{!this.state.sent && (
							<Grid item xs={12}>
								<Card>
									<form onSubmit={this.submit}>
										<Padding>
											<Grid container spacing={2}>
												<Grid item xs={12} md={6}>
													<TextField
														fullWidth autoFocus
														required
														type="text"
														variant="outlined"
														label="Název prodejce"
														placeholder="např. U Fleků"
														value={this.state.name}
														onChange={this.changeName}
													/>
												</Grid>
												<Grid item xs={12} md={6}>
													<TextField
														fullWidth
														type="address"
														variant="outlined"
														label="Adresa"
														placeholder="např. Na růžku 13, Hodonín"
														value={this.state.address}
														onChange={this.changeAddress}
													/>
												</Grid>
												<Grid item xs={12} md={6}>
													<TextField
														fullWidth
														type="webpage"
														variant="outlined"
														label="Webové stránky"
														placeholder="např. www.ufleku.cz"
														value={this.state.webpage}
														onChange={this.changeWebpage}
													/>
												</Grid>
												<Grid item xs={12} md={6}>
													<TextField
														fullWidth
														type="tel"
														variant="outlined"
														label="Telefon"
														placeholder="např. +420 221 221 331"
														value={this.state.phone}
														onChange={this.changePhone}
													/>
												</Grid>
												<Grid item>
													<Button
														type="submit"
														variant="contained"
														color="primary"
														display="flex"
														disabled={this.state.sending}
													>
														Odeslat
													</Button>
												</Grid>
											</Grid>
										</Padding>
									</form>
								</Card>
							</Grid>
						)}
						{!!this.state.sent && (
							<Grid item>
								<Alert severity="info">
									<AlertTitle>
										Děkujeme!
									</AlertTitle>
									<Typography>
										Děkujeme za doporučení. S prodejcem se spojíme a pomůžeme mu s registrací na <MaterialLink component={Link} to="/">vyzvednisi.cz</MaterialLink>
									</Typography>
								</Alert>
							</Grid>
						)}
					</Grid>
				</Padding>
			</LayoutZone>
		)
	}

	changeName(e) {
		this.setState({name: e.target.value})
	}

	changeAddress(e) {
		this.setState({address: e.target.value})
	}

	changeWebpage(e) {
		this.setState({webpage: e.target.value})
	}

	changePhone(e) {
		this.setState({phone: e.target.value})
	}

	submit(e) {
		e.preventDefault()

		if (this.state.email && !validator.isEmail(this.state.email)) {
			return this.props.enqueueSnackbar('Neplatný e-mail.', {variant: 'error'})
		}

		if (this.state.phone && !validator.isMobilePhone(this.state.phone, ['cs-CZ', 'sk-SK'])) {
			return this.props.enqueueSnackbar('Neplatné telefonní číslo.')
		}

		this.setState({sending: true}, () => {
			this.props.API.post('/web/support?type=proposeNewSeller', {
				body: {
					name: this.state.name,
					address: this.state.address,
					webpage: this.state.webpage,
					phone: this.state.phone
				}
			})
			.then(() => {
				this.setState({sent: true})
				this.setState({sending: false})
			})
			.catch(() => {
				this.setState({sending: false})
			})
		})
	}
}