import {Router} from '@reach/router'
import {Typography} from '@material-ui/core'
import IconSuccess from 'mdi-material-ui/CheckboxMarkedCircleOutline'
import IconFailure from 'mdi-material-ui/AlertCircleOutline'

const StyledRouter = styled(Router)`
	display: flex;
  	flex-direction: column;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
`

export default class PaymentsContract extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)
	}

	render() {
		return (
			<StyledRouter>
				<Signed path="signed"/>
				<KycSigned path="kyc-signed"/>
				<Incomplete path="incomplete"/>
				<KycIncomplete path="kyc-incomplete"/>
			</StyledRouter>
		)
	}
}

const StyledTitle = styled.div`
  margin-bottom: 16px;
  svg {
    position: relative;
    top: 2px;
    right: 8px;
  }
`

const Signed = React.memo(() => {
	return (
		<>
			<StyledTitle>
				<Typography variant="h5">
					<IconSuccess/> Online platby byly aktivovány
				</Typography>
			</StyledTitle>
			<Typography variant="body2">
				Gratulujeme, nyní máte na Vyzvednisi.cz možnost povolit online platby.
			</Typography>
		</>
	)
})

const KycSigned = React.memo(() => {
	return (
		<>
			<StyledTitle>
				<Typography variant="h5">
					<IconSuccess/> Úplné ověření bylo dokončeno
				</Typography>
			</StyledTitle>
			<Typography variant="body2">
				Gratulujeme, nyní můžete pokračovat ve využívání online plateb na Vyzvednisi.cz.
			</Typography>
		</>
	)
})

const Incomplete = React.memo(() => {
	return (
		<>
			<StyledTitle>
				<Typography variant="h5">
					<IconFailure/> Aktivace online plateb se nezdařila
				</Typography>
			</StyledTitle>
			<Typography variant="body2">
				Prosíme, kontaktujte podporu – <b>info@vyzvednisi.cz</b>
			</Typography>
		</>
	)
})

const KycIncomplete = React.memo(() => {
	return (
		<>
			<StyledTitle>
				<Typography variant="h5">
					<IconFailure/> Automatické ověření se nezdařilo
				</Typography>
			</StyledTitle>
			<Typography variant="body2">
				Vaše žádost bude individuálně posouzena. Vyčkejte na kontakt naší technické podpory.
			</Typography>
		</>
	)
})