import Autocomplete from '@material-ui/lab/Autocomplete'
import {Link, Redirect, Router} from '@reach/router'
import {
	AppBar,
	Button,
	Grid,
	Hidden,
	InputAdornment,
	Link as MaterialLink,
	Paper,
	TextField,
	Typography
} from '@material-ui/core'
import {LayoutZone, Padding} from '../../style/lib'
import Home from './Home'
import Restaurant from './Restaurant'
import NotFound from './NotFound'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import LockRoundedIcon from '@material-ui/icons/LockRounded'
import memo from 'memoize-one'
import Panel from './Panel.loader'
import Browse from './Browse'
import Online from './Online'
import RestaurantListByTags from './RestaurantListByTags'
import SeoDelivery from './Seo/SeoDelivery'
import social from '../../definition/social'
import TermsOfUse from './Static/TermsOfUse'
import PrivacyPolicy from './Static/PrivacyPolicy'
import Auth from './Auth.loader'
import Nearby from './Nearby'
import IconSearch from 'mdi-material-ui/Magnify'
import IconClose from 'mdi-material-ui/Close'
import linksBar from './../../definition/linksBar.json'
import {Sticky} from '../Sticky'
import {Flex, FlexItem} from '../Flex'
import RecommendSeller from './RecommendSeller'
import OrderTracking from './OrderTracking.loader'
import {css} from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import CityRank from './Rank/CityRank'
import Derby from './Rank/Derby'
import IconFacebook from 'mdi-material-ui/Facebook'
import IconLinkedIn from 'mdi-material-ui/Linkedin'
import IconTwitter from 'mdi-material-ui/Twitter'
import IconInstagram from 'mdi-material-ui/Instagram'
import IconHomeCity from 'mdi-material-ui/HomeCity'
import geo from '../../definition/geo'
import {Big} from 'big.js'
import {HERE} from '../../module/HERE'
import baseTheme from '../../module/theme'
import store from 'store'
import Fuse from 'fuse.js'
import {RestaurantCardCompact} from '../RestaurantCardCompact'
import LaFormaggeria from './Static/LaFormaggeria'
import About from './Static/About'
import BlogPostList from './Blog/BlogPostList'
import BlogPost from './Blog/BlogPost'
import PaymentsContract from './PaymentsContract'
import CookieConsent from '../CookieConsent'

const StyledPageWrapper = styled.div`
	display: flex;
	position: relative;
	flex-grow: 1;
	flex-direction: column;

	&:after {
		z-index: 10;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		background-color: #FFFFFFCC;
		opacity: ${props => props.search ? 1 : 0};
		pointer-events: ${props => props.search ? 'all' : 'none'};
		transition: opacity 0.2s ease;
	}
`

const StyledContentWrapper = styled.div`
	display: flex;
	position: relative;
	flex-grow: 1;
	flex-direction: column;
	
	${props => props.search && css`
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		z-index: 9;
		> * {
			flex-shrink: 0;
		}
	`}
`

const StyledBody = styled.div`
	&, & > div {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}
`

const StyledLogoWrapperFlexItem = styled(FlexItem)`
	width: ${props => props.mobile ? 142 : 172}px;
	display: ${props => props.mobile && props.search ? 'none' : 'block'};
`

const StyledLogoLink = styled(Link)`
	text-decoration: none;
	display: block;
	width: ${props => props.mobile ? 142 : 172}px;
	height: ${props => props.mobile ? 48 : 64}px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: auto 100%;
`

const StyledLocationTextField = styled(TextField)`
	width: 100%;
	
	> div:last-child {
		border-radius: 2px;
		overflow: hidden;
		
		&:before {
			content: none;
		}
	}
`

const StyledFooter = styled.div`
	padding: 32px 0;
	background-color: #ffdc88;
	margin-top: 16px;
`

const StyledFooterBottom = styled.div`
	color: #FFF8E1;
	background-color: #281c00;
`

const StyledStickyBar = styled(Sticky)`
	width: 100%;
	z-index: 1000;
`

const StyledLocationInputFlexItem = styled(FlexItem)`
	margin-left: ${props => props.mobile ? 0 : '32px'};
	${props => props.mobile && css`margin: 8px 0;`}
`

const StyledLayoutZone = styled(LayoutZone)`
	${props => props.mobile && css`padding: 8px 16px;`}
`

const StyledDesktopSearchButton = styled(Button)`
	color: #f5f5f5;
	padding: 6px 16px;
`

@withMatch('nearby/:address')
@withAPI @withUser @withScreen
export default class Layout extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.state = {
			links: linksBar,
			alive: {},
			sticky: false,
			search: false,
			searchResults: [],
			restaurants: []
		}

		this.shuffleArray(this.state.links)
	}

	shuffleArray(array) {
		for (let i = array.length - 1; i > 0; i--) {
			let j = Math.floor(Math.random() * (i + 1))
			let temp = array[i]
			array[i] = array[j]
			array[j] = temp
		}
	}

	render() {
		const mobile = this.props.screen.width < 960
		return (
			<>
				<CookieConsent/>
				<StyledStickyBar id="AppBar">
					<AppBar position="static">
						<StyledLayoutZone
							centerVertical
							verticalPadding
							mobile={+(this.props.screen.width < 960)}
							ref={ref => this.layoutZoneSlider = ref}
						>
							<Flex justify="space-between" align="center" spacing={2}>
								<FlexItem grow={1} shrink={true}>
									<Flex align="center" spacing={2} overflow="visible">
										<StyledLogoWrapperFlexItem
											shrink={false}
											mobile={+mobile}
											search={+this.state.search}
											ref={ref => this.logoWrapper = ref}
										>
											<StyledLogoLink
												to="/"
												className="image logo"
												ref={ref => this.logo = ref}
												mobile={+(this.props.screen.width < 960)}
											/>
										</StyledLogoWrapperFlexItem>
										{this.state.search ? (
											<StyledLocationInputFlexItem grow={1} mobile={+mobile}>
												<SearchField
													onClose={this.closeSearch}
													onResultsChange={this.searchResultsChange}
													restaurants={this.state.restaurants}
												/>
											</StyledLocationInputFlexItem>
										) : (
											<Hidden smDown>
												<StyledLocationInputFlexItem grow={1} maxWidth={368}>
													<LocationInput getHeadTheme={this.getHeadTheme}/>
												</StyledLocationInputFlexItem>
											</Hidden>
										)}
									</Flex>
								</FlexItem>
								{this.state.search ? (
									<FlexItem>
										<IconButton
											size={mobile ? 'small' : 'medium'}
											onClick={this.toggleSearch}
											style={{color: '#fff'}}
										>
											<IconClose/>
										</IconButton>
									</FlexItem>
								) : (
									<FlexItem shrink={false}>
										<Flex spacing={2} align="center" safariFix>
											<FlexItem>
												{mobile ? (
													<IconButton size="small" onClick={this.toggleSearch}>
														{this.state.search ? <IconClose/> : <IconSearch/>}
													</IconButton>
												) : (
													<StyledDesktopSearchButton
														startIcon={<IconSearch/>}
														onClick={this.toggleSearch}
													>
														Hledat
													</StyledDesktopSearchButton>
												)}
											</FlexItem>
											<FlexItem>
												<Button
													variant="contained"
													color="secondary"
													component={Link}
													to={this.props.user.authorized ? '/panel' : '/auth/login'}
													startIcon={this.props.screen.width < 580 ? undefined :
														<LockRoundedIcon/>}
													disableElevation
												>
													{this.props.screen.width < 580 ?
														<LockRoundedIcon/> : 'Správa prodejce'}
												</Button>
											</FlexItem>
										</Flex>
									</FlexItem>
								)}
							</Flex>
						</StyledLayoutZone>
					</AppBar>
				</StyledStickyBar>
				<StyledPageWrapper search={+this.state.search} onClick={this.closeSearch}>
					{this.state.search && (
						<Search
							results={this.state.searchResults}
							alive={this.state.alive}
						/>
					)}
					<StyledContentWrapper search={+this.state.search}>
						<StyledBody>
							{this.props.screen.width < 960 &&
								<Router primary={false}>
									<MobileLocationInput path="/"/>
									<MobileLocationInput path="nearby/:address"/>
								</Router>
							}
							<Router primary={false}>
								<NotFound path="404"/>
								<Home path="/" alive={this.state.alive}/>
								<Auth path="auth/*"/>
								<Panel path="panel/*" alive={this.state.alive}/>
								<Browse path="browse/*" alive={this.state.alive}/>
								<Online path="online" alive={this.state.alive}/>
								<RecommendSeller path="doporucit-prodejce"/>
								<RestaurantListByTags path="tag/:tag" alive={this.state.alive}/>
								<CityRank path="zebricek/mesta"/>
								<Derby path="derby/:home/:away"/>
								<TermsOfUse path="podminky-uziti"/>
								<PrivacyPolicy path="ochrana-osobnich-udaju"/>
								<SeoDelivery path="rozvoz-jidel"/>
								<LaFormaggeria path="la-formaggeria"/>
								<Redirect from="/zaciname" to="/o-nas" />
								<Redirect from="/registrace" to="/o-nas" />
								<About path="o-nas"/>
								<Nearby path="nearby" alive={this.state.alive}/>
								<Nearby path="nearby/:address" alive={this.state.alive}/>
								<OrderTracking path="t/:id"/>
								<OrderTracking path="t/:id/:pageType"/>
								<BlogPostList path="blog"/>
								<PaymentsContract path="payments-contract/*"/>
								<BlogPost
									path="blog/:name"
									restaurants={this.state.restaurants}
									alive={this.state.alive}
								/>
								<Restaurant path=":restaurantId" alive={this.state.alive}/>
							</Router>
						</StyledBody>
						<StyledFooter>
							<MuiThemeProvider theme={this.getFooterTheme}>
								<LayoutZone centerVertical>
									<Footer socialLinks={social}/>
								</LayoutZone>
							</MuiThemeProvider>
						</StyledFooter>
						<StyledFooterBottom>
							<MuiThemeProvider theme={this.getFooterBottomTheme}>
								<Grid container justify="center">
									<Grid item>
										<Padding size={16}>
											<Typography variant="body2">
												S láskou{' '}
												<MaterialLink
													color="primary"
													href="https://dotykacka.cz"
													underline="always"
													target="_blank"
												>
													Dotykačka
												</MaterialLink>.
											</Typography>
										</Padding>
									</Grid>
								</Grid>
							</MuiThemeProvider>
						</StyledFooterBottom>
					</StyledContentWrapper>
				</StyledPageWrapper>
			</>
		)
	}

	componentDidMount() {
		this.fetchAlive()
		this.fetchRestaurants()
		this.fetchAliveInterval = setInterval(this.fetchAlive, 5 * 60000)
		window.addEventListener('scroll', this.onScroll)
	}

	componentWillUnmount() {
		clearInterval(this.fetchAliveInterval)
		window.removeEventListener('scroll', this.onScroll)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.match.match && !this.props.match.match) {
			this.setState({searchValue: null})
		}

		if (prevProps.screen.width >= 960 && this.props.screen.width < 960) {
			this.layoutZoneSlider.style.padding = `8px`
			this.logo.style.height = `48px`
			this.logo.style.width = `142px`
			this.logoWrapper.style.width = `142px`
		}
		if (prevProps.screen.width < 960 && this.props.screen.width >= 960) {
			this.layoutZoneSlider.style.padding = `16px`
			this.logo.style.height = `64px`
			this.logo.style.width = `172px`
			this.logoWrapper.style.width = `172px`
		}
	}

	fetchRestaurants() {
		this.props.API.get('/default.json', {S3: true})
		.then(result => {
			const restaurants = result.restaurants.filter(x => x.disabled !== true)
			this.setState({restaurants})
		})
		.catch(() => this.props.enqueueSnackbar(
			'Nepodařilo se načíst seznam restaurací. Zkuste to prosím později.',
			{variant: 'error'}
		))
	}

	toggleSearch() {
		this.setState(prevState => ({
			search: !prevState.search,
			searchResults: prevState.search
				? []
				: prevState.searchResults
		}))
	}

	closeSearch() {
		this.setState({search: false, searchResults: []})
	}

	searchResultsChange(searchResults) {
		this.setState({searchResults})
	}

	getHeadTheme = memo(theme => {
		return createMuiTheme({
			...theme,
			palette: {
				primary: {
					main: '#fff'
				},
				secondary: {
					...theme.palette.secondary
				},
				type: 'dark'
			}
		})
	})

	fetchAlive() {
		this.props.API.get('/alive.json', {
			S3: true
		})
		.then(result => {
			this.setState({alive: result})
		})
		.catch(() => {
		})
	}

	getFooterTheme = memo(theme => {
		return createMuiTheme({
			...theme,
			palette: {
				primary: {
					main: '#000'
				},
				secondary: {
					...theme.palette.secondary
				},
				type: 'dark'
			}
		})
	})

	getFooterBottomTheme = memo(theme => {
		return createMuiTheme({
			...theme,
			palette: {
				primary: {
					main: '#8BC34A'
				},
				secondary: {
					...theme.palette.secondary
				},
				type: 'dark'
			}
		})
	})

	onScroll() {
		if (this.props.screen.width < 960) return

		const targetScrollPosition = 32
		let progress
		const scrollProgressPx = this.getScrollTop()
		progress = Math.max(Math.min(scrollProgressPx / targetScrollPosition, 1), 0)

		this.layoutZoneSlider.style.padding = `${16 - 8 * progress}px 16px`
		this.logo.style.height = `${64 - 12 * progress}px`
		this.logo.style.width = `${172 - 30 * progress}px`
		this.logoWrapper.style.width = `${172 - 30 * progress}px`
	}

	getScrollTop() {
		const doc = document.documentElement
		return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
	}
}

const StyledFooterHeadTypography = styled(Typography)`
	color: #5a2d03;
`

const StyledFooterLink = styled(MaterialLink)`
	color: #743904;
`

class Footer extends React.PureComponent {
	render() {
		return (
			<Padding size={32} vertical>
				<Flex spacing={8} justify="space-between">
					<FlexItem>
						<Padding size={16} vertical edge="top">
							<StyledFooterHeadTypography variant="h5">
								Dokumenty
							</StyledFooterHeadTypography>
						</Padding>
						<Typography variant="body1">
							<StyledFooterLink
								component={Link}
								to={'/podminky-uziti'}>
								Podmínky užití
							</StyledFooterLink>
						</Typography>
						<Typography variant="body1">
							<StyledFooterLink
								component={Link}
								to={'/ochrana-osobnich-udaju'}>
								Ochrana osobních údajů
							</StyledFooterLink>
						</Typography>
						<Typography variant="body1">
							<StyledFooterLink
								href="https://manual.vyzvednisi.cz"
								target="_blank">
								Návod k použití
							</StyledFooterLink>
						</Typography>
					</FlexItem>
					<FlexItem>
						<Padding size={16} vertical edge="top">
							<StyledFooterHeadTypography variant="h5">
								Navigace
							</StyledFooterHeadTypography>
						</Padding>
						<Typography variant="body1">
							<StyledFooterLink
								component={Link}
								to="/o-nas"
							>
								O nás
							</StyledFooterLink>
						</Typography>
						<Typography variant="body1">
							<StyledFooterLink
								component={Link}
								to={'/blog'}>
								Blog
							</StyledFooterLink>
						</Typography>
						<Typography variant="body1">
							<StyledFooterLink
								component={Link}
								to={'/rozvoz-jidel'}>
								Rozvoz jídel
							</StyledFooterLink>
						</Typography>
						<Typography variant="body1">
							<StyledFooterLink
								component={Link}
								to={'/online'}>
								Prodejci online
							</StyledFooterLink>
						</Typography>
						<Typography variant="body1">
							<StyledFooterLink
								component={Link}
								to={'/zebricek/mesta'}>
								Žebříček měst
							</StyledFooterLink>
						</Typography>
					</FlexItem>
					<FlexItem>
						<Padding size={16} vertical edge="top">
							<StyledFooterHeadTypography variant="h5">
								Sociální sítě
							</StyledFooterHeadTypography>
						</Padding>
						<Flex spacing={1}>
							<FlexItem>
								<MaterialLink href={this.props.socialLinks[0].profile_url} target="_blank">
									<IconFacebook/>
								</MaterialLink>
							</FlexItem>
							<FlexItem>
								<MaterialLink href={this.props.socialLinks[1].profile_url} target="_blank">
									<IconInstagram/>
								</MaterialLink>
							</FlexItem>
							<FlexItem>
								<MaterialLink href={this.props.socialLinks[2].profile_url} target="_blank">
									<IconTwitter/>
								</MaterialLink>
							</FlexItem>
							<FlexItem>
								<MaterialLink href={this.props.socialLinks[3].profile_url} target="_blank">
									<IconLinkedIn/>
								</MaterialLink>
							</FlexItem>
						</Flex>
					</FlexItem>
					<FlexItem>
						<Padding size={16} vertical edge="top">
							<StyledFooterHeadTypography variant="h5">
								Kontakt
							</StyledFooterHeadTypography>
						</Padding>
						<Typography variant="body1">
							<StyledFooterLink
								href="mailto:info@vyzvednisi.cz"
								target="_blank"
							>
								info@vyzvednisi.cz
							</StyledFooterLink>
						</Typography>
						<Typography variant="body1">
							<StyledFooterLink
								href="tel:+420533312166"
								target="_blank"
							>
								533 312 166
							</StyledFooterLink>
						</Typography>
					</FlexItem>
				</Flex>
			</Padding>
		)
	}
}

const StyledIconHomeCity = styled(IconHomeCity)`
	position: relative;
	bottom: 8px;
	left: 4px;
	color: ${props => props.mobile ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, .48)'};
`

@withLocation
class LocationInput extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		// noinspection JSUnresolvedFunction
		this.searchService = HERE.getSearchService()

		this.state = {
			suggestions: [],
			inputValue: this.props.location.formatted || ''
		}
	}

	render() {
		const mobile = this.props.mobile
		return (
			<MuiThemeProvider theme={this.getSuggestionsTheme}>
				<Autocomplete
					freeSolo autoHighlight
					filterOptions={x => x}
					options={(this.state.suggestions || [])}
					getOptionLabel={option => option.formatted || ''}
					value={this.props.location.formatted || ''}
					onChange={this.suggestionSelected}
					inputValue={this.state.inputValue || ''}
					renderInput={props => (
						<MuiThemeProvider theme={this.props.getHeadTheme}>
							<StyledLocationTextField
								{...props}
								fullWidth
								mobile={+!!mobile}
								size="small"
								color="primary"
								variant="filled"
								label="Moje adresa"
								placeholder="např. Křenová 69, Brno"
								onChange={this.searchValueChange}
								InputProps={{
									...props.InputProps,
									startAdornment: (
										<InputAdornment position="start">
											<StyledIconHomeCity mobile={+!!mobile} fontSize="small"/>
										</InputAdornment>
									)
								}}
								onFocus={this.focus}
								onBlur={this.blur}
							/>
						</MuiThemeProvider>
					)}
				/>
			</MuiThemeProvider>
		)
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.formatted !== prevProps.location.formatted) {
			this.setState({inputValue: this.props.location.formatted})
		}
	}

	searchValueChange(e) {
		const input = e.target.value
		this.setState({inputValue: input})
		if (!input) return this.setState({suggestions: store.get('userLocationHistory') || []})

		const at = (this.props.location.latitude && this.props.location.longitude)
			? `${this.props.location.latitude},${this.props.location.longitude}`
			: `${geo.cz.latitude},${geo.cz.longitude}`

		this.searchService.autosuggest({
			q: input, at,
			in: 'countryCode:CZE'
		}, result => {
			this.setState({
				suggestions: result.items.filter(x => x.resultType === 'houseNumber'
					|| (x.resultType === 'locality' && x.localityType === 'city')
					|| x.resultType === 'street'
				).map(this.mapResults)
			})
		}, err => {
			console.warn('Autosuggest service failure. See the attached error:')
			console.warn(err)
		})
	}

	suggestionSelected(e, location) {
		if (!location) {
			return this.setState({
				inputValue: '',
				suggestions: store.get('userLocationHistory') || []
			})
		}

		if (typeof location === 'string') return

		this.setState({inputValue: location.formatted})
		this.props.location.provideLocation(location)
	}

	mapResults(result) {
		const location = {
			formatted: result.title,
			street: null,
			city: result.address.city || null,
			zip: result.address.postalCode || null,
			latitude: null,
			longitude: null
		}

		if (result.position) {
			location.latitude = parseFloat(Big(result.position.lat).toFixed(3))
			location.longitude = parseFloat(Big(result.position.lng).toFixed(3))
		}

		if (result.address.street) {
			location.street = result.address.houseNumber
				? `${result.address.street} ${result.address.houseNumber}`
				: result.address.street
		}

		return location
	}

	blur() {
		if (this.state.suggestions.length === 1 && this.state.inputValue !== '') {
			this.setState({suggestions: []})
			return this.suggestionSelected(null, {...this.state.suggestions[0]})
		}

		this.setState({
			suggestions: [],
			inputValue: this.props.location.formatted
		})
	}

	focus() {
		this.setState({
			inputValue: '',
			suggestions: store.get('userLocationHistory') || []
		})
	}

	getSuggestionsTheme = memo(theme => {
		return createMuiTheme({
			...theme,
			palette: {
				primary: {
					main: '#fff'
				},
				secondary: {
					...theme.palette.secondary
				},
				type: 'light'
			}
		})
	})
}

const StyledMobileAddressBar = styled(Paper)`
	padding: 8px 16px;
	border-radius: 0;
	z-index: 1;
`

class MobileLocationInput extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)
	}

	render() {
		return (
			<StyledMobileAddressBar>
				<LocationInput
					mobile
					getHeadTheme={baseTheme}
				/>
			</StyledMobileAddressBar>
		)
	}
}

const StyledSearchTextField = styled(TextField)`
	> div:last-child {
		background-color: #fff;
		border-radius: 2px;
		box-shadow: 0 0 16px 0 rgba(0, 0, 0, .08);
		
		&::before, &::after {
			content: initial;
		}
	}
`

const fuseIndexOptions = {
	includeScore: true,
	keys: ['name', 'description']
}

class SearchField extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.state = {
			value: ''
		}
	}

	render() {
		return (
			<StyledSearchTextField
				fullWidth autoFocus
				variant="filled"
				size="small"
				label="Vyhledat"
				placeholder="např. Červená pivnice"
				onKeyDown={this.keyDown}
				value={this.state.value}
				onChange={this.change}
			/>
		)
	}

	componentDidMount() {
		this.index = new Fuse(this.props.restaurants, fuseIndexOptions)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.restaurants !== this.props.restaurants) {
			this.index = new Fuse(this.props.restaurants, fuseIndexOptions)
		}
	}

	change(e) {
		const value = e.target.value
		this.setState({value})

		const results = this.index.search(value)
		.filter(x => x.score < 0.5).slice(0, 24)

		this.props.onResultsChange(results)
	}

	keyDown(e) {
		if (e.keyCode === 27) {
			this.props.onClose()
		}
	}
}

const StyledSearchContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	z-index: 11;
	background-color: ${props => props.empty ? 'transparent' : '#EEEEEE'};
`

class Search extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)
	}

	render() {
		return (
			<StyledSearchContainer empty={+!this.props.results.length}>
				<LayoutZone verticalPadding>
					<Grid container spacing={2}>
						{this.props.results.map(({item, index}) => (
							<RestaurantCardCompact
								key={item.id}
								restaurant={item}
								alive={(this.props.alive || {})[item.id]}
								index={index}/>
						))}
					</Grid>
				</LayoutZone>
			</StyledSearchContainer>
		)
	}
}