import {LayoutZone} from '../../../style/lib'
import {Grid, Link as MaterialLink, Paper, Typography} from '@material-ui/core'
import {withSnackbar} from 'notistack'
import {Link} from '@reach/router'
import {MetaTags} from 'react-meta-tags'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Markdown from 'markdown-to-jsx'

const StyledParagraph = styled(Typography)`
	margin-top: 0.5rem;
	margin-bottom: 1.1rem;
`
const StyledH1 = styled(Typography)`
	margin-top: 1.1rem;
	margin-bottom: 0.8rem;
`

const StyledH2 = styled(Typography)`
	margin-top: 2rem;
	margin-bottom: 1.43rem;
`
@withAPI @withSnackbar
export default class BlogPostList extends React.PureComponent {

	constructor(props) {
		super(props)
		this.state = {
			blogPosts: null
		}
	}

	render() {
		const title = `Vyzvednisi.cz blog | ${window.WebClaim}`
		const postList = this.state.blogPosts
		return (
			<>
				<MetaTags>
					<title>{title}</title>
					<meta name="title" content={title}/>
					<meta name="description"
					      content="Seznam příspěvků od Vyzvednisi.cz a partnerů"/>
					<meta name="keywords"
					      content="blog, přispěvky, partner vyzvednisi.cz, jak nezavřit"/>
				</MetaTags>
				<LayoutZone>
					<Container maxWidth="md">
						<Box my={4}>
							<Grid container spacing={4} justify="center">
								<Grid item xs={12}>
									<Typography variant="h4" component="h1">Blog</Typography>
								</Grid>
								{postList && postList.map((post) => (
									<Grid item xs={12}>
										<Paper>
											<Box p={2}>
												<Typography variant="h5" component="h2" gutterBottom>
													<MaterialLink
														component={Link}
														to={`/blog/${post.name}`}
													>
														{post.title}
													</MaterialLink>
												</Typography>
												<Typography gutterBottom>
													<Markdown>{post.perex}</Markdown>
												</Typography>
												<Box textAlign="right" component={Typography}>
													<MaterialLink
														component={Link}
														to={`/blog/${post.name}`}
													>
														Přečíst článek
													</MaterialLink>
												</Box>
											</Box>
										</Paper>
									</Grid>
								))}
							</Grid>
						</Box>
					</Container>
				</LayoutZone>
			</>
		)
	}

	componentDidMount() {
		this.fetchBlogPosts()
	}

	fetchBlogPosts() {
		this.props.API.get('/blog/list.json', {
			S3: true
		})
		.then(result => {
			console.log(result)
			this.setState({
				blogPosts: result
			})
		})
		.catch(() => this.props.enqueueSnackbar(
			'Nepodařilo se načíst seznam příspěvků na blogu. Zkuste to prosím později.',
			{variant: 'error'}
		))
	}
}
