import {withSnackbar} from 'notistack'
import {LayoutZoneWrapper} from '../../style/lib'
import {Flex} from '../Flex'
import {Box, Grid, Typography} from '@material-ui/core'

const StyledLayoutZone = styled(LayoutZoneWrapper)`
  display: flex;
  flex-direction: column;

  > div, > div > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    > div:last-child {
      display: flex;
      flex-direction: column;
    }
  }
`

const StyledBanner = styled.div`
  width: 100%;
  min-height: 1080px;
  max-width: 1920px;
  //background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: ${props => props.screenwidth < 800 ? props.screenwidth + 800 : 1920}px;
  display: flex;
  margin: auto;

  > div {
    margin: 0;
  }

  background-image: url('/asset/end-banner.jpg');
`

const StyledBannerContent = styled(Grid)`
  padding-top: ${props => props.screenwidth < 800 ? ((props.screenwidth + 400) / 1400) * 600 : 600}px;
  padding-left: 16px;
  padding-right: 16px;

  > div {
    max-width: 800px;
  }
  > div p {
    hyphens: none;
  }
`

const StyledCta = styled.a`
  @import url('https://fonts.cdnfonts.com/css/gotham');
  padding: 15px 42px;
  border-radius: 26px;
  border: solid 2px #0DB02B;
  display: inline;
  font-size: 17px;
  text-decoration: none;
  box-shadow: none;
  transition: all 0.2s linear;
  color: #ffffff;
  background-color: #0DB02B;
  font-family: "Gotham Light", sans-serif;
  font-weight: 400;
  box-sizing: border-box;

  :hover {
    color: #0DB02B;
    background-color: #ffffff;
  }
`

@withSnackbar @withScreen
export default class End extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)
	}

	render() {
		return (
			<StyledLayoutZone>
				<StyledBanner className="image" screenwidth={this.props.screen.width}>
					<StyledBannerContent
						container
						spacing={2}
						direction="column"
						alignItems="center"
						justify="flex-start"
						screenwidth={this.props.screen.width}
					>
						<Grid item>
							<Typography variant="body1">
								Když v době covidu kvůli karanténě musela zavřít většina provozoven, náš tým neváhal a
								během
								relativně krátké doby přišel s nástrojem, který mnohým podnikatelům pomohl přežít krizi.
								Objednávkový systém Vyzvednisi se zrodil po 14 dnech (a nocích) intenzivní práce našich
								vývojářů, za což jim patří obrovský dík. Kromě svého času a energie, dali do projektu
								především své ❤️. O to těžší tak bylo rozhodování o jeho budoucnosti.
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body1">
								Aplikace však splnila svůj účel a přišel čas se rozloučit. Správa celého systému, jeho
								aktualizace a doplňování nových funkcí totiž vyžaduje zdroje jak lidské, tak finanční.
								Rozhodli jsme se proto k 1. 6. 2023 provoz objednávkového systému Vyzvednisi ukončit.
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body1">
								Na rozloučenou pro vás máme tip na vhodnou alternativu, která nabízí pokročilé možnosti
								bezkontaktního objednávání, včetně služby na klíč.
							</Typography>
						</Grid>

						<Grid item>
							<Box py={3}>
								<StyledCta
									href="https://dotykacka.cz/choice">
									Zajímá mě to
								</StyledCta>
							</Box>
						</Grid>

						<Grid item>
							<Typography variant="body1">
								Děkujeme za vaši přízeň a přejeme vám hodně spokojených zákazníků.
							</Typography>
						</Grid>
					</StyledBannerContent>
				</StyledBanner>
				<Flex spacing={2} align="stretch" direction="column">

				</Flex>
			</StyledLayoutZone>
		)
	}
}