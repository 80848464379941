import {LayoutZone, Padding} from '../../../style/lib'
import {Grid} from '@material-ui/core'
import {withSnackbar} from 'notistack'
import SkeletonGrid from '../../SkeletonGrid'
import RestaurantGrid from '../../RestaurantGrid'
import {MetaTags} from 'react-meta-tags'

const StyledBanner = styled.div`
	width: 100%;
	height: 240px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	background-image: url('${S3_URI}/image/partner/banner/la-formaggeria?v=2');
	
	> div {
		margin: ${props => props.screenwidth < 580 ? 16 : 32}px 0;
	}
`

@withAPI @withSnackbar @withScreen
export default class RestaurantListByTags extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.state = {
			restaurants: null
		}
	}

	render() {
		const title = `Prodejny La Formaggeria`

		return (
			<>
				<MetaTags>
					<title>{title}</title>
					<meta name="description" content={`Nabídky prodejen La Formaggeria`}/>
				</MetaTags>
				<LayoutZone>
					<StyledBanner className="image" screenwidth={this.props.screen.width}/>
					<Padding vertical size={32}>
						<Grid container spacing={4} justify="center" direction="column">
							<Grid item xs={12} style={{maxWidth: '100%'}}>
								{this.state.restaurants !== null
									? <RestaurantGrid restaurants={this.state.restaurants} alive={this.props.alive}/>
									: <SkeletonGrid items={24}/>
								}
							</Grid>
						</Grid>
					</Padding>
				</LayoutZone>
			</>
		)
	}

	componentDidMount() {
		this.fetchRestaurants()
	}

	fetchRestaurants() {
		this.props.API.get(`/tags/-la-formaggeria.json`, {S3: true})
		.then(result => {
			this.setState({
				restaurants: result.restaurants.filter(x => x.disabled !== true)
			})
		})
		.catch(() => this.props.enqueueSnackbar(
			'Nepodařilo se načíst seznam prodejců. Zkuste to prosím později.',
			{variant: 'error'}
		))
	}
}