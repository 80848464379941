import {withSnackbar} from 'notistack'
import {Grid, Typography} from '@material-ui/core'
import {MetaTags} from 'react-meta-tags'
import {LayoutZone, Padding} from '../../../style/lib'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import {RestaurantCardCompact} from '../../RestaurantCardCompact'

@withAPI @withSnackbar
export default class Derby extends React.PureComponent {
	constructor() {
		super()
		this.state = {
			home: null,
			away: null
		}
	}

	render() {
		const home = this.state.home
		const away = this.state.away
		var derbyName = ''
		if (home && away) {
			derbyName = `${home.name} vs. ${away.name}`
		}
		const title = `Derby | ${window.WebClaim}`
		return <>
			<MetaTags>
				<title>{title}</title>
				<meta name="title" content={title}/>
				<meta name="description"
				      content='Přehled restaurací a obchodů nabízejících rozvoz jídel. Registrace pro restaurace a obchody je zdarma.'/>
				<meta name="keywords"
				      content='rozvoz jídel, rozvoz jídla, rozvoz jídla praha, rozvoz jídla brno, rozvoz jídla plzeň'/>
			</MetaTags>
			<LayoutZone>
				<Padding vertical size={32}>
					<Grid container spacing={2} justify="center" direction="row">
						{home && (<Grid item xs={4}>
								<Card>
									<CardActionArea>
										<CardMedia
											component="img"
											src={`/asset/city/${this.props.home.replace(/[0-9]*$/, '').replace(/-*$/, '')}.jpg`}
											title={home.name}
											height={223}
										/>
										<CardContent>
											<Grid container justify="space-between" alignItems="flex-end">
												<Grid item>
													<Typography variant="h5" component="h3">
														{home.name}
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant="h5" component="p">
														{this.sellers(home.items.length)}
													</Typography>
												</Grid>
											</Grid>
										</CardContent>
									</CardActionArea>
								</Card>
							</Grid>
						)}
						{away && (<Grid item xs={4}>
								<Card>
									<CardActionArea>
										<CardMedia
											component="img"
											src={`/asset/city/${this.props.away.replace(/[0-9]*$/, '').replace(/-*$/, '')}.jpg`}
											title={away.name}
											height={223}
										/>
										<CardContent>
											<Grid container justify="space-between" alignItems="flex-end">
												<Grid item>
													<Typography variant="h5" component="h3">
														{away.name}
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant="h5" component="p">
														{this.sellers(away.items.length)}
													</Typography>
												</Grid>
											</Grid>
										</CardContent>
									</CardActionArea>
								</Card>
							</Grid>
						)}
						<Grid item xs={6}>
							<Grid container>
								{home && home.items.map((restaurant) => {
									<RestaurantCardCompact index={restaurant.id} restaurant={restaurant}/>
								})}
							</Grid>
						</Grid>
					</Grid>
				</Padding>
			</LayoutZone>
		</>
	}

	componentDidMount() {
		this.fetchHome()
		this.fetchAway()
	}

	fetchHome() {
		this.props.API.get(`/cities/${this.props.home}.json`, {
			S3: true
		})
		.then(result => {
			console.log(result)
			this.setState({
				home: result
			})
		})
		.catch(() => this.props.enqueueSnackbar(
			'Nepodařilo se načíst tým domácích. Zkuste to prosím později.',
			{variant: 'error'}
		))
	}

	fetchAway() {
		this.props.API.get(`/cities/${this.props.away}.json`, {
			S3: true
		})
		.then(result => {
			console.log(result)
			this.setState({
				away: result
			})
		})
		.catch(() => this.props.enqueueSnackbar(
			'Nepodařilo se načíst tým hostů. Zkuste to prosím později.',
			{variant: 'error'}
		))
	}

	sellers(number) {
		if (number === 1) {
			return `${number} prodejce`
		}
		if (number >= 2 && number <= 4) {
			return `${number} prodejci`
		}
		return `${number} prodejců`
	}
}