import MutationSummary from 'mutation-summary'

@withScreen
export class Anchor extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.scrolledIn = null
		this.viewedIn = null
	}

	render() {
		const proxiedProps = {...this.props}
		delete proxiedProps.id
		delete proxiedProps.onScrollIn
		delete proxiedProps.onScrollOut
		delete proxiedProps.onViewIn
		delete proxiedProps.onViewOut
		delete proxiedProps.top
		delete proxiedProps.topElements

		return (
			<this.props.component
				{...proxiedProps}
				ref={ref => this.anchor = ref}
			>
				{this.props.children}
			</this.props.component>
		)
	}

	componentDidMount() {
		this.getAnchorState()
		window.addEventListener('scroll', this.getAnchorState)
		window.addEventListener('resize', this.getAnchorState)
		this.observer = new MutationSummary({
			rootNode: this.anchor,
			callback: this.getAnchorState,
			queries: [{all: true}]
		})

		anchors[this.props.id] = this
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.getAnchorState)
		window.removeEventListener('resize', this.getAnchorState)
		this.observer.disconnect()

		delete anchors[this.props.id]
	}

	scrollIn({addPixels = 0, behavior = 'smooth'}) {
		const doc = document.documentElement
		const scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
		const topOffset = this.getTopOffset(this.props.top, this.props.topElements)
		const anchorTop = this.anchor.getBoundingClientRect().top
		window.scrollTo({
			top: scrollTop + anchorTop - topOffset + addPixels,
			behavior
		})
	}

	getAnchorState() {
		const topOffset = this.getTopOffset(this.props.top, this.props.topElements)
		const anchorRect = this.anchor.getBoundingClientRect()

		;(() => {
			if (anchorRect.top <= this.props.screen.height && anchorRect.bottom >= topOffset) {
				if (this.viewedIn === true) return
				this.viewedIn = true
				setTimeout(() => this.props.onViewIn(), 0)
				return
			}

			if (this.viewedIn === false) return
			this.viewedIn = false
			this.props.onViewOut()
		})()

		;(() => {
			if (anchorRect.top <= topOffset && anchorRect.bottom > topOffset) {
				if (this.scrolledIn === true) return
				this.scrolledIn = true
				setTimeout(() => this.props.onScrollIn(), 0)
				return
			}

			if (this.scrolledIn === false) return
			this.scrolledIn = false
			this.props.onScrollOut()
		})()
	}

	getTopOffset(top, elements) {
		return elements.reduce((total, element) => {
			return total + element.offsetHeight
		}, top)
	}

	static propTypes = {
		id: PropTypes.string.isRequired,
		top: PropTypes.number,
		topElements: PropTypes.array,
		component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
		onScrollIn: PropTypes.func,
		onScrollOut: PropTypes.func,
		onViewIn: PropTypes.func,
		onViewOut: PropTypes.func
	}

	static defaultProps = {
		component: 'div',
		top: 0,
		topElements: [],
		onScrollIn: Function.prototype,
		onScrollOut: Function.prototype,
		onViewIn: Function.prototype,
		onViewOut: Function.prototype
	}
}

const anchors = {}

export function goToAnchor(id, options = {}) {
	anchors[id].scrollIn(options)
}