import {StylesProvider} from '@material-ui/core/styles'
import ReactDOM from 'react-dom'
import {SnackbarProvider} from 'notistack'
import geolocator from 'geolocator'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

/* Import globals */
import './globals'

/* Material-UI theme */
import {MuiThemeProvider} from '@material-ui/core/styles'
import theme from './module/theme'

import App from './component/container/App'
import {isRedirect} from '@reach/router'

const snackbarProviderProps = {
	variant: 'info',
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'right'
	}
}

geolocator.config({
	language: 'cs',
	google: {
		version: '3',
		key: window.GOOGLE_MAP_API_KEY
	}
})

let ErrorBoundary = React.Fragment
if (BUGSNAG_KEY) {
	const bugsnagClient = bugsnag({
		apiKey: BUGSNAG_KEY,
		beforeSend: report => {
			if (isRedirect(report.originalError)) {
				report.ignore()
			}
		}
	})
	bugsnagClient.use(bugsnagReact, React)
	ErrorBoundary = bugsnagClient.getPlugin('react')
}

ReactDOM.render(
	<ErrorBoundary>
		<StylesProvider injectFirst>
			<MuiThemeProvider theme={theme}>
				<SnackbarProvider {...snackbarProviderProps}>
					<App/>
				</SnackbarProvider>
			</MuiThemeProvider>
		</StylesProvider>
	</ErrorBoundary>,
	document.getElementById('root')
)