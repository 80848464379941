import {LayoutZone, Padding} from '../../style/lib'
import {Grid, Typography} from '@material-ui/core'
import {withSnackbar} from 'notistack'
import SkeletonGrid from '../SkeletonGrid'
import RestaurantGrid from '../RestaurantGrid'

@withAPI @withSnackbar
export default class Browse extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.state = {
			restaurants: null
		}
	}

	render() {
		return (
			<LayoutZone>
				<Padding vertical size={32}>
					<Grid container spacing={4} justify="center" direction="column">
						<Grid item xs={12}>
							<Typography variant="h5">Kompletní nabídka {!!this.state.restaurants && (
								`(${this.state.restaurants.length} prodejců)`
							)}</Typography>
						</Grid>
						<Grid item xs={12} style={{maxWidth: '100%'}}>
							{this.state.restaurants !== null
								? <RestaurantGrid
									restaurants={this.state.restaurants}
									alive={this.props.alive}/>
								: <SkeletonGrid items={24}/>
							}
						</Grid>
					</Grid>
				</Padding>
			</LayoutZone>
		)
	}

	componentDidMount() {
		this.fetchRestaurants()
	}

	fetchRestaurants() {
		this.props.API.get('/default.json', {S3: true})
		.then(result => {
			this.setState({
				restaurants: result.restaurants.filter(x => x.disabled !== true)
			})
		})
		.catch(() => this.props.enqueueSnackbar(
			'Nepodařilo se načíst seznam prodejců. Zkuste to prosím později.',
			{variant: 'error'}
		))
	}
}