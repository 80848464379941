import {Box, Button, Card, Grid, Typography} from '@material-ui/core'
import {Link} from '@reach/router'
import {Flex} from './Flex'

const StyledBannerLink = styled(Link)`
	text-decoration: none;
`

const StyledCard = styled(Card)`
	border: none;
`

export class RecommendSellerBanner extends React.PureComponent {
	render() {
		return <>
			<StyledBannerLink to="/doporucit-prodejce">
				<StyledCard variant="outlined">
					<Box px={2} py={3} bgcolor="secondary.main">
						<Flex spacing={2} justify="space-between" safariFix>
							<Grid item>
								<Typography variant="h6" component="h2">
									Chybí vaše oblíbená restaurace nebo obchod?
								</Typography>
							</Grid>
							<Grid item>
								<Button
									color="primary"
									variant="contained"
									disableElevation
									disableRipple
									disableFocusRipple
									disableTouchRipple
								>
									Doporučit prodejce
								</Button>
							</Grid>
						</Flex>
					</Box>
				</StyledCard>
			</StyledBannerLink>
		</>
	}
}