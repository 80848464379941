import {locationContext} from '../../context/location'
import geolocator from 'geolocator'
import {Big} from 'big.js'
import store from 'store'
import memo from 'memoize-one'
import {HERE} from '../../module/HERE'

export default class LocationProvider extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		// noinspection JSUnresolvedFunction
		this.searchService = HERE.getSearchService()

		const storedLocation = store.get('userLocation') || {}

		this.state = {
			locationAccuracy: store.get('userLocationAccuracy') || 0, // 0|1|2|3 (none|IP|geolocated|user-provided)
			location: {
				formatted: storedLocation.formatted || null,
				street: storedLocation.street || null,
				city: storedLocation.city || null,
				zip: storedLocation.zip || null,
				latitude: storedLocation.latitude || null,
				longitude: storedLocation.longitude || null
			}
		}
	}

	render() {
		return (
			<locationContext.Provider
				value={this.getContextValue(
					this.state.location,
					this.state.locationAccuracy
				)}
			>
				{this.props.children}
			</locationContext.Provider>
		)
	}

	componentDidMount() {
		this.locate()
	}

	getContextValue = memo((location, locationAccuracy) => ({
		accuracy: locationAccuracy,
		...location,
		provideLocation: this.provideLocation
	}))

	locate() {
		let geolocated

		if (this.state.locationAccuracy < 2) {
			geolocator.locate({
				fallbackToIP: false,
				enableHighAccuracy: false
			}, (err, location) => {
				if (!err) {
					geolocated = true
				}

				// noinspection JSIgnoredPromiseFromCall
				this.located(err, location, 2)
			})
		}

		if (this.state.locationAccuracy < 1) {
			geolocator.locateByIP({}, (err, location) => {
				geolocated || this.located(err, location, 1)
			})
		}
	}

	async located(err, location, accuracy) {
		if (err) {
			if (err.message === 'User denied Geolocation') return this.geolocationDenied()
			console.warn('Unable to locate. See the attached error:')
			console.warn(err)
			return
		}

		if (!location.coords) {
			console.warn('Unable to locate. See the attached location response:')
			console.warn(location)
			return
		}

		const address = await this.getAddressFromLocation(location.coords).catch(Function.prototype)

		if (!address) {
			console.warn('Unable to get an address from provided coordinates.')
			return
		}

		const latitude = parseFloat(Big(location.coords.latitude).toFixed(3))
		const longitude = parseFloat(Big(location.coords.longitude).toFixed(3))
		const locationData = {latitude, longitude, ...address}

		this.setState({
			locationAccuracy: accuracy,
			location: locationData
		}, () => {
			store.set('userLocation', locationData)
			store.set('userLocationAccuracy', accuracy)
		})
	}

	geolocationDenied() {
		// either denied or unsupported geolocation
	}

	getAddressFromLocation(coords) {
		return new Promise((resolve, reject) => {
			this.searchService.reverseGeocode({
				at: `${coords.latitude},${coords.longitude}`
			}, result => {
				if (!result.items || !result.items.length) {
					return reject('No result.')
				}

				const address = {
					formatted: result.items[0].title,
					street: null,
					city: result.items[0].address.city || null,
					zip: result.items[0].address.postalCode || null
				}

				if (result.items[0].address.street) {
					address.street = result.items[0].address.houseNumber
						? `${result.items[0].address.street} ${result.items[0].address.houseNumber}`
						: result.items[0].address.street
				}

				resolve(address)
			}, reject)
		})
	}

	provideLocation(location) {
		this.setState({
			locationAccuracy: 3,
			location
		}, () => {
			store.set('userLocation', location)
			store.set('userLocationAccuracy', 3)

			const history = store.get('userLocationHistory') || []
			store.set('userLocationHistory', [
				location,
				...history.filter(x => x.formatted !== location.formatted).slice(0, 4)
			])
		})
	}
}