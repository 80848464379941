import {LayoutZone, Padding} from '../../style/lib'
import {Grid, Typography} from '@material-ui/core'
import {withSnackbar} from 'notistack'
import SkeletonGrid from '../SkeletonGrid'
import RestaurantGrid from '../RestaurantGrid'
import {MetaTags} from 'react-meta-tags'

@withAPI @withSnackbar
export default class RestaurantListByTags extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.state = {
			restaurants: null
		}
	}

	render() {
		const title = `Nabídky se štítkem ${this.props.tag ? this.props.tag : ''} | ${window.WebName}`

		return (
			<>
				<MetaTags>
					<title>{title}</title>
					<meta name="description" content={`Nabídky restaurací a prodjeců ${this.props.tag ? this.props.tag : ''}`}/>
				</MetaTags>
				<LayoutZone>
					<Padding vertical size={32}>
						<Grid container spacing={4} justify="center" direction="column">
							<Grid item xs={12}>
								<Typography variant="h5">
									{this.props.tag !== null
										? `Nabídky se štítkem ${this.props.tag}`
										: 'Všechny nabídky'}
								</Typography>
							</Grid>
							<Grid item xs={12} style={{maxWidth: '100%'}}>
								{this.state.restaurants !== null
									? <RestaurantGrid restaurants={this.state.restaurants} alive={this.props.alive}/>
									: <SkeletonGrid items={24}/>
								}
							</Grid>
						</Grid>
					</Padding>
				</LayoutZone>
			</>
		)
	}

	componentDidMount() {
		this.fetchRestaurants()
	}

	fetchRestaurants() {
		this.props.API.get(`/tags/${this.props.tag}.json`, {S3: true})
		.then(result => {
			this.setState({
				restaurants: result.restaurants.filter(x => x.disabled !== true)
			})
		})
		.catch(() => this.props.enqueueSnackbar(
			'Nepodařilo se načíst seznam restaurací. Zkuste to prosím později.',
			{variant: 'error'}
		))
	}
}