import {dialogContext} from '../context/dialog'

export function withDialog(ComponentType) {
	return React.forwardRef((props, ref) => (
		<dialogContext.Consumer>
			{context => (
				<ComponentType
					{...props}
					ref={ref}
					createDialog={context.createDialog}
				/>
			)}
		</dialogContext.Consumer>
	))
}