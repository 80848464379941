import {screenContext} from '../../context/screen'

export default class ScreenProvider extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.state = {
			screen: {
				width: window.innerWidth,
				height: window.innerHeight
			}
		}
	}

	render() {
		return (
			<screenContext.Provider value={this.state.screen}>
				{this.props.children}
			</screenContext.Provider>
		)
	}

	componentDidMount() {
		window.addEventListener('resize', this.onResize)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize)
	}

	onResize(e) {
		this.setState(prevState => ({
			screen: {
				...prevState.screen,
				width: e.target.innerWidth,
				height: e.target.innerHeight
			}
		}))
	}
}