import {userContext} from '../context/user'

export function withUser(ComponentType) {
	return React.forwardRef((props, ref) => (
		<userContext.Consumer>
			{context => (
				<ComponentType
					{...props}
					ref={ref}
					user={context}
				/>
			)}
		</userContext.Consumer>
	))
}