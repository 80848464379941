import {Dialog, DialogActions, Button, DialogContent, DialogTitle, Typography} from '@material-ui/core'
import {dialogContext} from '../../context/dialog'
import IconDelete from 'mdi-material-ui/TrashCan'

export default class DialogProvider extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.contextValue = {
			createDialog: this.createDialog
		}

		this.state = {
			...this.getStateTemplate(),
			contents: null,
			type: null
		}
	}

	render() {
		return (
			<>
				<Dialog open={this.state.open} onClose={this.reject}>
					<Title type={this.state.type}/>
					<DialogContent>
						{typeof this.state.contents === 'string'
							? <Typography variant="body2">{this.state.contents}</Typography>
							: this.state.contents
						}
					</DialogContent>
					<Actions
						type={this.state.type}
						resolve={this.resolve}
						reject={this.reject}
					/>
				</Dialog>
				<dialogContext.Provider value={this.contextValue}>
					{this.props.children}
				</dialogContext.Provider>
			</>
		)
	}

	getStateTemplate() {
		return {
			open: false,
			resolve: Function.prototype,
			reject: Function.prototype
		}
	}

	createDialog(options) {
		const {
			type = 'info',
			contents = ''
		} = options

		return new Promise((resolve, reject) => {
			this.setState({
				resolve, reject, type, contents,
				open: true
			})
		})
	}

	reject() {
		const reject = this.state.reject
		this.setState(this.getStateTemplate(), reject)
	}

	resolve() {
		const resolve = this.state.resolve
		this.setState(this.getStateTemplate(), resolve)
	}
}

const Title = React.memo(props => {
	if (!props.type) return null

	if (props.type === 'info') return (
		<DialogTitle>Info</DialogTitle>
	)

	if (props.type === 'delete') return (
		<DialogTitle>Potvrdit smazání</DialogTitle>
	)
})

const Actions = React.memo(props => {
	if (!props.type) return null

	if (props.type === 'info') return (
		<DialogActions>
			<Button
				color="primary"
				onClick={props.resolve}
			>
				Rozumím
			</Button>
		</DialogActions>
	)

	if (props.type === 'delete') return (
		<DialogActions>
			<Button onClick={props.reject}>
				Zrušit
			</Button>
			<Button
				color="primary"
				startIcon={<IconDelete/>}
				onClick={props.resolve}
			>
				Smazat
			</Button>
		</DialogActions>
	)
})