import React from 'react'
import ReactDOM from 'react-dom'
import {Snackbar, Slide, IconButton} from '@material-ui/core'
import CloseIcon from 'mdi-material-ui/Close'

const cookieConsentText = 'Tento web používá k poskytování služeb a analýze návštěvnosti soubory cookie. Používáním tohoto webu s tím souhlasíte.'

const StyledWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: rgb(49, 49, 49);
`

const StyledContentWrapper = styled.div`
  width: 100%;
  max-width: 1248px;
  margin-left: auto;
  margin-right: auto;

  > div {
    width: 100%;
    display: block;
    transform: none;
    position: initial;
  }

  .MuiSnackbarContent-root {
    border-radius: 0;
    box-shadow: none;
  }
`

export default class CookieConsent extends React.PureComponent {
	state = {
		seen: JSON.parse(localStorage.getItem('vzvCookieConsentSeen') ?? 'false')
	}

	render() {
		return ReactDOM.createPortal(
			<Slide
				in={!this.state.seen}
				direction="up"
				timeout={this.state.seen ? 150 : 0}
				mountOnEnter={true}
				unmountOnExit={true}
			>
				<StyledWrapper>
					<StyledContentWrapper>
						<Snackbar
							open
							message={cookieConsentText}
							transitionDuration={0}
							action={
								<IconButton
									size="small"
									color="inherit"
									onClick={this.onClose.bind(this)}
								>
									<CloseIcon fontSize="small"/>
								</IconButton>
							}
						/>
					</StyledContentWrapper>
				</StyledWrapper>
			</Slide>,
			document.body
		)
	}

	onClose() {
		this.setState({
			seen: true
		})

		localStorage.setItem('vzvCookieConsentSeen', 'true')
	}
}