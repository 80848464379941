import {withSnackbar} from 'notistack'
import {LayoutZone} from '../../style/lib'
import {Flex, FlexItem} from '../Flex'
import {Button, CircularProgress, Divider, IconButton, Paper, Typography} from '@material-ui/core'
import moment from 'moment'
import IconPrevious from 'mdi-material-ui/ChevronLeft'
import IconNext from 'mdi-material-ui/ChevronRight'
import memo from 'memoize-one'
import Select from '../Select'
import IconInfo from 'mdi-material-ui/Information'

const bucketUrl = `https://dotypos-${APP_ENVIRONMENT === 'production' ? 'prod' : 'test'}-dailymenu-json-data.s3.eu-west-1.amazonaws.com`

const StyledLogoSvg = styled.svg`
  padding-top: 4px;
  height: 40px;
`

const StyledPadding = styled.div`
  padding: 8px;
`

const StyledCenterContent = styled.div`
  margin: auto;
  text-align: center;
`

const StyledLayoutZone = styled(LayoutZone)`
  display: flex;
  flex-direction: column;

  > div, > div > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    > div:last-child {
      display: flex;
      flex-direction: column;
    }
  }
`

@withSnackbar
export default class ExternalMenu extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)
	}

	state = {
		week: moment().startOf('week'),
		weekday: moment().isoWeekday(),
		fetching: true,
		menu: null
	}

	currentWeekNo = moment().isoWeek()
	currentWeekday = moment().isoWeekday()

	render() {
		const weekLabel = this.getWeekLabel(this.state.week)
		const weekRange = this.getWeekRange(this.state.week)
		const weekdayOptions = this.getWeekdayOptions(this.state.week)
		const menu = this.state.menu?.menu?.[this.state.weekday]
		const weeklyMenu = this.state.menu?.menu?.['8']

		return (
			<StyledLayoutZone verticalPadding maxWidth={560}>
				<Flex spacing={2} align="stretch" direction="column">
					<FlexItem>
						<Flex spacing={1} align="center" justify="space-between">
							<FlexItem>
								<Typography variant="h6">
									Denní menu
								</Typography>
							</FlexItem>
							<FlexItem>
								<StyledLogoSvg
									xmlns="http://www.w3.org/2000/svg"
									width={196.7*0.6}
									viewBox="0 0 196.7 38.033">
									<defs>
										<clipPath id="clip-path">
											<rect id="Rectangle_535" data-name="Rectangle 535" width="196.7"
											      height="38.033" fill="none"></rect>
										</clipPath>
									</defs>
									<g id="Artwork_3" data-name="Artwork 3" transform="translate(98.35 19.016)">
										<g id="Group_666" data-name="Group 666" transform="translate(-98.35 -19.016)"
										   clipPath="url(#clip-path)">
											<path id="Path_941" data-name="Path 941"
											      d="M15.722,10.6V2.3H21.9V29.857H15.722v-2.12a6.8,6.8,0,0,1-5.564,2.385C3.445,30.122,0,24.735,0,18.905s3.445-10.6,10.246-10.6a7.066,7.066,0,0,1,5.476,2.3m0,8.568a4.946,4.946,0,0,0-4.77-5.123c-3.18,0-4.77,2.473-4.77,5.035s1.59,5.123,4.77,5.123a4.946,4.946,0,0,0,4.77-5.123"
											      transform="translate(0 -0.269)" fill="#221f1f"></path>
											<path id="Path_942" data-name="Path 942"
											      d="M28,20.107c0-15.1,22.611-15.015,22.611,0S28,35.034,28,20.107m16.428,0a5.123,5.123,0,0,0-10.246,0c0,7.066,10.246,7.154,10.246,0"
											      transform="translate(-3.269 -1.029)" fill="#221f1f"></path>
											<path id="Path_943" data-name="Path 943"
											      d="M65.534,30.491c-4.77,0-7.6-2.12-7.6-7.684V14.5H55.2V9.2h2.738V5.23l6.094-.53V9.2h4.416v5.3H64.032v8.3c0,1.855,1.413,2.473,2.826,2.473l1.767-.177.177,5.123a21.552,21.552,0,0,1-3.356.353"
											      transform="translate(-6.445 -0.549)" fill="#221f1f"></path>
											<path id="Path_944" data-name="Path 944"
											      d="M85.689,16.3l2.65-6.8H94.7v.972L84.276,36.881H77.828v-.972l2.738-6.978L73.5,10.472V9.588h6.448l2.65,6.8,1.5,5.388Z"
											      transform="translate(-8.581 -1.109)" fill="#221f1f"></path>
											<path id="Path_945" data-name="Path 945"
											      d="M105.683,17.074l5.829-8.3h6.713v.265l-7.154,10.246,7.861,10.334v.265h-7.154l-6.094-8.214v8.214H99.5V2.5h6.183V17.074Z"
											      transform="translate(-11.617 -0.292)" fill="#221f1f"></path>
											<path id="Path_946" data-name="Path 946"
											      d="M137.122,11.661V9.541H143.3v21.2h-6.183V28.531a6.8,6.8,0,0,1-5.564,2.385c-6.713,0-10.246-5.388-10.157-11.217s3.356-10.6,10.246-10.6a6.8,6.8,0,0,1,5.476,2.561m0,8.214a4.946,4.946,0,0,0-4.77-5.123c-3.091,0-4.681,2.473-4.77,5.035a4.719,4.719,0,0,0,4.77,5.123,4.946,4.946,0,0,0,4.77-5.123"
											      transform="translate(-14.173 -1.062)" fill="#221f1f"></path>
											<path id="Path_947" data-name="Path 947"
											      d="M170.086,21.66C168.32,34.644,148.8,33.76,148.8,20.07S168.408,5.5,170.086,18.48h-6.094a4.681,4.681,0,0,0-9.186,1.59,4.681,4.681,0,0,0,9.186,1.59Z"
											      transform="translate(-17.372 -1.08)" fill="#221f1f"></path>
											<path id="Path_948" data-name="Path 948"
											      d="M182.283,17.074l5.829-8.3h6.713v.265l-7.154,10.246,7.861,10.334v.265h-7.243l-6.006-8.214v8.214H176.1V2.5h6.183V17.074Z"
											      transform="translate(-20.559 -0.292)" fill="#221f1f"></path>
											<path id="Path_949" data-name="Path 949"
											      d="M213.722,11.661V9.541H219.9v21.2h-6.183V28.531a6.8,6.8,0,0,1-5.564,2.385c-6.713,0-10.246-5.388-10.157-11.217s3.356-10.6,10.246-10.6a6.8,6.8,0,0,1,5.476,2.561m0,8.214a4.946,4.946,0,0,0-4.77-5.123c-3.091,0-4.681,2.473-4.77,5.035a4.719,4.719,0,0,0,4.77,5.123,4.946,4.946,0,0,0,4.77-5.123"
											      transform="translate(-23.116 -1.062)" fill="#221f1f"></path>
											<path id="Path_950" data-name="Path 950"
											      d="M49.538,39.368,47.507,36.1a15.015,15.015,0,0,1-16.075,0L29.4,39.368a18.813,18.813,0,0,0,20.138,0"
											      transform="translate(-3.432 -4.215)" fill="#2cb14b"></path>
											<path id="Path_951" data-name="Path 951"
											      d="M149.4,2.914l2.031,3.268a15.015,15.015,0,0,1,16.075,0l2.031-3.268a18.813,18.813,0,0,0-20.138,0"
											      transform="translate(-17.442 0.001)" fill="#2cb14b"></path>
										</g>
									</g>
								</StyledLogoSvg>
							</FlexItem>
						</Flex>
					</FlexItem>
					<FlexItem>
						<Paper>
							<StyledPadding>
								<Flex spacing={2} align="center" justify="space-between">
									<FlexItem>
										<IconButton
											size="small"
											disabled={this.state.fetching || this.state.week.isoWeek() <= this.currentWeekNo}
											onClick={this.onPreviousWeekClick}
										>
											<IconPrevious/>
										</IconButton>
									</FlexItem>
									<FlexItem>
										<Typography variant="subtitle1" align="center">
											{weekLabel}
										</Typography>
										<Typography variant="caption" align="center">
											{weekRange}
										</Typography>
									</FlexItem>
									<FlexItem>
										<IconButton
											size="small"
											disabled={this.state.fetching}
											onClick={this.onNextWeekClick}
										>
											<IconNext/>
										</IconButton>
									</FlexItem>
								</Flex>
							</StyledPadding>
							<Divider/>
							<StyledPadding>
								<Flex spacing={1}>
									<FlexItem xs={12}>
										<Select
											fullWidth
											size="small"
											value={this.state.weekday}
											onChange={this.onWeekdayChange}
											options={weekdayOptions}
											disabled={this.state.fetching}
										/>
									</FlexItem>
									{(this.currentWeekNo !== this.state.week.isoWeek() || this.currentWeekday !== this.state.weekday) && (
										<FlexItem xs={12}>
											<Button
												color="primary"
												variant="outlined"
												onClick={this.onCurrentDayClick}
												fullWidth
											>
												Zobrazit dnešní den
											</Button>
										</FlexItem>
									)}
								</Flex>
							</StyledPadding>
						</Paper>
					</FlexItem>
					<FlexItem grow={1}>
						{this.state.fetching && (
							<StyledCenterContent>
								<CircularProgress/>
							</StyledCenterContent>
						)}
						{!this.state.fetching && !menu?.length && !weeklyMenu?.length && (
							<StyledCenterContent>
								<div style={{marginBottom: 16}}><IconInfo/></div>
								<Typography variant="button">
									Menu pro tento den ještě není připraveno.
								</Typography>
							</StyledCenterContent>
						)}
						<Flex spacing={2}>
							{!this.state.fetching && !!menu?.length && (
								<MenuProductList menu={menu}/>
							)}
							{!this.state.fetching && !!weeklyMenu?.length && (
								<>
									<FlexItem xs={12}>
										<Typography variant="h6">Týdenní nabídka</Typography>
									</FlexItem>
									<MenuProductList menu={weeklyMenu}/>
								</>
							)}

						</Flex>
					</FlexItem>
				</Flex>
			</StyledLayoutZone>
		)
	}

	async componentDidMount() {
		await this.fetchWeek()
	}

	async componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.state.week.isSame(prevState.week)) {
			await this.fetchWeek()
		}
	}

	async fetchWeek() {
		await new Promise(resolve => this.setState({fetching: true}, () => resolve()))
		const menu = await fetch(`${bucketUrl}/${this.props.cloudHash}/${this.state.week.year()}/${this.state.week.isoWeek()}/daily-menu-publishing-dto.json`, {
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then(response => response.json())
		.catch(() => null)

		this.setState({fetching: false, menu})
	}

	getWeekLabel = memo(week => {
		const selectedWeekNo = week.isoWeek()
		const difference = selectedWeekNo - this.currentWeekNo

		if (difference === 0) return 'Tento týden'
		if (difference === -1) return 'Minulý týden'
		if (difference === 1) return 'Příští týden'

		if (difference > 1) {
			if (difference < 5) return `Za ${difference} týdny`
			return `Za ${difference} týdnů`
		}

		return `Před ${-difference} týdny`
	}, isEqualStringified)

	getWeekRange = memo(week => {
		const start = week.format('D.M.YYYY')
		const end = week.clone().add(6, 'days').format('D.M.YYYY')
		return `${start} — ${end}`
	}, isEqualStringified)

	getWeekdayOptions = memo(week => {
		const weekday = week.clone().isoWeekday(1)
		return Array.from(new Array(7)).map(() => {
			const weekdayString = weekday.isoWeekday().toString()
			const option = {
				value: weekdayString,
				label: weekday.format('dddd D.M.')
			}

			weekday.add(1, 'day')
			return option
		})
	}, isEqualStringified)

	onWeekdayChange(e) {
		this.setState({weekday: e.target.value})
	}

	onPreviousWeekClick() {
		this.setState(prevState => ({
			week: prevState.week.clone().subtract(1, 'week')
		}))
	}

	onNextWeekClick() {
		this.setState(prevState => ({
			week: prevState.week.clone().add(1, 'week')
		}))
	}

	onCurrentDayClick() {
		this.setState(prevState => ({
			weekday: this.currentWeekday,
			week: prevState.week.clone().isoWeek(this.currentWeekNo)
		}))
	}
}

const isEqualStringified = (newArgs, lastArgs) => JSON.stringify(newArgs) === JSON.stringify(lastArgs)

function formatCurrency(value) {
	return new Intl.NumberFormat('cs-CZ', {
		style: 'currency',
		currency: 'CZK'
	}).format(value)
}

class MenuProductList extends React.PureComponent {
	render() {
		return (
			<FlexItem xs={12}>
				{this.props.menu.map(product => (
					<Flex spacing={2} wrap={false} key={JSON.stringify(product)}>
						<FlexItem grow={1} shrink={true}>
							<Flex spacing={1}>
								<FlexItem xs={12}>
									<Typography variant="subtitle2">
										{product.prefix}. {product.name}
									</Typography>
								</FlexItem>
								{product.note && (
									<FlexItem xs={12}>
										<Typography variant="body2">
											{product.note}
										</Typography>
									</FlexItem>
								)}
							</Flex>
						</FlexItem>
						<FlexItem shrink={false}>
							<Typography variant="subtitle2" style={{whiteSpace: 'nowrap'}}>
								{formatCurrency(product.priceWithVat ?? '0')}
							</Typography>
						</FlexItem>
					</Flex>
				))}
			</FlexItem>
		)
	}
}