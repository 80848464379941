import {LayoutZone, Padding} from '../../../style/lib'
import {Grid, Link as MaterialLink, Paper, Typography} from '@material-ui/core'
import {Link} from '@reach/router'

const StyledParagraph = styled(Typography).attrs({variant: 'body1'})`
	margin-top: 0.5rem;
	margin-bottom: 1.1rem;
`
const StyledH1 = styled(Typography).attrs({variant: 'h4', component: 'h1'})`
	margin-top: 1.1rem;
`

const StyledH2 = styled(Typography).attrs({variant: 'h6', component: 'h2'})`
	margin-top: 2.8rem;
`

const StyledDocumentPaper = styled(Paper)`
	ul > li > ul {
		margin-left: 24px;
	}
	
	li:not(.dot) {
		list-style-type: none;
	}
`

export default class PrivacyPolicy extends React.PureComponent {
	render() {
		return (
			<LayoutZone>
				<Padding size={40}>
					<StyledDocumentPaper>
						<Padding size={32}>
							<Grid container spacing={4} justify="center" direction="column">
								<Grid item xs={12}>

									<StyledH1>INFORMACE O OCHRANĚ OSOBNÍCH ÚDAJŮ</StyledH1>

									<StyledParagraph>
										Společnost <b>Dotykačka ČR s.r.o.</b>, IČO: 062 90 914, se sídlem Plzeňská
										3217/16,
										Smíchov, 150 00 Praha 5 (dále jen „<b>Správce</b>“), zapsaná v obchodním
										rejstříku
										vedeném Městským soudem v Praze pod sp. zn. C 279594, je při využívání služby
										dostupné na <LinkHome/> a/nebo mobilní aplikace Vyzvednisi.cz (dále jen
										„<b>Služba</b>“) Správcem osobních údajů zákazníků, potenciálních zákazníků,
										osob jednajících za Poskytovatele a jiných osob (dále jen „<b>Subjekt údajů</b>“),
										které využívají předmětnou Službu Správce a
										poskytují mu při jejím využívání osobní údaje. Ochrana osobních údajů je pro
										Správce velmi důležitá, proto vždy s osobními údaji zachází v souladu s právními
										předpisy.
									</StyledParagraph>
									<StyledParagraph>
										V případě, že jsou v této informaci o ochraně osobních údajů (dále jen
										„<b>Informace</b>“) použity pojmy začínající velkým písmenem, mají význam
										stanovený v
										této Informaci nebo v podmínkách užití Služby.
									</StyledParagraph>

									<ul>
										<li>
											<StyledH2>
												1. Jaké údaje zpracováváme?
											</StyledH2>
											<ul>
												<li>
													<StyledParagraph>
														a) <b>identifikační údaje</b> – zejména jméno a příjmení
														Subjektu údajů,
														uživatelské jméno a heslo;
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														b) <b>kontaktní (adresní) údaje</b> – e-mailová adresa,
														telefonní číslo, adresa pro doručení apod;
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														c) <b>údaje o objednávkách</b> – jako např. údaje o
														objednávkách Subjektu údajů, jejich stavu, historie objednávek;
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														d) <b>platební údaje</b> – informace o způsobu platby;
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														e) <b>lokalizační údaje</b> – např. poloha zařízení, která
														pomůže Subjektu údajů nalézt provozovny v jeho okolí;
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														f) <b>cookies</b> – na webových stránkách využíváme rovněž tzv.
														cookies, tj. krátké textové soubory, které se ukládají do Vašeho
														počítače při načtení webových stránek Vyzvednisi.cz. Díky
														cookies můžeme identifikovat způsob, jakým Subjekt údajů pracuje
														s
														obsahem webových stránek, a tím personalizovat naši komunikaci
														vůči Vám anebo třeba uložit Vaši poslední rozpracovanou
														objednávku.
													</StyledParagraph>
												</li>
											</ul>
											<StyledParagraph>
												Souhlas s umístěním souborů cookies je dobrovolný. Subjekt údajů tedy
												může
												cookies zablokovat, a to i jenom částečně, nebo vymazat ty, které již
												byly nastaveny. Více informací o cookies a možnostech nastavení můžete
												najít na stránce <MaterialLink target="_blank"
												                               href="https://www.aboutcookies.org">www.aboutcookies.org</MaterialLink>,
												nebo na internetových stránkách
												jednotlivých prohlížečů. Dovolujeme si Vás upozornit, že v případě, že
												dojde k blokaci nebo vymazání cookies odesílaných z našich webových
												stránek, které jsou nezbytně nutné nebo zajišťují funkčnost a výkon
												webových stránek, může se stát, že stránky nebude možné používat.
											</StyledParagraph>
										</li>
									</ul>

									<ul>
										<li>
											<StyledH2>
												2. Za jakým účelem osobní údaje zpracováváme?
											</StyledH2>
											<ul>
												<li>
													<StyledParagraph>
														a) Subjekt údajů udělil souhlas pro jeden či více konkrétních
														účelů,
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														b) zpracování osobních údajů Subjektu údajů je nezbytné pro
														splnění
														smlouvy, jejíž smluvní stranou je Subjekt údajů, nebo pro
														provedení
														opatření přijatých před uzavřením smlouvy na žádost Subjektu
														údajů,
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														c) zpracování je nezbytné pro splnění právní povinnosti, která
														se na Správce vztahuje,
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														d) zpracování je nezbytné pro ochranu životně důležitých zájmů
														Subjektu údajů nebo jiné fyzické osoby,
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														e) zpracování je nezbytné pro splnění úkolu prováděného ve
														veřejném zájmu nebo při výkonu veřejné moci, kterým je pověřen
														Správce,
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														f) zpracování je nezbytné pro účely oprávněných zájmů
														příslušného Správce či třetí strany, kromě případů, kdy před
														těmito zájmy mají přednost zájmy nebo základní práva a svobody
														Subjektu údajů vyžadující ochranu osobních údajů.
													</StyledParagraph>
												</li>
											</ul>

											<StyledParagraph>
												Pro větší srozumitelnost přidáváme několik nejběžnějších případů
												zpracování osobních údajů Správce s přiřazením konkrétního účelu
												zpracování:
											</StyledParagraph>

											<ul>
												<li>
													<StyledParagraph>
														1. <b>Uzavření smlouvy</b>
													</StyledParagraph>
													<StyledParagraph>
														Správce zpracovává osobní údaje Subjektu údajů – oprávněných
														zástupců Poskytovatelů za účelem uzavření smluvního vztahu mezi
														Správcem a Poskytovatelem, na základě kterého Správce mj.
														vytvoří Poskytovateli příležitost k uzavření smlouvy na dodání
														zboží nebo poskytnutí služeb Poskytovatelem Zákazníkovi.
													</StyledParagraph>
													<StyledParagraph>
														Titulem pro výše uvedený účel zpracování Vašich osobních údajů
														je jejich nezbytnost pro splnění smlouvy.
													</StyledParagraph>
													<StyledParagraph>
														2. <b>Realizace objednávky</b>
													</StyledParagraph>
													<StyledParagraph>
														Správce zpracovává Vaše osobní údaje pro účely související s
														propojením Zákazníka s poskytovatelem služby, tj.
														zejména restaurací a/nebo obchodem. Při těchto úkonech
														potřebujeme
														znát minimálně jméno a příjmení Zákazníka, e-mail, telefonní
														číslo a
														adresu pro doručení, abychom mohli řádně realizovat naši Službu.
													</StyledParagraph>
													<StyledParagraph>
														Titulem pro výše uvedený účel zpracování Vašich osobních údajů
														je
														jejich nezbytnost pro splnění smlouvy.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														3. <b>Správa a zlepšování našich Služeb</b>
													</StyledParagraph>
													<StyledParagraph>
														Vaše údaje nám rovněž slouží k tomu, abychom mohli, mimo jiné
														díky
														analýze osobních údajů, spravovat, vylepšovat a rozvíjet naše
														Služby.
													</StyledParagraph>
													<StyledParagraph>
														Pro uvedené účely zpracováváme Vaše osobní údaje na základě
														oprávněného zájmu.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														4. <b>Zpětná vazba od Subjektů údajů</b>
													</StyledParagraph>
													<StyledParagraph>
														Vaše osobní údaje využíváme také proto, abychom od Vás získali
														zpětnou vazbu k fungování našich Služeb a umožnili Vám hodnotit
														jednotlivé poskytovatele, u nichž jste si udělali objednávku.
														Čas od
														času Vás také upozorníme na důležité změny týkající se našich
														Služeb.
													</StyledParagraph>
													<StyledParagraph>
														I zde zpracováváme Vaše osobní údaje na základě oprávněného
														zájmu.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														5. <b>Prevence nezákonného/podvodného jednání a plnění právních
														povinností</b>
													</StyledParagraph>
													<StyledParagraph>
														Správce se snaží při poskytování Služeb předcházet nezákonnému
														jednání/ podvodům a zneužívání Služeb, aby ochránil své
														Zákazníky i
														poskytovatele. Některé osobní údaje je Správce povinen
														shromažďovat
														rovněž proto, aby dostál zákonným požadavkům.
													</StyledParagraph>
													<StyledParagraph>
														Správce v tomto případě zpracovává osobní údaje na základě
														právního
														titulu oprávněného zájmu a na základě právního titulu plnění
														právní
														povinnosti.
													</StyledParagraph>
												</li>
											</ul>
										</li>
									</ul>

									<ul>
										<li>
											<StyledH2>
												3. Jaká jsou práva Subjektů údajů v oblasti ochrany osobních údajů?
											</StyledH2>
											<ul>
												<li>
													<StyledParagraph>
														a) <b>Právo na přístup</b> – Subjekt údajů má právo získat
														informace
														o tom, zda o
														něm zpracováváme osobní údaje a pokud ano, jakým způsobem je
														zpracováváme.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														b) <b>Právo na opravu a doplnění</b> – Pokud Subjekt údajů
														zjistí, že
														jsou jeho
														osobní údaje, které Správce zpracovává, nepřesné nebo neúplné,
														má
														právo nás požádat o jejich opravu a aktualizaci.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														c) <b>Právo na výmaz</b> – Subjekt údajů má právo požádat
														Správce o
														vymazání
														svých osobních údajů a Správce je povinen je v níže uvedených
														případech vymazat:
													</StyledParagraph>
													<ul>
														<li className="dot">
															<StyledParagraph>
																osobní údaje již nejsou potřebné pro účely, pro které
																byly
																shromážděny nebo jinak zpracovány;
															</StyledParagraph>
														</li>
														<li className="dot">
															<StyledParagraph>
																Subjekt údajů odvolal souhlas se zpracováním osobních
																údajů,
																přičemž se jedná o údaje, k jejichž zpracování je jeho
																souhlas nezbytný a Správce zároveň nemá jiný důvod, proč
																tyto údaje potřebuje nadále zpracovávat;
															</StyledParagraph>
														</li>
														<li className="dot">
															<StyledParagraph>
																Subjekt údajů využije svého práva vznést námitku proti
																zpracování u osobních údajů, které Správce zpracovává na
																základě svých oprávněných zájmů a Správce shledá, že již
																netrvají oprávněné zájmy, na základě kterých došlo ke
																zpracování osobních údajů;
															</StyledParagraph>
														</li>
														<li className="dot">
															<StyledParagraph>
																pokud se Subjekt údajů domnívá, že jsou jeho údaje
																zpracovávány protiprávně;
															</StyledParagraph>
														</li>
													</ul>
													<StyledParagraph>
														Právo na výmaz osobních údajů se neuplatní v případě, že je
														zpracování osobních údajů Subjektu údajů i nadále nezbytné pro
														splnění právní povinnosti Správce, a/nebo určení, výkon nebo
														obhajobu právních nároků Správce.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														d) <b>Právo na omezení zpracování</b> – Subjekt údajů má právo v
														případech, kdy např. popírá přesnost, zákonnost zpracování
														osobních
														údajů, vznesl námitku proti zpracování osobních údajů tak, jak
														je
														uvedeno níže, požádat Správce o dočasné omezení zpracování
														osobních
														údajů. Pokud bude zpracování osobních údajů Subjektu údajů dle
														žádosti
														vyhověno, omezí Správce zpracování osobních údajů na nezbytné
														minimum. Pokud dojde ke zrušení omezení zpracování údajů a
														Správce
														bude dále zpracovávat osobní údaje, bude o této skutečnosti
														Subjekt údajů předem informovat.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														e) <b>Právo na přenositelnost</b> – Subjekt údajů má právo
														získat osobní údaje, které se ho týkají, a které poskytl
														Správci, ve strukturovaném, běžně používaném a strojově čitelném
														formátu, a právo tyto údaje předat novému správci osobních
														údajů. Subjekt údajů je oprávněn uplatnit právo na
														přenositelnost údajů, pouze pokud Správce zpracovává jeho osobní
														údaje na základě souhlasu Subjektu údajů, pro účely plnění
														smlouvy a zároveň je zpracování automatizované.
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														f) <b>Právo odvolat souhlas a vznést námitku</b> – Pokud byly
														shromážděny osobní údaje Subjektu údajů na základě jeho
														souhlasu, může Subjekt údajů svůj souhlas kdykoliv odvolat.
													</StyledParagraph>
													<StyledParagraph>
														V případě, že je právním základem zpracování osobních údajů
														oprávněný zájem Správce či třetí strany (případně tehdy, když je
														právním základem zpracování plnění úkolu prováděného ve veřejném
														zájmu nebo při výkonu veřejné moci), může Subjekt údajů vznést
														námitku proti zpracování osobních údajů.
													</StyledParagraph>
													<StyledParagraph>
														Pokud vznesete proti zpracování námitku, nebude Správce Vaše
														osobní údaje dále zpracovávat, a to do té doby, dokud neprokáže
														závažné důvody pro zpracování, které převažují nad Vašimi zájmy
														nebo právy a svobodami. Námitku můžete vznést také v případě,
														kdy Správce zpracovává osobní údaje pro účely přímého marketingu
														(na základěprávního titulu oprávněného zájmu), včetně
														profilování s cílem najít pro Zákazníka ty nejvhodnější nabídky
														Poskytovatelů zboží a/nebo služeb. Pokud Subjekt údajů vznese
														námitku proti zpracování pro účely přímého marketingu (včetně
														profilování), přestane Správce osobní údaje Subjektu údajů pro
														tento účel zpracovávat bez dalšího.
													</StyledParagraph>
												</li>
												<StyledParagraph>
													g) <b>Právo podat stížnost u dozorového orgánu</b> – Subjekt údajů
													má právo v případě, že se domnívá, že Správce porušuje povinnosti
													stanovené právními předpisy, podat stížnost u Úřadu pro ochranu
													osobních údajů, Pplk. Sochora 27, 170 00 Praha 7,
													e-mail: <MaterialLink
													href="mailto:posta@uoou.cz">posta@uoou.cz</MaterialLink>.
												</StyledParagraph>
											</ul>
											<StyledParagraph>
												Jako Správce máme zájem se svými Zákazníky co nejlépe komunikovat a
												řešit veškeré jejich podměty a stížnosti. Neváhejte se proto před
												případným podáním stížnosti k dozorovému úřadu obrátit nejprve na nás a
												dát nám možnost vše vyřešit přímo s Vámi.
											</StyledParagraph>
										</li>
									</ul>

									<ul>
										<li>
											<StyledH2>
												4. Komu jsou Vaše osobní údaje předávány a kdo je zpracovává?
											</StyledH2>

											<StyledParagraph>
												Osobní údaje zpracovává primárně Správce, který určuje, pro jaké účely
												jsou osobní údaje shromažďovány, v jakém rozsahu a jakým způsobem je
												jejich zpracování zabezpečeno.
											</StyledParagraph>
											<StyledParagraph>
												Vaše osobní údaje zpracováváme rovněž v rámci holdingu společností
												patřících pod mateřskou společnost Dotykačka Holding a.s., IČO: 062 70
												611, se sídlem Plzeňská 3217/16, Smíchov, 150 00 Praha 5, zapsané do
												obchodního rejstříku vedeného Městským soudem v Praze pod sp. zn. B
												22692, a to na základě oprávněného zájmu, tj. pro vnitřní
												administrativní účely, analýzu chování Subjektů údajů a zlepšení
												poskytování našich služeb.
											</StyledParagraph>
											<StyledParagraph>
												Vaše osobní údaje můžeme předávat také dalším subjektům, a to:
											</StyledParagraph>
											<ul>
												<li>
													<StyledParagraph>
														a) Poskytovatelům, jejichž produkty nebo služby jste si
														prostřednictvím
														našich Služeb zakoupili – tj. předání v rámci realizace Vaší
														objednávky;
													</StyledParagraph>
												</li>
												<li>
													<StyledParagraph>
														b) dalším zpracovatelům, kteří osobní údaje zpracovávají pouze
														podle našich pokynů – tj. našim dodavatelům – např. tvůrcům
														webových stránek a mobilní aplikace, dodavatelům hostingových a
														marketingových služeb, dodavatelům statistických, databázových a
														jiných softwarových nástrojů, poskytovatelům SMS komunikačních a
														chatovacích nástrojů a případně jiným subjektům, které nám
														poskytují své služby.
													</StyledParagraph>
												</li>
											</ul>
											<StyledParagraph>
												Vaše osobní údaje jsou zpracovávány zejména v České republice. Některé
												činnosti, jako např. ukládání dat prostřednictvím cloudových služeb,
												realizujeme v EU.
											</StyledParagraph>
										</li>
									</ul>

									<ul>
										<li>
											<StyledH2>
												5. Společní správci osobních údajů – Facebook
											</StyledH2>
											<StyledParagraph>
												Do Služby se Subjekt údajů může registrovat a přihlásit rovněž
												prostřednictvím svého účtu zřízeného u Facebooku. V takovém případě
												dojde k propojení profilu Subjektu údajů na Facebooku s profilem ve
												Službě, a to na základě souhlasu Subjektu údajů uděleného na stránkách
												Facebooku. Díky propojení získá Správce od společnosti Facebook Inc.
												automaticky některé informace, a to v závislosti na nastavení ochrany
												osobních údajůSubjektu údajů na Facebooku.
											</StyledParagraph>
											<StyledParagraph>
												Zejména se může jednat o tyto informace:
											</StyledParagraph>
											<ul>
												<li className="dot">
													<StyledParagraph>
														jméno a příjmení, přezdívka Subjektu údajů užívaná na Facebooku
													</StyledParagraph>
												</li>
												<li className="dot">
													<StyledParagraph>
														věk a pohlaví
													</StyledParagraph>
												</li>
												<li className="dot">
													<StyledParagraph>
														link na veřejný profil Subjektu údajů založený na Facebooku
													</StyledParagraph>
												</li>
												<li className="dot">
													<StyledParagraph>
														profilové obrázky, uživatelská jména přátel Subjektu údajů a
														seznam přátel, pokud jsou v nastavení ochrany soukromí Subjektu
														údajů na Facebooku označené jako „veřejné“.
													</StyledParagraph>
												</li>
											</ul>

											<StyledParagraph>
												Další informace o přihlášení prostřednictvím sociální sítě Facebook a
												nastavení účtu Subjektu údajů na Facebooku může Subjekt údajů najít v
												Informacích o ochraně osobních údajů a v Podmínkách použití Facebook
												(<MaterialLink
												href="https://www.facebook.com/legal/terms">https://www.facebook.com/legal/terms</MaterialLink>).
											</StyledParagraph>
											<StyledParagraph>
												Údaje předané z Facebooku Správce uchovává a zpracovává za účelem
												registrace, přihlášení do uživatelského účtu ve Službě a poskytování
												Služeb.
											</StyledParagraph>
											<StyledParagraph>
												Souhlas s poskytováním osobních údajů může Zákazník kdykoliv odvolat
												e-mailem na <MaterialLink
												href="mailto:info@vyzvednisi.cz">info@vyzvednisi.cz</MaterialLink> nebo{' '}
												<MaterialLink
													href="mailto:gdpr@dotykacka.cz">gdpr@dotykacka.cz</MaterialLink>. V
												takovém případě
												již
												nebude Zákazníkovi umožněno přihlásit se do Služby prostřednictvím sítě
												Facebook.
											</StyledParagraph>
											<StyledParagraph>
												Správce tímto informuje Zákazníka, že je spolu s provozovatelem
												Facebooku tzv. společným správcem ve smyslu čl. 26 nařízení Evropského
												parlamentu a Rady (EU) 2016/679 ze dne 27. dubna 2016 o ochraně
												fyzických
												osob v souvislosti se zpracováním osobních údajů a o volném pohybu
												těchto
												údajů a o zrušení směrnice 95/46/EC.
											</StyledParagraph>
										</li>
									</ul>

									<ul>
										<li>
											<StyledH2>
												6. Jak dlouho vaše údaje zpracováváme?
											</StyledH2>
											<StyledParagraph>
												Vaše osobní údaje zpracováváme po dobu poskytování Služby, tj. např. po
												dobu existence registrace Zákazníka/Poskytovatele. Po uplynutí této doby
												Správce osobní údaje dále uchovává na základě oprávněného zájmu za
												účelem ochrany právních nároků a pro vnitřní statistické a kontrolní
												úkony, a to po dobu 10 let, není-li platnými právními předpisy stanovená
												delší doba, po kterou musí Správce osobní údaje evidovat.
											</StyledParagraph>
										</li>
									</ul>

									<ul>
										<li>
											<StyledH2>
												7. Jakým způsobem Vaše osobní údaje zpracováváme
											</StyledH2>
											<StyledParagraph>
												Správce zpracovává osobní údaje manuálně i automatizovaně v
												elektronických informačních systémech, v elektronické nebo listinné
												podobě, a to prostřednictvím zaměstnanců nebo externích zpracovatelů.
												Zaměstnanci i externí zpracovatelé, kteří se podílejí na zpracování
												osobních údajů Subjektů údajů, jsou vázáni mlčenlivostí.
											</StyledParagraph>
											<StyledParagraph>
												Správce přijal veškerá vhodná organizační a bezpečnostní opatření pro
												ochranu osobních údajů Subjektů údajů před zneužitím, ztrátou, zničením,
												poškozením a neoprávněným nebo nezákonným přístupem. V případě, že je to
												možné pro naplnění daného účelu zpracování, zpracovává Správce osobní
												údaje v pseudonymizované či anonymizované podobě.
											</StyledParagraph>
										</li>
									</ul>

									<ul>
										<li>
											<StyledH2>
												8. Jak nás můžete kontaktovat?
											</StyledH2>
											<StyledParagraph>
												Pokud byste se na Správce chtěli obrátit, využijte níže uvedené
												kontaktní údaje:
											</StyledParagraph>
											<ul>
												<li className="dot">
													<StyledParagraph>
														Adresa: Plzeňská 3217/16, Smíchov, 150 00 Praha 5
													</StyledParagraph>
												</li>
												<li className="dot">
													<StyledParagraph>
														E-mailová adresa: <MaterialLink
														href="mailto:info@vyzvednisi.cz">info@vyzvednisi.cz</MaterialLink>,{' '}
														<MaterialLink
															href="mailto:gdpr@dotykacka.cz">gdpr@dotykacka.cz</MaterialLink>
													</StyledParagraph>
												</li>
											</ul>
											<StyledParagraph>
												Vaše dotazy, žádosti nebo stížnosti vyřídíme bez zbytečného odkladu,
												nejpozději do jednoho měsíce. V případě, že se bude jednat o požadavek,
												který nebudeme schopni vyřešit v průběhu jednoho měsíce, budeme Vás o
												této skutečnosti i o délce lhůty pro vyřízení informovat.
											</StyledParagraph>
										</li>
									</ul>

									<ul>
										<li>
											<StyledH2>
												9. Změny informací o ochraně osobních údajů
											</StyledH2>
											<StyledParagraph>
												Správce si vyhrazuje možnost informace o ochraně osobních údajů změnit
												či doplnit v souladu s příslušnými právními předpisy. Správce bude o
												takové změně informovat Subjekty údajů na webové stránce
												www.vyzvednisi.cz, případně jiným vhodným způsobem dle volby Správce
												tak, aby se s aktuálně platným zněním Informací o ochraně osobních údajů
												mohl každý Subjekt údajů bez zbytečného prodlení seznámit.
											</StyledParagraph>
											<StyledParagraph>
												Shora uvedené Informace o ochraně osobních údajů byly naposledy
												aktualizovány ke dni 14.8.2020.
											</StyledParagraph>
										</li>
									</ul>

								</Grid>
							</Grid>
						</Padding>
					</StyledDocumentPaper>
				</Padding>
			</LayoutZone>
		)
	}
}

class LinkHome extends React.PureComponent {
	render() {
		return <MaterialLink component={Link} to="/">www.vyzvednisi.cz</MaterialLink>
	}
}
