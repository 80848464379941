import {userContext} from '../../context/user'

const APIRefreshTokenIdentifier = 'APIRefreshToken'

export default class UserProvider extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		const refreshToken = localStorage.getItem(APIRefreshTokenIdentifier) || null

		this.state = {
			user: null,
			accessToken: null,
			refreshToken
		}
	}

	render() {
		return (
			<userContext.Provider value={{
				...this.state.user,
				authorized: this.getAuthorizationState(),
				accessToken: this.state.accessToken,
				refreshToken: this.state.refreshToken,
				update: this.updateUserContext,
				login: this.login,
				logout: this.logout,
				authorize: this.authorize
			}}>
				{this.props.children}
			</userContext.Provider>
		)
	}

	getAuthorizationState() {
		if (!this.state.refreshToken) return false
		if (!this.state.accessToken) return null
		return true
	}

	updateUserContext(updater, callback) {
		this.setState(prevState => ({
			user: typeof updater === 'function'
				? {...prevState.user, ...updater(prevState.user)}
				: {...prevState.user, ...updater}
		}), callback)
	}

	login(refreshToken, callback) {
		localStorage.setItem(APIRefreshTokenIdentifier, refreshToken)
		this.setState({refreshToken}, callback)
	}

	logout() {
		localStorage.removeItem(APIRefreshTokenIdentifier)
		this.setState({
			user: false,
			accessToken: null,
			refreshToken: null
		})
	}

	authorize({accessToken, user}, callback) {
		this.setState({accessToken, user}, callback)
	}
}