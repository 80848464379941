import MutationSummary from 'mutation-summary'

const containerContext = React.createContext(null)

@withContext @withScreen
export class Sticky extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.initStyle = {
			position: 'relative',
			top: undefined,
			bottom: false
		}
	}

	render() {
		const proxiedProps = {...this.props}
		delete proxiedProps.top
		delete proxiedProps.bottom
		delete proxiedProps.topElements
		delete proxiedProps.component
		delete proxiedProps.screen
		delete proxiedProps.container
		delete proxiedProps.onStick
		delete proxiedProps.onRelease

		return (
			<>
				<this.props.component
					{...proxiedProps}
					ref={ref => this.shadow = ref}
					style={{
						...proxiedProps.style,
						position: 'relative',
						visibility: 'hidden',
						height: this.initStyle.position !== 'relative'
							? 'auto' : 0
					}}
					id={undefined}
				>
					{this.props.children}
				</this.props.component>
				<this.props.component
					{...proxiedProps}
					ref={ref => this.sticky = ref}
					style={{
						...proxiedProps.style,
						position: this.initStyle.position,
						top: this.initStyle.top,
						display: 'block',
						...(this.initStyle.bottom && {
							position: 'absolute',
							top: 'initial',
							bottom: 0,
							left: 0,
							right: 0
						})
					}}
				>
					{this.props.children}
				</this.props.component>
			</>
		)
	}

	componentDidMount() {
		this.position()
		window.addEventListener('scroll', this.position)
		window.addEventListener('resize', this.position)
		this.observer = new MutationSummary({
			rootNode: this.props.container || this.sticky,
			callback: this.position,
			queries: [{all: true}]
		})
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.position)
		window.removeEventListener('resize', this.position)
		this.observer.disconnect()
	}

	position() {
		const shadowOffset = this.shadow.getBoundingClientRect().top
		const topOffset = this.getTopOffset(this.props.top, this.props.topElements)

		if (shadowOffset < topOffset) {
			if (this.props.container) {
				const containerRect = this.props.container.getBoundingClientRect()

				if (containerRect.bottom - this.props.bottom - topOffset < this.sticky.offsetHeight) {
					return this.setStyle({
						position: 'absolute',
						bottom: this.props.bottom
					})
				}
			}

			return this.setStyle({
				position: 'fixed',
				top: topOffset + 'px',
				bottom: false
			})
		}

		return this.setStyle({
			position: 'relative',
			top: 'initial',
			bottom: false
		})
	}

	setStyle(style) {
		this.sticky.style.width = `${this.shadow.getBoundingClientRect().width}px`
		this.shadow.style.height = style.position !== 'relative'
			? 'auto' : '0px'
		this.sticky.style.position = style.position
		this.sticky.style.top = style.top

		if (style.bottom !== false) {
			this.sticky.style.position = 'absolute'
			this.sticky.style.top = 'initial'
			this.sticky.style.bottom = `${style.bottom}px`
			this.sticky.style.left = '0px'
			this.sticky.style.right = '0px'
		}
		else {
			this.sticky.style.bottom = 'initial'
			this.sticky.style.left = 'initial'
			this.sticky.style.right = 'initial'
		}

		if (style.position !== 'relative' && !this.isSticky) {
			this.isSticky = true
			this.props.onStick()
			return
		}

		if (style.position === 'relative' && this.isSticky) {
			this.isSticky = false
			this.props.onRelease()
		}
	}

	isSticky = false

	getTopOffset = (top, elements) => {
		return elements.reduce((total, element) => {
			return total + element.offsetHeight
		}, top)
	}

	static propTypes = {
		top: PropTypes.number,
		topElements: PropTypes.array,
		component: PropTypes.string,
		onStick: PropTypes.func,
		onRelease: PropTypes.func,
		bottom: PropTypes.number
	}

	static defaultProps = {
		top: 0,
		topElements: [],
		component: 'div',
		onStick: Function.prototype,
		onRelease: Function.prototype,
		bottom: 0
	}
}

function withContext(ComponentType) {
	return React.forwardRef((props, ref) => (
		<containerContext.Consumer>
			{container => (
				<ComponentType
					{...props}
					ref={ref}
					container={container}
				/>
			)}
		</containerContext.Consumer>
	))
}

const StyledContainerDiv = styled.div`
	position: relative;
	height: 100%;
`

export class StickyContainer extends React.Component {
	constructor(props) {
		super(props)
		autobind(this)

		this.state = {
			containerRef: null
		}
	}

	render() {
		return (
			<containerContext.Provider value={this.state.containerRef}>
				<StyledContainerDiv {...this.props} ref={ref => this.containerRef = ref}/>
			</containerContext.Provider>
		)
	}

	componentDidMount() {
		this.setState({containerRef: this.containerRef})
	}
}
