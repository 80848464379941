// matchMedia: addEventListener
(function() {
    if (window.matchMedia('all').addEventListener) return
    const localMatchMedia = window.matchMedia
    window.matchMedia = function(media) {
        const mql = localMatchMedia(media)
        mql.addEventListener = function(eventType, eventListener) {
            eventType === 'change' && mql.addListener(eventListener)
        }
        mql.removeEventListener = function(eventType, eventListener) {
            eventType === 'change' && mql.removeListener(eventListener)
        }

        return mql
    }
})()

// Object.entries
;(function() {
    if (Object.entries) return
    Object.entries = function(obj) {
        let ownProps = Object.keys(obj),
            i = ownProps.length,
            resArray = new Array(i) // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]]

        return resArray
    }
})()