import {withGoogleMap, GoogleMap, Marker, InfoWindow} from 'react-google-maps'
import geo from '../definition/geo'

const StyledFullHeight = styled.div`
	height: 100%;
`

function injectProps(ComponentType) {
	return React.forwardRef((props, ref) => (
		<ComponentType
			{...props}
			forwardRef={ref}
			containerElement={<div style={{height: props.height || 400}}/>}
			mapElement={<StyledFullHeight/>}
		/>
	))
}

@injectProps @withGoogleMap
export default class Map extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.state = {
			activeMarkerCard: null
		}
	}

	render() {
		return (
			<GoogleMap
				defaultZoom={this.props.zoom}
				defaultCenter={{
					lat: parseFloat(this.props.center.latitude),
					lng: parseFloat(this.props.center.longitude)
				}}
				ref={this.props.forwardRef}
			>
				{this.props.markers.map((marker, index) => {
					const identifier = `${marker.latitude}${marker.longitude}`
					return (
						<Marker
							key={index}
							onClick={() => this.markerCardToggle(identifier)}
							position={{
								lat: parseFloat(marker.latitude),
								lng: parseFloat(marker.longitude)
							}}
						>
							{marker.card && this.state.activeMarkerCard === identifier && (
								<InfoWindow onCloseClick={this.markerCardToggle}>
									{marker.card}
								</InfoWindow>
							)}
						</Marker>
					)
				})}
			</GoogleMap>
		)
	}

	markerCardToggle(identifier = null) {
		this.setState({
			activeMarkerCard: identifier
		})
	}

	static defaultProps = {
		center: {
			latitude: geo.cz.latitude,
			longitude: geo.cz.longitude
		},
		markers: [],
		zoom: 7
	}

	static propTypes = {
		markers: PropTypes.arrayOf(PropTypes.shape({
			latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			card: PropTypes.element
		})),
		center: PropTypes.shape({
			latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
		}),
		zoom: PropTypes.number
	}
}