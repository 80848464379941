import {apiContext} from '../../context/api'
import queryString from 'query-string'

const APIURI = API_URI
const S3URI = S3_URI

@withUser
export default class APIProvider extends React.PureComponent {
	constructor(props) {
		super(props)
		autobind(this)

		this.requests = {}

		this.contextValue = {
			request: this.request,
			cancel: this.cancelRequest
		}
	}

	render() {
		return (
			<apiContext.Provider value={this.contextValue}>
				{this.props.children}
			</apiContext.Provider>
		)
	}

	componentDidMount() {
		// `null` has a special meaning here.
		// Refer to the documentation.
		if (this.props.user.authorized === null) {
			this.getAccessToken()
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.user.authorized !== null && this.props.user.authorized === null) {
			this.getAccessToken()
		}
	}

	componentWillUnmount() {
		for (const symbol in this.requests) {
			if (!this.requests.hasOwnProperty(symbol)) continue
			this.requests[symbol].cancel()
		}
	}

	request(uri, options) {
		return new Promise((resolve, reject) => {
			this.makeRequest(uri, {
				success: resolve,
				error: reject,
				...options
			})
		})
	}

	makeRequest(uri, options) {
		if (uri.startsWith('/authorized') && !this.props.user.refreshToken) {
			return console.error('Authorization is required for', uri)
		}

		const query = options.query ? '?' + queryString.stringify(options.query) : ''

		if (uri.startsWith('/')) {
			uri = (options.S3 ? S3URI : APIURI) + uri + query
		}

		const originalOptions = {...options}

		options.headers = {
			'Content-Type': 'application/json; charset=utf-8',
			...options.headers
		}

		if (this.props.user.accessToken && !options.S3) {
			options.headers = {
				Authorization: this.props.user.accessToken,
				...options.headers
			}
		}

		if (options.body) options.body = JSON.stringify(options.body)

		const controller = new AbortController()
		options.signal = controller.signal
		this.requests[options.symbol || Symbol()] = {cancel: () => controller.abort()}

		fetch(uri, options)
		.then(result => {
			return new Promise((resolve, reject) => {
				result.json()
				.then(resolve)
				.catch(() => reject(result))
			})
		})
		.then(json => {
			if (json.error === 'UNAUTHORIZED') {
				return this.getAccessToken(() => {
					this.makeRequest(uri, originalOptions)
				})
			}

			if (json.error) throw json.error
			;(options.success || Function.prototype)(json)
		})
		.catch(error => {
			if (error.name === 'AbortError') return
			;(options.error || Function.prototype)(error)
		})
	}

	getAccessToken(callback) {
		this.request('/public/user_access_token', {
			method: 'post',
			body: {refresh_token: this.props.user.refreshToken, getUserData: true},
			success: result => {
				if (!result.token || !result.token.string) {
					throw 'RESPONSE_MISSING_TOKENS'
				}

				if (!result.user) {
					throw 'RESPONSE_MISSING_USER_DATA'
				}

				this.props.user.authorize({
					accessToken: result.token.string,
					user: result.user
				}, callback)
			},
			error: error => {
				console.log(error)
				this.props.user.logout()
			}
		})
	}

	cancelRequest(symbol) {
		this.requests[symbol].cancel()
	}
}