export function processSeznamEvent(cId, callback = Function.prototype) {
	if (APP_ENVIRONMENT !== 'production') return callback()

	window.seznam_cId = cId
	window.seznam_value = null

	const script = document.createElement('script')
	script.onload = callback
	script.async = true
	script.src = 'https://www.seznam.cz/rs/static/rc.js'

	document.head.appendChild(script)
}