import {Match} from '@reach/router'

export const withMatch = path => ComponentType => {
	return React.forwardRef((props, ref) => (
		<Match path={path}>
			{match => (
				<ComponentType
					{...props}
					ref={ref}
					match={match}
				/>
			)}
		</Match>
	))
}