import {Grid, TextField} from '@material-ui/core'

export const ProfileField = React.memo(props => {
	function onChange(e) {
		props.changeMissingField(props.index, e.target.value)
	}

	if (props.name === 'name') return (
		<Grid item xs={12}>
			<TextField
				fullWidth required
				autoFocus={props.index === 0}
				variant="outlined"
				label="Jméno a příjmení"
				placeholder="např. Jan Novák"
				value={props.value}
				onChange={onChange}
				disabled={props.disabled}
			/>
		</Grid>
	)

	if (props.name === 'phone') return (
		<Grid item xs={12}>
			<TextField
				fullWidth required
				autoFocus={props.index === 0}
				variant="outlined"
				label="Telefonní číslo"
				placeholder="např. +420777888999"
				value={props.value}
				onChange={onChange}
				disabled={props.disabled}
			/>
		</Grid>
	)

	return null
})