import {screenContext} from '../context/screen'

export function withScreen(ComponentType) {
	return React.forwardRef((props, ref) => (
		<screenContext.Consumer>
			{context => (
				<ComponentType
					{...props}
					ref={ref}
					screen={context}
				/>
			)}
		</screenContext.Consumer>
	))
}